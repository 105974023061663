import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Input, InputAuto } from "../../../../controls";
import { ClearRounded } from "@mui/icons-material";
import { API, action, controller } from "../../../../api/api";
import { no_img } from "../../../../assets";

const Composite = (props) => {
  const { setValues, values, selOutlet } = props;
  const [searchVal, setSearchVal] = useState("");
  const [searchLi, setSearchLi] = useState([]);
  const [findErr, setFindErr] = useState("");

  const GetDataFromServer = (val, onComplete) => {
    selOutlet &&
      API(controller.Product + action.FindProducts).post(
        {
          orgId: selOutlet.orgId,
          value: val,
        },
        onComplete
      );
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      if (res.status === 200) {
        var _prods = [];
        res.result
          .filter((j) => j.prodType !== 3)
          .forEach((prod) => {
            if (prod.prodType === 1) {
              _prods.push({
                ...prod,
                isVariant: false,
                id: prod.id,
                varProdId: null,
                img: prod.imgs && prod.imgs[0] && prod.imgs[0].imgUrl,
                label: prod.name,
                subtitle:
                  (prod.skuCode && `${prod.skuCode} | `) + `$${prod.splrPrice}`,
              });
            } else {
              prod.prodVariants.forEach((varProd) => {
                _prods.push({
                  ...varProd,
                  isVariant: true,
                  id: null,
                  varProdId: varProd.id,
                  img:
                    varProd.imgs && varProd.imgs[0] && varProd.imgs[0].imgUrl,
                  label: prod.name,
                  subtitle:
                    varProd.attrVal1 +
                    (varProd.attrVal2 && ` / ${varProd.attrVal2}`) +
                    (varProd.attrVal3 && ` / ${varProd.attrVal3} | `) +
                    (varProd.skuCode && ` | ${varProd.skuCode} | `) +
                    `$${varProd.splrPrice}`,
                });
              });
            }
          });
        setSearchLi([..._prods]);
        setFindErr("");
      }
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  const handleScan = (e, searchVal) => {
    const onComplete = (res) => {
      if (res && res.result.length) {
        const _prod = res.result.filter((j) => j.prodType !== 3)[0];
        var isVariantProd = _prod.prodType === 2 ? true : false;

        const prod = isVariantProd
          ? _prod.prodVariants.find((j) => j.skuCode === searchVal)
          : _prod;

        prod
          ? handleSelect(null, {
              ...prod,
              isVariant: isVariantProd,
              label: prod.name,
            })
          : setFindErr("Product Not Found");
      }
    };
    e.target.value && GetDataFromServer(e.target.value, onComplete);
    setSearchVal("");
  };

  const handleSelect = (e, prod) => {
    if (prod) {
      if (
        !values.compositProds.some(
          (j) => j.prodId === prod.id && j.varProdId === prod.varProdId
        )
      ) {
        setValues((x) => ({
          ...x,
          compositProds: [
            {
              name: prod.label,
              prodId: prod.id,
              varProdId: prod.varProdId,
              qty: 1,
              splrPrice: prod.splrPrice,
              isVariant: prod.isVariant,
              img: prod.img,
              prod: prod,
            },
            ...x.compositProds,
          ],
        }));
      } else if (
        values.compositProds.some(
          (j) => j.prodId === prod.id && j.varProdId === prod.varProdId
        )
      ) {
        setValues((x) => ({
          ...x,
          compositProds: x.compositProds.map((j) =>
            j.prodId === prod.id && j.varProdId === prod.varProdId
              ? { ...j, qty: parseFloat(j.qty) + 1 }
              : j
          ),
        }));
      }
      setFindErr("");
    }
    setSearchVal("");
  };

  const handleQty = (e, prod) => {
    setValues((j) => ({
      ...j,
      compositProds: j.compositProds.map((x) =>
        x.productId === prod.productId && x.varProdId === prod.varProdId
          ? { ...x, qty: Math.abs(e.target.value) }
          : x
      ),
    }));
  };

  const handleRmvItem = (prod) => {
    prod.isVariant
      ? setValues((x) => ({
          ...x,
          compositProds: x.compositProds.filter(
            (j) => j.varProdId !== prod.varProdId
          ),
        }))
      : setValues((x) => ({
          ...x,
          compositProds: x.compositProds.filter(
            (j) => j.productId !== prod.productId
          ),
        }));
  };

  return (
    <>
      <Grid item xs={12} className="mt-[20px]"></Grid>

      <Grid item xs={12} md={4}>
        <div className="grid gap-[5px] border-left">
          <span className="text-[20px] text-gray-500 font-medium uppercase">
            Product
          </span>
          <span className="text-[15px]">
            Add multiple products to create a composite product.
          </span>
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <InputAuto
          label="Search Product"
          value={searchVal}
          onChange={handleSelect}
          options={searchLi}
          inputValue={searchVal}
          onInputChange={handleSearch}
          onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
          classes={{ paper: "shadow-sm" }}
          error={findErr}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer className="bs-secondary rounded-[15px] bg-inherit">
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>
                  Quantity{" "}
                  <span className="font-medium text-[18px]">
                    (
                    {values.compositProds
                      .map((j) => j.qty)
                      .reduce((x, y) => x + y, 0)}
                    )
                  </span>
                </TableCell>
                <TableCell align="right">
                  Supplier Price{" "}
                  <span className="font-medium text-[18px]">
                    ($
                    {values.compositProds
                      .map((j) => j.splrPrice * j.qty)
                      .reduce((x, y) => x + y, 0)
                      .toFixed(2)}
                    )
                  </span>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.compositProds.map((_prod, i) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <div className="flex items-center gap-[10px]">
                      <img
                        src={_prod.img ? _prod.img : no_img}
                        alt="product_img"
                        className="img-fluid w-[60px] object-cover rounded-[10px] shadow-sm"
                      />
                      <div className="grid">
                        <span className="text-[16px] font-medium">
                          {_prod.name}
                        </span>
                        <span className="text-[15px] mr-[20px] text-gray-500">
                          {_prod.prod && _prod.prod.subtitle}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Input
                      variant="outlined"
                      size="small"
                      sx={{ maxWidth: "200px" }}
                      value={_prod.qty}
                      onChange={(e) => handleQty(e, _prod)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="text-right">
                      <span
                        className="text-[18px] text-gray-500"
                        style={{ minWidth: "200px" }}
                      >
                        $&nbsp;{_prod.splrPrice}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-right">
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => handleRmvItem(_prod)}
                        >
                          <ClearRounded className="text-[26px]" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default Composite;
