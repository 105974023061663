import { CloseRounded } from "@mui/icons-material";
import { Divider, IconButton, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "../../../../layout";
import { AppContext } from "../../../../App";
import { action, API, controller } from "../../../../api/api";
import RegFormBody from "./RegFormBody";

const RegisterForm = (props) => {
  const { setShowForm, regCloseable } = props;
  const { authUser } = useContext(AppContext);
  const [outlets, setOutlets] = useState([]);

  useEffect(() => {
    if (authUser && authUser.id) {
      API(controller.Outlet + action.GetOutletsWithRegisters).get((res) => {
        res.status === 200 &&
          setOutlets([
            ...res.result.map((j) => ({ ...j, label: j.outletName })),
          ]);
      });
    }
    return () => {};
  }, [authUser]);

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Paper>
      {/* Header */}
      <div className="flex flex-wrap justify-between items-center p-[8px]">
        <span className="font-medium text-[18px] uppercase text-gray-500 ml-[8px]">
          Open Register
        </span>

        {regCloseable && (
          <IconButton size="small" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        )}
      </div>
      <Divider />

      {/* Body */}
      <div className="mt-[10px]">
        <Tabs header={[...outlets.map((j) => ({ ...j, icon: "" }))]}>
          {outlets.map((outlet, ind) => (
            <div key={ind}>
              {outlet.registers && outlet.registers.length > 0 ? (
                <RegFormBody
                  {...{
                    regs: outlet.registers,
                    outletId: outlet.id,
                    setShowForm,
                  }}
                />
              ) : (
                <span className="block font-medium text-[22px] text-center p-[20px] text-gray-400">
                  No Regsiter Found
                </span>
              )}
            </div>
          ))}
        </Tabs>
      </div>
    </Paper>
  );
};

export default RegisterForm;
