import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const InputTag = (props) => {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    options = [],
    error = null,
    dltConfirm = false,
    setAlert,
    required,
    ...other
  } = props;
  const [val, setVal] = useState([]);

  useEffect(() => {
    setVal([...value]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (even, newVal, reason) => {
    if (even && even.key !== "Backspace") {
      if (dltConfirm && reason === "removeOption" && setAlert) {
        var dltTag = val.filter((j) => !newVal.includes(j));
        setAlert({
          isOpen: true,
          type: "confirmation",
          title: "Are you sure!",
          subTitle: `You want to delete this "${dltTag}"?`,
          onConfirm: () => {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: "You have successfully deleted",
            });
            handleChg(newVal);
          },
        });
      } else if (reason === "createOption") handleChg(newVal);
    }
  };

  const handleChg = (newVal) => {
    setVal(newVal);
    var e = {
      target: {
        value: newVal ? newVal : "",
        name: name,
      },
    };
    onChange && onChange(e);
  };

  return (
    <Autocomplete
      clearIcon={false}
      options={options}
      freeSolo
      multiple
      fullWidth
      value={val || []}
      onChange={handleChange}
      componentsProps={{
        paper: {
          className: "bg-white rounded-[14px]",
        },
      }}
      popupIcon={<KeyboardArrowDownRounded />}
      {...other}
      renderTags={(value, props) =>
        value.map((option, index) => (
          <Chip label={option} {...props({ index })} />
        ))
      }
      renderInput={(params) => {
        return (
          <TextField
            variant={variant || "outlined"}
            label={label}
            required={required}
            {...params}
            {...(error && { error: true, helperText: error })}
          />
        );
      }}
    />
  );
};

export default InputTag;
