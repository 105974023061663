import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { KeyboardArrowDownRounded } from "@mui/icons-material";

export default function Select(props) {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    options,
    required,
    fullWidth = true,
    error = null,
    showNA = false,
    className,
    ...other
  } = props;

  return (
    <FormControl
      variant={variant || "outlined"}
      fullWidth={fullWidth}
      required={required}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={options.some((j) => j.id === value) ? value : ""}
        onChange={onChange}
        className={className}
        MenuProps={{
          PaperProps: {
            className: "bg-white border border-gray-500 rounded-[14px]",
          },
        }}
        IconComponent={(e) => <KeyboardArrowDownRounded {...e} />}
        {...(error && { error: true })}
        {...other}
      >
        {showNA && (
          <MenuItem value="">
            <em>N/A</em>
          </MenuItem>
        )}
        {options.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && (
        <FormHelperText className="error-color">{error}</FormHelperText>
      )}
    </FormControl>
  );
}
