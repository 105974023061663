import React, { useState } from "react";
import { Input, Button, Box, Paper, Divider, IconButton } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { FormHelperText } from "@mui/material";
import { Alert } from "../layout";
import { useEffect } from "react";
import { CloseRounded } from "@mui/icons-material";

export default function InputMultFile(props) {
  const {
    name,
    label,
    onChange,
    error = null,
    values = [],
    rmv,
    src,
    maxUpl = 5,
    maxSize = 3,
  } = props;

  const [files, setFiles] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleFile = (e) => {
    const _files = e.target.files;
    const uplFiles = [];
    if (files.length + _files.length <= maxUpl) {
      Array.from(_files).forEach((file) => {
        if (file.size / 1048576 < maxSize) {
          setFiles((x) => [
            ...x,
            {
              name: file.name,
              lastModified: file.lastModified,
              size: file.size,
            },
          ]);
          uplFiles.push(file);
        } else {
          setAlert({
            isOpen: true,
            type: "warning",
            title: `Maximum Allowed ${maxSize}MB`,
            subTitle: `"${file.name}" size is ${(file.size / 1048576).toFixed(
              2
            )}MB. You can't upload file larger than ${maxSize}MB.`,
          });
        }
      });
      // return files
      onChange && onChange({ name, value: values.concat(uplFiles) });
    } else {
      setAlert({
        isOpen: true,
        type: "warning",
        title: `Maximum Upload ${maxUpl} Files`,
        subTitle: `You have uploaded ${
          files.length
        } images. Now you can upload ${maxUpl - files.length} files`,
      });
    }
    // FOR BUG IN CHROME
    e.target.value = "";
  };

  const handleRmv = (file) => {
    setFiles([...files.filter((j) => j !== file)]);

    rmv && rmv(file);
  };

  useEffect(() => {
    src && setFiles([...src]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <>
      <Paper className="rounded-[14px] text-gray-500 shadow-none border border-gray-400">
        <div className="grid">
          {files.map((file, i) => (
            <div key={i}>
              <div className="flex justify-between items-center p-[5px]">
                <span className="mt-[0px] ml-[5px] text-[12px]">
                  <span>File Name:&nbsp;&nbsp;</span>
                  {file.isUploaded ? (
                    <span className="text-[14px]">{file.name}</span>
                  ) : (
                    <a href={file.fileSrc} target="_blank" rel="noreferrer">
                      {file.name}
                    </a>
                  )}
                </span>
                <div>
                  <IconButton
                    onClick={() => handleRmv(file)}
                    color="error"
                    size="small"
                  >
                    <CloseRounded className="text-[16px]" />
                  </IconButton>
                </div>
              </div>
              {files.length !== i + 1 && <Divider />}
            </div>
          ))}
        </div>

        <Box className="flex p-[5px]">
          <label htmlFor={name} className="w-100pr">
            <Input
              className="hidden"
              accept="image/*"
              id={name}
              onChange={handleFile}
              inputProps={{ multiple: true }}
              type="file"
            />
            <Button
              className="w-[100%]"
              startIcon={<DriveFolderUploadIcon />}
              component="span"
              size="small"
            >
              {label}
            </Button>
          </label>
        </Box>
        {error && (
          <FormHelperText sx={{ color: "#d32f2f" }}>{error}</FormHelperText>
        )}
      </Paper>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
}
