import { Grid, TableCell } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "../../../../layout";
import { Button } from "../../../../controls";
import {
  CloseRounded,
  DoneAllRounded,
  EditNoteRounded,
  LocalShippingRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Products = ({ order, setShowCancelForm, handleMarkAsSent }) => {
  const navigate = useNavigate();
  const [invOutlets, setInvOutlets] = useState([]);
  const [accounts, setAccounts] = useState({
    totalOrderedQty: 0,
    totalPurchPrice: 0,
    totalRetailPrice: 0,
  });

  const [tblData, setTblData] = useState([]);

  useEffect(() => {
    if (order && order.stockProds) {
      // table data
      setTblData([
        ...order.stockProds.map((prod, ind) => {
          const flattenedProd = {
            ...prod,
            order: ind + 1,
            label: (
              <div className="min-w-[350px] !max-w-[600px] whitespace-normal text-[13px]">
                <p>{prod.label}</p>
              </div>
            ),
            skuAndSplrCode: (
              <div className="flex">
                <div className="grid text-[14px]">
                  <span>SKU &nbsp;&nbsp;</span>
                  <span>Supplier Code &nbsp;&nbsp;</span>
                </div>
                <div className="grid text-[14px]">
                  <span className="font-medium">: {prod.sku}</span>
                  <span className="font-medium">: {prod.splrCode}</span>
                </div>
              </div>
            ),
            stock: (
              <div className="grid text-[14px] w-max">
                <div className="flex gap-3 border-b-[1px]">
                  <div>
                    <span>Ordered:&nbsp;</span>
                    <span className="font-medium">{prod.totalOrderQty}</span>
                  </div>
                  <div>
                    <span>Received:&nbsp;</span>
                    <span className="font-medium">{prod.totalReceiveQty}</span>
                  </div>
                </div>
                <div>
                  <span>Previous Stock:&nbsp;</span>
                  <span className="font-medium">{prod.totalPrevStock}</span>
                </div>
              </div>
            ),
            purchPrice: (
              <div className="flex">
                <div className="grid text-[14px]">
                  <span>Cost Price &nbsp;&nbsp;</span>
                  <span>Total Cost Price &nbsp;&nbsp;</span>
                </div>
                <div className="grid text-[14px]">
                  <span className="font-medium">
                    : ${" "}
                    {prod.unitPrice.toLocaleString("en-us", {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <span className="font-medium">
                    : ${" "}
                    {prod.totalPrice.toLocaleString("en-us", {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            ),
            retailPrice: (
              <div className="flex">
                <div className="grid text-[14px]">
                  <span>Retail Price &nbsp;&nbsp;</span>
                  <span>Total Retail Price &nbsp;&nbsp;</span>
                </div>
                <div className="grid text-[14px]">
                  <span className="font-medium">
                    : ${" "}
                    {prod.retailPrice.toLocaleString("en-us", {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <span className="font-medium">
                    : ${" "}
                    {prod.totalRetailPrice.toLocaleString("en-us", {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            ),
          };

          if (prod.orderInvsFor) {
            prod.orderInvsFor.forEach((inv) => {
              flattenedProd[inv.outletId] = (
                <div className="grid text-[14px] w-max">
                  <div className="flex gap-3 border-b-[1px]">
                    <div>
                      <span>Ordered:&nbsp;</span>
                      <span className="font-medium">{inv.qty}</span>
                    </div>
                    <div>
                      <span>Received:&nbsp;</span>
                      <span className="font-medium">{inv.receiveQty}</span>
                    </div>
                  </div>
                  <div>
                    <span>Previous Stock:&nbsp;</span>
                    <span className="font-medium">{inv.previousStock}</span>
                  </div>
                </div>
              );
            });
          }

          return flattenedProd;
        }),
      ]);

      // Set Accounting Properties.
      var _stockProds = order.stockProds;
      var _totaOrderQty = _stockProds
        .map((j) => j.totalOrderQty)
        .reduce((x, y) => x + y, 0)
        .toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });

      var _totalReceiveQty = _stockProds
        .map((j) => j.totalReceiveQty)
        .reduce((x, y) => x + y, 0)
        .toLocaleString("en-US", {
          maximumFractionDigits: 3,
        });

      var _totalCostPrice = _stockProds
        .map((j) => j.totalPrice)
        .reduce((x, y) => x + y, 0)
        .toLocaleString("en-US", { maximumFractionDigits: 2 });

      var _totalRetailPrice = _stockProds
        .map((j) => j.totalRetailPrice)
        .reduce((x, y) => x + y, 0)
        .toLocaleString("en-US", { maximumFractionDigits: 2 });

      setAccounts({
        totalOrderQty: _totaOrderQty,
        totalReceiveQty: _totalReceiveQty,
        totalCostPrice: _totalCostPrice,
        totalRetailPrice: _totalRetailPrice,
      });

      // Inv Outlets
      _stockProds[0] && setInvOutlets([..._stockProds[0].orderInvsFor]);
    } else
      setAccounts({
        totalOrderedQty: 0,
        totalPreviousStock: 0,
        totalPurchPrice: 0,
        totalRetailPrice: 0,
      });
    return () => {};
  }, [order]);

  const handleCancel = () => {
    setShowCancelForm(true);
  };

  const handleReceived = () => {
    navigate(`/receive_delivery_form/${order.id}`);
  };

  const handleEdit = () => {
    navigate(`/order_stock_form/${order.id || ""}`);
  };

  const HeaderCom = () => (
    <div className="flex gap-[10px] flex-wrap">
      {order.status !== 3 && order.status !== 2 && (
        <>
          <Button
            startIcon={<DoneAllRounded />}
            onClick={() => handleReceived()}
          >
            Received
          </Button>
          {order.status !== 4 && (
            <Button
              startIcon={<LocalShippingRounded />}
              color="primary"
              onClick={() => handleMarkAsSent()}
            >
              Mark As Sent
            </Button>
          )}
          <Button
            onClick={handleEdit}
            startIcon={<EditNoteRounded />}
            color="primary"
          >
            Edit Order
          </Button>
          <Button
            startIcon={<CloseRounded />}
            color="error"
            onClick={() => handleCancel()}
          >
            Cancel Order
          </Button>
        </>
      )}
    </div>
  );
  return (
    <>
      <Grid item xs={12}>
        {order.stockProds ? (
          <Table
            ActionComp={() => <></>}
            HeaderComp={HeaderCom}
            tblHead={[
              { id: "order", label: "#" },
              { id: "label", label: "Product" },
              {
                id: "skuAndSplrCode",
                label: " Supplier Code / SKU",
              },
              { id: "stock", label: "Quantity" },
              { id: "purchPrice", label: "Cost Price" },
              { id: "retailPrice", label: "Retail Price" },
              ...invOutlets.map((j, ind) => ({
                id: j.outletId,
                label: (
                  <span className="block w-max">{`${
                    j.outlet && j.outlet.outletName
                  }`}</span>
                ),
              })),
            ]}
            tblData={tblData}
            FooterRow={() => (
              <>
                <TableCell className="!text-[16px] !font-medium uppercase">
                  Total
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>

                <TableCell className="w-max !text-[16px] !font-medium !text-slate-500 !max-w-max">
                  <div className="flex w-max">
                    <div className="grid text-[14px] w-max">
                      <span>Receive &nbsp;&nbsp;</span>
                      <span>Ordered &nbsp;&nbsp;</span>
                    </div>
                    <div className="grid text-[14px]">
                      <span className="font-medium w-max">
                        : {accounts.totalReceiveQty}
                      </span>
                      <span className="font-medium w-max">
                        : {accounts.totalOrderQty}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="!text-[20px] !text-slate-500 !font-medium">
                  $ {accounts.totalCostPrice}
                </TableCell>
                <TableCell className="!text-[20px] !text-slate-500 !font-medium">
                  $ {accounts.totalRetailPrice}
                </TableCell>
              </>
            )}
          />
        ) : (
          <span className="text-[20px] font-medium">No Data</span>
        )}
      </Grid>
    </>
  );
};

export default Products;
