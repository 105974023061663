import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputAuto, InputImg, Select } from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Alert, Form, Tabs } from "../../../layout";
import { ArrowBackRounded } from "@mui/icons-material";
import { Countries } from "../../../hooks/Countries";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditSplr = (props) => {
  const { id } = useParams();
  const { Gender } = DropLi();
  const { SupplierMdl } = Model();
  const navigate = useNavigate();
  const { values, errors, setErrors, handleInpChg, setValues, handleFile } =
    UseForm(SupplierMdl);
  const { selOutlet, authUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    id &&
      API(controller.Supplier + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((x) => ({ ...x, ...val }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.splrName = values.splrName !== "" ? "" : "Supplier name is required.";
    temp.defMarkup =
      values.defMarkup !== "" ? "" : "Default markup is required.";
    temp.company = values.company !== "" ? "" : "Company name is required.";
    temp.phone = values.phone !== "" ? "" : "Phone name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && selOutlet && selOutlet.id) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201 || res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: res.msg,
              onClick: () => navigate("/suppliers"),
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        }
      };
      if (values.id === 0) {
        values.outletId = selOutlet.id;
        values.orgId = selOutlet.orgId;
        values.userId = authUser.id;
        API(controller.Supplier + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else {
        API(controller.Supplier + action.Put).put(
          ToFormData(
            RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
          ),
          onComplete
        );
      }
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-[10px] flex justify-between items-center !pt-[15px] !pl-[15px] !pr-[15px]">
            <div className="flex items-center gap-[10px]">
              <div>
                <IconButton size="small" onClick={() => navigate(-1)}>
                  <ArrowBackRounded />
                </IconButton>
              </div>
              <div>
                <h1 className="text-[20px] font-medium text-gray-500 uppercase">
                  {id ? "Edit" : "Add"} Supplier
                </h1>
              </div>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {id ? "Save Changes" : "Submit"}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-[15px] pr-[15px] pb-[15px]">
            <Tabs
              className="mt-[30px]"
              header={[
                { label: "General Fields", icon: "" },
                { label: "Additional Fields", icon: "" },
              ]}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Supplier Name"
                    name="splrName"
                    required={true}
                    value={values.splrName}
                    error={errors.splrName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Default Markup"
                    name="defMarkup"
                    required={true}
                    type="number"
                    value={values.defMarkup}
                    error={errors.defMarkup}
                    onChange={handleInpChg}
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 3))
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Company"
                    name="company"
                    required={true}
                    value={values.company}
                    error={errors.company}
                    onChange={handleInpChg}
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="First Name"
                    name="firstName"
                    value={values.firstName}
                    error={errors.firstName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Last Name"
                    name="lastName"
                    value={values.lastName}
                    error={errors.lastName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label="Phone"
                    name="phone"
                    value={values.phone}
                    error={errors.phone}
                    required={true}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Description"
                    name="desc"
                    value={values.desc}
                    error={errors.desc}
                    inputProps={{ maxLength: 1500 }}
                    multiline
                    rows={3}
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    error={errors.email}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Fax"
                    name="fax"
                    value={values.fax}
                    error={errors.fax}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    label="Gender"
                    name="gender"
                    value={values.gender}
                    error={errors.gender}
                    onChange={handleInpChg}
                    options={Gender}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Website"
                    name="website"
                    value={values.website}
                    error={errors.website}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputAuto
                    label="Country"
                    name="country"
                    value={values.country}
                    error={errors.country}
                    onChange={handleInpChg}
                    options={Countries}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label="City"
                    name="city"
                    value={values.city}
                    inputProps={{ maxLength: 300 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="State"
                    name="state"
                    value={values.state}
                    inputProps={{ maxLength: 300 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="ZIP"
                    name="zip"
                    value={values.zip}
                    error={errors.zip}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 1"
                    name="address1"
                    multiline
                    rows={2}
                    value={values.address1}
                    error={errors.address1}
                    inputProps={{ maxLength: 400 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 2"
                    name="address2"
                    multiline
                    rows={2}
                    value={values.address2}
                    inputProps={{ maxLength: 400 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputImg
                    label="Profile Photo"
                    name="photoFile"
                    src={values.photo}
                    value={values.photoFile}
                    rmv={() => {
                      setValues({ ...values, photoFile: "", photo: "" });
                    }}
                    onChange={handleFile}
                    error={errors.photoFile}
                  />
                </Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditSplr;
