import { Box, Paper } from "@mui/material";
import React, { useContext, useState } from "react";
import { Accordion, Dialog } from "../../../../layout";
import InvDetails from "../ViewInvDetails/InvDetails";
import { CartContext } from "../Sell";
import SelectCustomer from "../SelectCustomer";
import CartProdHeader from "./CartProdHeader";
import CartProdBody from "./CartProdBody";
import Accounts from "./Accounts";
import CartAdditionalFields from "./CartAdditionalFields";

const Cart = () => {
  const { cartData } = useContext(CartContext);
  const [showInvDtls, setShowInvDtls] = useState(false);

  return (
    <>
      <SelectCustomer />
      <Paper className="!rounded-[14px] bg-white pt-[10px]">
        {/* Cart Items */}
        <Box
          sx={{
            height: "calc(100vh - 285px)",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Accordion
            items={cartData.sellItems.map((item, i) => ({
              header: <CartProdHeader item={item} />,
              body: <CartProdBody {...{ data: item }} />,
            }))}
          />
          <Box className="w-[100%]">
            <Accordion
              items={[
                {
                  header: (
                    <div className="text-gray-500 w-[100%] uppercase flex justify-between">
                      <span className="text-[16px] font-medium">Add</span>
                      <span className="mr-10">
                        Note & Discount{" "}
                        {cartData.disAmt > 0 && (
                          <span className="font-semibold">
                            (${" "}
                            {(cartData.disAmt || 0).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                            )
                          </span>
                        )}
                      </span>
                    </div>
                  ),
                  body: <CartAdditionalFields />,
                },
              ]}
            />
          </Box>
        </Box>

        {/* Accounts */}
        <Accounts />
      </Paper>

      {/* Inventory Details */}
      <Dialog show={showInvDtls} onClose={false} maxWidth="md">
        <InvDetails {...{ setShowForm: setShowInvDtls }} />
      </Dialog>
    </>
  );
};

export default Cart;
