import { Box, Divider, InputAdornment, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input } from "../../../../controls";
import { CartContext } from "../Sell";
import { AppContext } from "../../../../App";
import { action, API, controller } from "../../../../api/api";
import {
  BGNestedObjToFromData,
  PrintDiv,
  RmvArryEmptyProp,
} from "../../../../hooks/Method";
import { Model } from "../../../../hooks";
import { Alert } from "../../../../layout";
import PrintRecept from "./PrintRecept";
import { useNavigate } from "react-router-dom";
// import PrintRecept from "./PrintRecept";

const Checkout = () => {
  const navigate = useNavigate();
  const [totalPay, setTotalPay] = useState(0);
  const [error, setError] = useState("");
  const [change, setChange] = useState(0);
  const [payMethods, setPayMethods] = useState([]);
  const { SellMdl } = Model();
  const { authUser, setLoader } = useContext(AppContext);
  const { cartData, setCartData, sellReg } = useContext(CartContext);
  const [receiptData, setReceiptData] = useState({});

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser && authUser.id) {
      API(controller.PayType + action.GetAllByOrg).getById(
        authUser.orgId,
        (res) => {
          setPayMethods([...res.result]);
        }
      );
    }

    return () => {};
  }, [authUser]);

  useEffect(() => {
    setTotalPay(cartData.totalPay || 0);
    return () => {};
  }, [cartData.totalPay]);

  useEffect(() => {
    setChange(
      (parseFloat(totalPay) - cartData.totalPay).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPay]);

  const handleInpAmt = (e) => {
    var amount = e.target.value;
    setTotalPay(amount);
  };

  const handlePayAmt = (e) => {
    var received = parseFloat(e.target.value);
    setCartData((j) => ({ ...j, received: received }));
    if (received >= cartData.totalPay) {
      setError("");
      setCartData((j) => ({ ...j, change: received - cartData.totalPay }));
    } else if (received < cartData.totalPay) {
      setError(
        `You have to pay: $ ${cartData.totalPay.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}.`
      );
    }
  };

  const handleSubmitSell = (payMethod) => {
    if (!error) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res && res.status === 200) {
          setReceiptData(res.result);
          setTimeout(() => {
            PrintDiv("printSellRecept");
            setCartData((j) => ({
              ...SellMdl(),
              taxRate: res.result.taxRate,
              taxId: res.result.taxId,
            }));
            navigate("/sell");
          }, 500);
        } else if (res)
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };

      API(controller.Sell + action.Post).post(
        BGNestedObjToFromData(
          RmvArryEmptyProp({
            ...cartData,
            sellItems: cartData.sellItems.map((j) => ({ ...j, prod: "" })),
            sellRegId: sellReg.id,
            outletId: sellReg.outletId,
            orgId: authUser.orgId,
            payTypeId: payMethod,
          })
        ),
        onComplete
      );
    }
  };

  return (
    <>
      <Box className="d-none" id="printSellRecept">
        {receiptData && receiptData.id && <PrintRecept data={receiptData} />}
      </Box>

      <Paper className=" grid gap-5 border-slate-200 border-[2px]">
        <div className="flex items-center justify-between pl-[20px] pr-[20px] pt-[20px]">
          <span className="text-[32px] text-gray-500 font-medium uppercase">
            Amount to Pay
          </span>

          <div className="max-w-[300px] ">
            <Input
              placeholder="0.00"
              name="totalPay"
              value={totalPay}
              error={error}
              type="number"
              variant="outlined"
              size="small"
              onChange={handleInpAmt}
              onBlur={handlePayAmt}
              InputProps={{
                className: "!text-[32px] !font-medium !text-gray-500",
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="text-[28px]">$</span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Divider />
        <div className="pl-[20px] pr-[20px] flex flex-wrap justify-between">
          <span className="text-[28px] text-gray-500 font-medium uppercase">
            Change
          </span>
          <span className="text-[28px] text-gray-500 font-medium ml-[15px] min-w-[300px]">
            $ {change}
          </span>
        </div>
        <Divider />

        {/* Submit Action */}
        <div className="grid grid-cols-3 gap-2 p-[20px]">
          {payMethods.map((type, ind) => (
            <Button
              onClick={() => handleSubmitSell(type.id)}
              key={ind}
              className="w-full !p-[15px] !pt-[22px] !pb-[22px] !text-[22px] !uppercase"
            >
              {type.name}
            </Button>
          ))}
        </div>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Checkout;
