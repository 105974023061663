import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../controls";
import { Dialog } from "../../../../layout";
import RegisterForm from "../Register/RegisterForm";
import { CartContext } from "../Sell";

const RegisterAct = () => {
  const { sellReg } = useContext(CartContext);
  const [regCloseable, setRegCloseable] = useState(true);
  const [showRegForm, setShowRegForm] = useState(false);

  const handleSwitchReg = () => {
    setShowRegForm(!showRegForm);
  };

  useEffect(() => {
    if (sellReg && sellReg.id === 0) {
      setShowRegForm(true);
      setRegCloseable(false);
    }
    return () => {};
  }, [sellReg]);

  return (
    <>
      {sellReg && sellReg.id ? (
        <Button
          onClick={handleSwitchReg}
          variant="text"
          className="!min-w-[220px]"
          fullWidth
          sx={{ display: "block", "& .MuiButton-startIcon": { mr: 0 } }}
        >
          <div className="grid gap-[1px] items-center text-left pl-[5px]">
            <span className="text-[16px]">
              {sellReg.register && sellReg.register.name}
            </span>

            <div className="flex gap-1 items-center">
              <span className="text-[12px]">
                {sellReg.outlet && sellReg.outlet.outletName}
              </span>
              <span className="text-gray-300">|</span>
              <span className="text-[12px] underline">Switch</span>
            </div>
          </div>
        </Button>
      ) : (
        "Loading..."
      )}

      {/* Switch Register Form */}
      {showRegForm && (
        <Dialog show={showRegForm} onClose={false} maxWidth="sm">
          <RegisterForm
            {...{
              setShowForm: setShowRegForm,
              regCloseable,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default RegisterAct;
