import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputImg,
  Select,
} from "../../controls";
import { DropLi, Model, UseForm } from "../../hooks";
import { Alert, Form, Tabs } from "../../layout";
import { ArrowBackRounded, ShuffleRounded } from "@mui/icons-material";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { Countries } from "../../hooks/Countries";
import { AppContext } from "../../App";
import { API, action, controller } from "../../api/api";

const AddEditCstmr = (props) => {
  const { id } = useParams();
  const { CstmrMdl } = Model();
  const navigate = useNavigate();
  const { Gender, AccBalTypes } = DropLi();
  const { selOutlet, authUser } = useContext(AppContext);
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    setValues,
    handleFile,
    handleInpDate,
  } = UseForm(CstmrMdl);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (selOutlet.id) {
      API(controller.CstmrGroup + action.GetAllByOutlet).getById(
        selOutlet.id,
        (res) => {
          res &&
            res.status === 200 &&
            setGroups([...res.result.map((j) => ({ ...j, label: j.name }))]);
        }
      );
      API(controller.Tax + action.GetAllByOrg).get((res) => {
        res &&
          res.status === 200 &&
          setTaxes([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  useEffect(() => {
    id &&
      API(controller.Customer + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((x) => ({ ...x, ...val }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.firstName = values.firstName !== "" ? "" : "First name is required.";
    temp.lastName = values.lastName !== "" ? "" : "Last name is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.cstmrCode =
      values.phone !== "" ? errors.cstmrCode || "" : "Phone is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && selOutlet && selOutlet.id) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201 || res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: res.msg,
              onClick: () => navigate("/customers"),
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        }
      };
      if (values.id === 0) {
        values.outletId = selOutlet.id;
        values.orgId = selOutlet.orgId;
        values.userId = authUser.id;
        API(controller.Customer + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else {
        API(controller.Customer + action.Put).put(
          ToFormData(
            RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
          ),
          onComplete
        );
      }
    }
  };

  const handleGenerateCode = (name) => {
    var code = Math.floor(1000000 + Math.random() * 1000000);
    setValues({
      ...values,
      [name]: code,
    });
    checkCstmrCode(code);
  };

  const checkCstmrCode = (val) => {
    API(controller.Customer + action.CheckCstmrCode).getById(val, (res) => {
      res &&
        setErrors((x) => ({
          ...x,
          cstmrCode: res.msg,
        }));
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-[10px] flex justify-between items-center pt-[15px] pl-[15px] pr-[15px]">
            <div className="flex items-center gap-[10px]">
              <div>
                <IconButton size="small" onClick={() => navigate(-1)}>
                  <ArrowBackRounded />
                </IconButton>
              </div>
              <div>
                <h1 className="text-[22px] font-medium text-gray-500 uppercase">
                  {id ? "Edit" : "Add"} Customer
                </h1>
              </div>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {id ? "Save Changes" : "Submit"}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-[15px] pr-[15px] pb-[15px]">
            <Tabs
              className="mt-[30px]"
              header={[
                { label: "General Fields", icon: "" },
                { label: "Address Fields", icon: "" },
                { label: "Additional Fields", icon: "" },
                { label: "Settings Fields", icon: "" },
                { label: "Credit/Debit Card", icon: "" },
              ]}
            >
              {/* General Info */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    label="First Name"
                    name="firstName"
                    required={true}
                    value={values.firstName}
                    error={errors.firstName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Last Name"
                    name="lastName"
                    required={true}
                    value={values.lastName}
                    error={errors.lastName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Customer Code"
                    name="cstmrCode"
                    value={values.cstmrCode}
                    error={errors.cstmrCode}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                    onBlur={(e) => checkCstmrCode(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip arrow title={"Generate Customer Id"}>
                            <IconButton
                              onClick={() => handleGenerateCode("cstmrCode")}
                            >
                              <ShuffleRounded />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputAuto
                    label="Customer Group"
                    name="cstmrGroupId"
                    value={values.cstmrGroupId}
                    error={errors.cstmrGroupId}
                    onChange={handleInpChg}
                    options={groups}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Phone"
                    name="phone"
                    required={true}
                    value={values.phone}
                    error={errors.phone}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    error={errors.email}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    label="Gender"
                    name="gender"
                    value={values.gender}
                    onChange={handleInpChg}
                    options={Gender}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputDate
                    label="Date of Birth"
                    name="dob"
                    value={values.dob}
                    onChange={(date) =>
                      handleInpDate("dob", new Date(date).toLocaleDateString())
                    }
                  />
                </Grid>
              </Grid>

              {/* Addresses */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputAuto
                    label="Country"
                    name="country"
                    value={values.country}
                    error={errors.country}
                    onChange={handleInpChg}
                    options={Countries}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="City"
                    name="city"
                    value={values.city}
                    error={errors.city}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="State"
                    name="state"
                    value={values.state}
                    error={errors.state}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="ZIP"
                    name="zip"
                    value={values.zip}
                    error={errors.zip}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 1"
                    name="address1"
                    value={values.address1}
                    error={errors.address1}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    rows={2}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 2"
                    name="address2"
                    value={values.address2}
                    error={errors.address2}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    rows={2}
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>

              {/* Additional informations */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Company Name"
                    name="company"
                    value={values.company}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label="Twitter"
                    name="twitter"
                    value={values.twitter}
                    inputProps={{ maxLength: 500 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Website"
                    name="website"
                    value={values.website}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Note"
                    name="note"
                    value={values.note}
                    multiline
                    rows={3}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label="Profile Picture"
                    name="photoFile"
                    src={values.photo}
                    value={values.photoFile}
                    rmv={() => {
                      setValues({ ...values, photoFile: "", photo: "" });
                    }}
                    onChange={handleFile}
                    error={errors.photoFile}
                  />
                </Grid>
              </Grid>

              {/* Settings Field  */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Select
                    label="Allow an on account balance"
                    name="accBalType"
                    value={values.accBalType}
                    onChange={handleInpChg}
                    options={AccBalTypes}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Balance Limit"
                    name="balLimit"
                    value={values.balLimit}
                    placeholder="$ 0.00"
                    type="number"
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputAuto
                    label="Tax Rate"
                    name="taxId"
                    value={values.taxId}
                    onChange={handleInpChg}
                    options={taxes}
                  />
                </Grid>
              </Grid>

              {/*  Credit/Debit Card  */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Name on Card"
                    name="nameOnCard"
                    inputProps={{ maxLength: 26 }}
                    value={values.nameOnCard}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Card Number"
                    name="cardNumber"
                    value={values.cardNumber}
                    type="number"
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 19))
                    }
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    label="Expiry Date"
                    name="expiryDate"
                    views={["year", "month"]}
                    format="MM/YYYY"
                    openTo="year"
                    minDate={new Date()}
                    value={values.expiryDate}
                    onChange={(date) =>
                      handleInpDate(
                        "expiryDate",
                        new Date(date).toLocaleDateString()
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Input
                    label="Security Code"
                    name="securityCode"
                    value={values.securityCode}
                    type="number"
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 3))
                    }
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditCstmr;
