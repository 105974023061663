import {
  BarChartRounded,
  HomeRounded,
  InventoryRounded,
  PeopleRounded,
  SellRounded,
  SettingsSuggestRounded,
} from "@mui/icons-material";
import { NavItem } from "../../layout";

const NavMenu = () => {
  return (
    <div>
      <NavItem
        title="Home"
        path="home"
        icon={<HomeRounded className="text-[22px] text-slate-600" />}
      />
      <NavItem
        title="Sell"
        icon={<SellRounded className="text-[22px] text-slate-600" />}
        child={[
          {
            title: "Sell",
            path: "sell",
          },
          {
            title: "Close Register",
            path: "close_reg",
          },
          {
            title: "Sales History",
            path: "sales_history",
          },
          {
            title: "Cash Management",
            path: "cash_management",
          },
          {
            title: "Status",
            path: "status",
          },
          {
            title: "Quotes",
            path: "quotes",
          },
        ]}
      />
      <NavItem
        title="Reports"
        icon={<BarChartRounded className="text-[22px] text-slate-600" />}
        child={[
          {
            title: "Retail Dashboard",
            path: "retail_dashboard",
          },
          {
            title: "Sales Reports",
            path: "sales_reports",
          },
          {
            title: "Inventory Reports",
            path: "inventory_reports",
          },
          {
            title: "Payments Reports",
            path: "payments_reports",
          },
          {
            title: "Register Closures",
            path: "register_closures",
          },
          {
            title: "Gift Card Reports",
            path: "gift_card_reports",
          },
          {
            title: "Store Card Reports",
            path: "store_credit_reports",
          },
          {
            title: "Tax Reports",
            path: "tax_reports",
          },
        ]}
      />
      <NavItem
        title="Catalog"
        icon={<SellRounded className="text-[22px] text-slate-600" />}
        child={[
          {
            title: "Products",
            path: "products",
          },
          {
            title: "Promotions",
            path: "promotions",
          },

          {
            title: "Suppliers",
            path: "suppliers",
          },
          {
            title: "Product Category",
            path: "product_category",
          },
          {
            title: "Brands",
            path: "brands",
          },

          {
            title: "Tags",
            path: "tags",
          },
          {
            title: "Product Attributes",
            path: "product_attributes",
          },
        ]}
      />
      <NavItem
        title="Inventory"
        icon={<InventoryRounded className="text-[22px] text-slate-600" />}
        child={[
          {
            title: "Stock Control",
            path: "stock_control",
          },
          {
            title: "Transfer Stock",
            path: "transfer_stock",
          },
          {
            title: "Return Stock",
            path: "return_stock",
          },
          {
            title: "Inventory Counts",
            path: "inventory_counts",
          },
          {
            title: "Fulfillments",
            path: "fulfillments",
          },
          {
            title: "Serial Numbers",
            path: "serial_numbers",
          },
        ]}
      />
      <NavItem
        title="Customer"
        icon={<PeopleRounded className="text-[22px] text-slate-600" />}
        child={[
          {
            title: "Customers",
            path: "customers",
          },
          {
            title: "Groups",
            path: "groups",
          },
        ]}
      />
      <NavItem
        title="Settings"
        icon={<SettingsSuggestRounded className="text-[22px] text-slate-600" />}
        child={[
          {
            title: "General",
            path: "settings_form",
          },
          {
            title: "Outlets",
            path: "outlets",
          },
          {
            title: "Payment Types",
            path: "payment_types",
          },
          {
            title: "Taxes",
            path: "taxes",
          },
          {
            title: "Users",
            path: "users",
          },
        ]}
      />
    </div>
  );
};

export default NavMenu;
