import React, { useContext, useEffect, useState } from "react";
import { Button, InputAuto } from "../../../controls";
import { CartContext } from "./Sell";
import { AppContext } from "../../../App";
import { action, API, controller } from "../../../api/api";
import { AddRounded } from "@mui/icons-material";

const SelectCustomer = () => {
  const { authUser } = useContext(AppContext);
  const { setCartData, cartData } = useContext(CartContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (authUser.id) {
      API(controller.Customer + action.GetAllByOrg).getById(
        authUser.orgId,
        (res) => {
          res &&
            res.status === 200 &&
            setOptions([
              ...res.result.map((j) => ({
                ...j,
                label: `${j.firstName + " " + j.lastName} ${
                  j.CstmrGroup ? "| " + j.CstmrGroup.name : ""
                }`,
                subtitle: `Id: ${j.id} | ${j.phone}`,
              })),
            ]);
        }
      );
    }
    return () => {};
  }, [authUser]);

  const handleCustomer = (e) => {
    setCartData((j) => ({ ...j, customerId: e.target.value }));
  };

  return (
    <div className="flex gap-2 mb-[7px] items-center">
      <InputAuto
        label="Select Customer"
        value={cartData.customerId}
        onChange={handleCustomer}
        options={options}
        size="small"
        fullWidth
      />
      <div>
        <Button className="" variant="text">
          <AddRounded />
        </Button>
      </div>
    </div>
  );
};

export default SelectCustomer;
