import { Button as MuiButton } from "@mui/material";
import React, { useState } from "react";
import { Button, Input } from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { action, API, controller } from "../../../../api/api";
import { RmvEmptyProp, ToFormData } from "../../../../hooks/Method";

const RegFormBody = ({ outletId, regs, setShowForm }) => {
  const [loading, setLoading] = useState(false);
  const { SellRegisterMdl } = Model();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(SellRegisterMdl);

  const validateForm = () => {
    let temp = {};
    temp.cashInDrawer =
      values.cashInDrawer !== "" ? "" : "This field is required.";
    temp.registerId = values.registerId !== "" ? "" : "Please select register.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);

        setShowForm(false);
      };

      API(controller.SellReg + action.Post).post(
        ToFormData(
          RmvEmptyProp({ ...values, checkIn: new Date().toISOString() })
        ),
        onComplete
      );
    }
  };

  const handleTypeChg = (registerId) => {
    setValues((j) => ({ ...j, outletId, registerId }));
  };

  console.log("res: ", regs);

  return (
    <div className="grid gap-5 p-[15px]">
      <div>
        {errors.registerId && (
          <span className="text-red-500 text-[13px] font-medium pl-[5px]">
            {errors.registerId}
          </span>
        )}
        <div className="flex justify-around flex-row flex-wrap	gap-3">
          {regs.map((reg, ind) => (
            <MuiButton
              key={ind + "reg"}
              onClick={() => handleTypeChg(reg.id)}
              varinat="text"
              className="h-[100px] flex-grow !min-w-[150px] !text-[16px]"
              sx={{
                borderBottom: `3px solid ${
                  reg.id === values.registerId
                    ? "var(--primary-color)"
                    : "var(--white-color)"
                }`,
              }}
            >
              <div className="grid items-center">
                <span className="font-medium text-[18px] leading-snug">
                  {reg.name}
                </span>
                {/* <span className="text-[12px]">Register Status</span> */}
              </div>
            </MuiButton>
          ))}
        </div>
      </div>

      <Input
        label="Cash In Drawer"
        name="cashInDrawer"
        value={values.cashInDrawer}
        error={errors.cashInDrawer}
        type="number"
        required={true}
        onChange={handleInpChg}
      />

      <Input
        label="Notes"
        name="notes"
        value={values.notes}
        multiline
        rows={3}
        onChange={handleInpChg}
      />

      <Button
        size="large"
        loading={loading}
        onClick={handleSubmit}
        // className="!text-[18px]"
      >
        Open
      </Button>
    </div>
  );
};

export default RegFormBody;
