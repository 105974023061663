import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const ProdInv = ({ product }) => {
  return (
    <div>
      {product && product.inventories ? (
        <TableContainer className="max-h-[500px] overflow-auto">
          <Table className="min-w-[650px]" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="!text-gray-500">Outlet</TableCell>
                <TableCell className="!text-gray-500">Supplier Price</TableCell>
                <TableCell className="!text-gray-500">Retail Price</TableCell>
                <TableCell className="!text-gray-500">
                  Current inventory
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {product.inventories.map((inv, ind) => (
                <TableRow key={ind}>
                  <TableCell>
                    <span className="text-[16px] text-gray-500">
                      {inv.outlet && inv.outlet.outletName}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-[16px] text-gray-500">
                      ${" "}
                      {(
                        inv.lastSprlPrice ||
                        product.splrPrice ||
                        0
                      ).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      }) || 0}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-[16px] text-gray-500">
                      ${" "}
                      {product.rtlPrice.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      }) || 0}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-[16px] text-gray-500">
                      {inv.inInv || 0}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span className="text-[18px] font-medium p-[15px]">Loading...</span>
      )}
    </div>
  );
};

export default ProdInv;
