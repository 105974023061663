import React from "react";

const LabelComp = ({ label, value, showReadOnly = false, className = "" }) => {
  return (
    <div className={`grid gap-[2px] ${className}`}>
      <span className="text-[11px] text-gray-400 tracking-wide uppercase italic">
        {value !== undefined && value !== "" && label}{" "}
        {showReadOnly && "(Read Only)"}
      </span>
      <span className="text-[16px] font-medium text-gray-600">
        {value || label} &nbsp;
      </span>
    </div>
  );
};

export default LabelComp;
