import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  InputAuto,
  InputMultImg,
  InputSwitch,
  MultiSelect,
} from "../../../../controls";
import { DropLi, Model, UseForm } from "../../../../hooks";
import { Alert, Dialog, Tabs } from "../../../../layout";
import { AddRounded, ArrowBackRounded } from "@mui/icons-material";
import ProdVariants from "./ProdVariants";
import Composite from "./Composite";
import StandardProd from "./StandardProd";
import { AppContext } from "../../../../App";
import { API, action, controller } from "../../../../api/api";
import AddEditTag from "../../Tag/AddEditTag";
import { BGNestedObjToFromData, RmvEmptyProp } from "../../../../hooks/Method";
import InputProdType from "./InputProdType";

const AddEditProd = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { ProdType } = DropLi();
  const { ProdMdl } = Model();
  const [showTagAddForm, setShowTagAddForm] = useState(false);
  const { authUser, selOutlet } = useContext(AppContext);
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    setValues,
    handleFile,
    handleInpCheck,
  } = UseForm(ProdMdl);
  const { SkuTypes } = DropLi();
  const [loading, setLoading] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [cats, setCats] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [subCats, setSubCats] = useState([]);
  const [splrs, setSplrs] = useState([]);
  const [imgSrc, setImgSrc] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser.id) {
      // Get Tags
      API(controller.Outlet + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setOutlets([
            ...res.result.map((j) => ({ ...j, label: j.outletName })),
          ]);
      });

      // Get Taxes
      API(controller.Tax + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setTaxes([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });

      // Get Categories
      API(controller.Category + action.GetAllMainCatByOrg).get((res) => {
        res &&
          res.status === 200 &&
          setCats([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });

      // Get Brand
      API(controller.Brand + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setBrands([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });
      // Get Tags
      API(controller.Tag + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setTags([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });
      // Get Suppliers
      API(controller.Supplier + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setSplrs([...res.result.map((j) => ({ ...j, label: j.splrName }))]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    id &&
      API(controller.Product + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((x) => ({
            ...x,
            ...val,
            imgFiles: [],
            prodTags: [...val.prodTags.map((j) => j.tagId)],
            prodVarAttrs: val.prodVarAttrs.map((j) => ({
              ...j,
              value: [...j.values.map((y) => y.name)],
            })),
            prodVariants: [
              ...val.prodVariants.map((j) => ({ ...j, imgFiles: [] })),
            ],
            compositProds: [
              ...val.compositProds.map((j) => {
                var prodDtl = j.isVariant ? j.varProd : j.prod;
                var name = j.isVariant
                  ? val.name +
                    " / " +
                    prodDtl.attrVal1 +
                    (prodDtl.attrVal2 && ` / ${prodDtl.attrVal2}`) +
                    (prodDtl.attrVal3 && ` / ${prodDtl.attrVal3}`)
                  : prodDtl.name;

                return {
                  ...j,
                  name,
                  splrPrice: prodDtl && prodDtl.splrPrice,
                  prod: prodDtl,
                };
              }),
            ],
          }));
          setImgSrc([...val.imgs.map((j) => ({ src: j.imgUrl, ...j }))]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Get Subcategory
  useEffect(() => {
    if (values.catId) {
      // Get Tags
      API(controller.Category + action.GetAllCatBySubcat).getById(
        values.catId,
        (res) => {
          res &&
            res.status === 200 &&
            setSubCats([...res.result.map((j) => ({ ...j, label: j.name }))]);
        }
      );
    } else {
      setSubCats([]);
      setValues((x) => ({ ...x, subcatId: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.catId]);

  useEffect(() => {
    // This will work only first time or product adding time.
    if (
      outlets.length > 0 &&
      values.id === 0 &&
      (values.inventories.length === 0 || !values.trackInv)
    ) {
      setValues((j) => ({
        ...j,
        inventories: [
          ...outlets.map((y) => ({
            outletName: y.outletName,
            outletId: y.id,
            inv: 0,
            reOrder: "",
            reOrderQty: "",
          })),
        ],
      }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlets, values.trackInv]);

  // Detect supplier default markup.
  useEffect(() => {
    var splrDtls = splrs.find((j) => j.id === values.splrId);
    splrDtls && setValues((j) => ({ ...j, markup: splrDtls.defMarkup }));
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.splrId]);

  useEffect(() => {
    var rltPrice =
      (values.prodType === 3
        ? values.compositProds
            .map((j) => j.splrPrice * j.qty)
            .reduce((x, y) => x + y, 0)
        : values.splrPrice) *
      (1 + values.markup / 100);
    setValues((x) => ({
      ...x,
      rtlPrice: (calcTaxPrice() + rltPrice).toFixed(2),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.compositProds]);

  const validateForm = () => {
    let temp = {};
    temp.firstName = values.firstName !== "" ? "" : "First Name is required.";
    temp.lastName = values.lastName !== "" ? "" : "Last Name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm() && authUser.id) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201 || res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: res.msg,
              onClick: () => navigate("/products"),
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        }
      };
      if (values.id === 0) {
        if (values.prodType === 2) {
          values.inventories = [];
        } else {
          values.prodVarAttrs = [];
        }

        if (values.prodType !== 3) {
          values.compositProds = [];
        }

        API(controller.Product + action.Post).post(
          BGNestedObjToFromData({
            ...values,
            outletId: selOutlet.id,
            orgId: authUser.orgId,
            userId: authUser.id,
            prodTags: values.prodTags.map((j) => ({ ...j, tagId: j })),
            prodVarAttrs: values.prodVarAttrs.map((j) => ({
              attrId: j.attrId,
              values: [...j.value.map((x) => ({ name: x }))],
            })),
          }),
          onComplete
        );
      } else {
        API(controller.Product + action.Put).put(
          BGNestedObjToFromData({
            ...values,
            outletId: "",
            outlet: "",
            prodTags: values.prodTags.map((j) => ({ ...j, tagId: j })),
            prodVarAttrs: values.prodVarAttrs.map((j) => ({
              ...j,
              values: [...j.value.map((x) => ({ name: x }))],
            })),
          }),
          onComplete
        );
      }
    }
  };

  const handleAddNewTag = () => {
    setShowTagAddForm(true);
  };

  const calcRltPrice = (e) => {
    var rltPrice =
      (values.prodType === 3
        ? values.compositProds
            .map((j) => j.splrPrice * j.qty)
            .reduce((x, y) => x + y, 0)
        : values.splrPrice) *
      (1 + e.target.value / 100);
    setValues((x) => ({
      ...x,
      rtlPrice: (calcTaxPrice() + rltPrice).toFixed(2),
    }));
  };

  const calcTaxPrice = (id = values.taxId) => {
    var taxAmt = 0;
    var taxDtl = taxes.find((j) => j.id === id);
    if (taxDtl) {
      var _totalProdAmt =
        values.prodType === 3
          ? values.compositProds
              .map((j) => j.splrPrice * j.qty)
              .reduce((x, y) => x + y, 0)
          : values.splrPrice;
      taxAmt = _totalProdAmt * (taxDtl.rate / 100);
      setValues((j) => ({
        ...j,
        taxPrice: taxAmt.toFixed(2),
      }));
    }
    return taxAmt;
  };

  const handleTaxChg = (e) => {
    handleInpChg(e);
    var splrPrice =
      values.prodType === 3
        ? values.compositProds
            .map((j) => j.splrPrice * j.qty)
            .reduce((x, y) => x + y, 0)
        : values.splrPrice;

    if (splrPrice) {
      var taxAmt = calcTaxPrice(e.target.value);
      var rtlPrice = splrPrice * (1 + values.markup / 100);
      setValues((j) => ({
        ...j,
        taxPrice: taxAmt.toFixed(2),
        rtlPrice: (parseFloat(rtlPrice) + parseFloat(taxAmt)).toFixed(2),
      }));
    }
  };

  const handleMarkup = (rtlPrice) => {
    var splrPrice =
      values.prodType === 3
        ? values.compositProds
            .map((j) => j.splrPrice * j.qty)
            .reduce((x, y) => x + y, 0)
        : values.splrPrice || 0;

    setValues((j) => ({
      ...j,
      markup:
        rtlPrice && splrPrice
          ? (((rtlPrice - splrPrice) / splrPrice) * 100).toFixed(2)
          : "",
    }));
  };

  const handleCalcPrice = (e) => {
    var rltPrice = e.target.value * (1 + values.markup / 100);
    var taxPrice = calcTaxPrice();
    setValues((x) => ({
      ...x,
      rtlPrice: (calcTaxPrice() + rltPrice).toFixed(2),
      taxPrice: taxPrice,
    }));
  };

  useEffect(() => {
    if (values.prodType === 3) {
      setValues((j) => ({
        ...j,
        trackInv: false,
        splrId: "",
        splrCode: "",
        splrPrice: "",
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.prodType]);

  return (
    <>
      <Paper>
        <div className="flex justify-between items-center p-[10px]">
          <div className="flex items-center gap-[10px]">
            <div>
              <IconButton size="small" onClick={() => navigate(-1)}>
                <ArrowBackRounded />
              </IconButton>
            </div>

            <div>
              <h1 className="form-title font-medium text-gray-500 uppercase">
                {id ? "Edit" : "Add"} Product
              </h1>
            </div>
          </div>

          <div>
            <Button onClick={handleSubmit} loading={loading}>
              {id ? "Save Changes" : "Submit"}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="pl-[15px] pr-[15px] pb-[15px]">
          <Tabs
            className="mt-[30px]"
            header={[
              { label: "General Fields", icon: "" },
              { label: "Inventory Fields", icon: "" },
            ]}
          >
            {/* -------- General Fields -------- */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Input
                  label="Name"
                  name="name"
                  required={true}
                  value={values.name}
                  error={errors.name}
                  inputProps={{ maxLength: 300 }}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputAuto
                  label="Brand"
                  name="brandId"
                  required={true}
                  value={values.brandId}
                  error={errors.brandId}
                  onChange={handleInpChg}
                  options={brands}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <div className="flex gap-[5px]">
                  <MultiSelect
                    label="Tags"
                    name="prodTags"
                    required={true}
                    fullWidth
                    value={values.prodTags}
                    onChange={handleInpChg}
                    options={tags}
                  />
                  <Tooltip title="Add New Tag">
                    <IconButton
                      onClick={handleAddNewTag}
                      sx={{ borderRadius: "12px" }}
                      className="bg-primaryXS"
                      size="large"
                    >
                      <AddRounded />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={12} md={3.5}>
                <InputAuto
                  label="Category"
                  name="catId"
                  value={values.catId}
                  onChange={handleInpChg}
                  options={cats}
                />
              </Grid>
              <Grid item xs={12} md={3.5}>
                <InputAuto
                  label="Subcategory"
                  name="subCatId"
                  value={values.subCatId}
                  onChange={handleInpChg}
                  options={subCats}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  label="Description"
                  name="desc"
                  required={true}
                  value={values.desc}
                  error={errors.desc}
                  inputProps={{ maxLength: 600 }}
                  multiline
                  rows={3}
                  onChange={handleInpChg}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="flex flex-wrap gap-[10px]">
                  <InputMultImg
                    label="Product Images"
                    name="imgFiles"
                    maxUpl={5}
                    src={imgSrc}
                    values={values.imgFiles}
                    onChange={handleFile}
                    rmv={(file) => {
                      setValues((x) => ({
                        ...x,
                        imgFiles: x.imgFiles.filter(
                          (j) => j.lastModified !== file.lastModified
                        ),
                        imgs: x.imgs.filter((j) => j.id !== file.id),
                      }));
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            {/* -------- Inventory Fields -------- */}
            <Grid container spacing={3}>
              {/* Product Type */}
              {!values.id && (
                <Grid item xs={12}>
                  <InputProdType
                    name="prodType"
                    value={values.prodType}
                    onChange={handleInpChg}
                    options={ProdType}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <div className="flex items-center pl-[5px] mt-[30px]">
                  <span
                    className="text-gray-500 uppercase font-medium"
                    style={{ minWidth: "200px" }}
                  >
                    SUPPLIER INFORMATION
                  </span>
                  <div className="bs-secondary w-[100%]" />
                </div>
              </Grid>

              {values.prodType !== 3 && (
                <>
                  <Grid item xs={12} md={4}>
                    <Input
                      label="Supplier Code"
                      name="splrCode"
                      value={values.splrCode}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleInpChg}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputAuto
                      label="Supplier"
                      name="splrId"
                      value={values.splrId}
                      onChange={handleInpChg}
                      options={splrs}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Input
                      label="Supplier Price"
                      name="splrPrice"
                      type="number"
                      value={values.splrPrice}
                      onBlur={handleCalcPrice}
                      placeholder="$ 0.00"
                      onChange={handleInpChg}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={3.5}>
                <Input
                  label="Markup"
                  name="markup"
                  placeholder="0.00"
                  value={values.markup}
                  onChange={(e) => {
                    handleInpChg(e);
                    calcRltPrice(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3.5}>
                <InputAuto
                  label="Tax"
                  name="taxId"
                  value={values.taxId}
                  onChange={handleTaxChg}
                  options={taxes}
                />
              </Grid>

              <Grid item xs={12} md={1.5}>
                <div className="grid gap-[10px] items-center">
                  <span className="text-[12px] text-gray-500">Tax Amount</span>
                  <span className="text-[18px]">${values.taxPrice}</span>
                </div>
              </Grid>
              <Grid item xs={12} md={3.5}>
                <Input
                  label="Retail price"
                  name="rtlPrice"
                  placeholder="$ 0.00"
                  type="number"
                  value={values.rtlPrice}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    handleInpChg(e);
                    handleMarkup(e.target.value);
                  }}
                />
              </Grid>

              {values.prodType !== 3 && (
                <Grid item xs={12}>
                  <div className="mt-[20px] mb-[30px]">
                    <div className="mb-[10px]">
                      <span className="text-[16px] font-medium uppercase">
                        INVENTORY LEVELS
                      </span>
                    </div>
                    <div>
                      <div>
                        <InputSwitch
                          label="Track inventory for this product"
                          name="trackInv"
                          checked={values.trackInv}
                          onChange={handleInpCheck}
                        />
                      </div>
                      <span className="text-[14px] text-gray-500 ml-[60px]">
                        Manage orders, transfers and returns accurately and get
                        reports and insights on this product’s performance
                      </span>
                    </div>
                  </div>
                </Grid>
              )}

              {values.prodType === 1 && (
                <StandardProd
                  {...{
                    setValues,
                    values,
                    handleInpChg,
                    SkuTypes,
                  }}
                />
              )}
              {values.prodType === 2 && (
                <ProdVariants {...{ setValues, values, selOutlet, outlets }} />
              )}
              {values.prodType === 3 && (
                <>
                  <StandardProd
                    {...{
                      setValues,
                      values,
                      handleInpChg,
                      SkuTypes,
                    }}
                  />
                  <Composite {...{ setValues, values, selOutlet }} />
                </>
              )}
            </Grid>
          </Tabs>
        </div>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Add/Edit Tag */}
      {showTagAddForm && (
        <Dialog show={showTagAddForm} onClose={false} maxWidth="sm">
          <AddEditTag
            {...{
              setTags,
              setProdVal: setValues,
              setShowForm: setShowTagAddForm,
              fromProdPg: true,
            }}
          />
        </Dialog>
      )}
    </>
  );
};
export default AddEditProd;
