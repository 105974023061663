import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../../layout";
import { Button } from "../../../controls";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DropLi } from "../../../hooks";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";

const Users = () => {
  const navigate = useNavigate();
  const { Role } = DropLi();
  const { selOutlet, setLoader } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleAddEdit = (id) => {
    navigate(`/user_form/${id || ""}`);
  };

  useEffect(() => {
    setLoader(true);
    if (selOutlet.id) {
      API(controller.User + action.GetAllByOrg).getById(
        selOutlet.orgId,
        (res) => {
          res && res.status === 200 && setUsers([...res.result]);
          setLoader(false);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this user?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setUsers((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        API(controller.User).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <div className="border !border-gray-100 flex gap-2 p-[3px] shadow-sm rounded-[12px] w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(props.id)}
          size="small"
          color="primary"
        >
          <EditRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add User</Button>
    </div>
  );
  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "user", label: "User" },
          { id: "access", label: "Access" },
          { id: "contact", label: "Contact" },
          { id: "lastVisit", label: "Last Visit" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={users.map((j) => {
          var role = Role.find((x) => x.id === j.role);
          var accStatus = j.accStatus === 1 ? "Active" : "Inactive";
          return {
            ...j,
            accStatus: accStatus,
            user: (
              <div className="flex mt-[5px] mb-[5px]">
                <div className="mr-[20px]">
                  <Avatar src={j.photo} className="shadow-lg" />
                </div>
                <div className="grid">
                  <span className="font-medium mb-[3px]">
                    {j.firstName + " " + j.lastName}
                  </span>
                  {j.accStatus === 1 ? (
                    <span className="text-[12px] font-medium bg-success text-white pl-[5px] pr-[5px] pt-[2px] pb-[2px] w-fit rounded-[8px] uppercase">
                      Active
                    </span>
                  ) : (
                    <span className="text-[12px] font-medium bg-error text-white pl-[5px] pr-[5px] pt-[2px] pb-[2px] w-fit rounded-[12px] uppercase">
                      Inactive
                    </span>
                  )}
                </div>
              </div>
            ),
            access: (
              <div className="grid ">
                <span className="text-[16px] font-medium text-gray-500 cursor-pointer">
                  {j.email}
                </span>
                <span className="text-[12px] font-medium text-gray-500 cursor-pointer">
                  {role && role.label} | {j.outlet && j.outlet.outletName}
                </span>
              </div>
            ),
            contact: (
              <div className="grid">
                <a
                  className="text-[14px] primary-color cursor-pointer mb-[3px]"
                  href={`mailto:${j.email}`}
                >
                  ✉️ {j.email}
                </a>
                <a
                  className="text-[14px] primary-color cursor-pointer"
                  href={`tel:${j.phone}`}
                >
                  📞 {j.phone}
                </a>
              </div>
            ),

            lastVisit: (
              <span className="text-[16px] font-medium text-gray-500 cursor-pointer">
                {j.lastVisit}
              </span>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Users;
