import React, { useState } from "react";
import { Select } from "../../controls";
import DropLi from "../../hooks/DropLi";
import { Box, Paper } from "@mui/material";

const HomeRepFilter = () => {
  const { PeriodicType } = DropLi();
  const [fltDate, setFltDate] = useState(3);

  const handleDate = (e) => {
    setFltDate(e.target.value);
  };

  return (
    <Paper className="p-[10px]">
      <div className="flex flex-wrap gap-[15px] justify-between items-center">
        <div className="grid gap-[5px]">
          <span className="text-[14px] text-slate-500">Welcome 🎉</span>
          <span className="text-[20px] text-slate-500 font-medium">
            Mohammad Ismile
          </span>
        </div>

        <Box sx={{ width: { md: "250px", xs: "100%" } }}>
          <Select
            variant="outlined"
            value={fltDate}
            onChange={handleDate}
            options={PeriodicType}
          />
        </Box>
      </div>
    </Paper>
  );
};

export default HomeRepFilter;
