import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { AppContext } from "../../../../App";
import { no_img } from "../../../../assets";
import { Inventory2Rounded } from "@mui/icons-material";

const CompositProdInv = ({ product, handleShowStock }) => {
  const { outlets } = useContext(AppContext);
  const [compositProds, setCompositProds] = useState([]);

  useEffect(() => {
    if (product && product.compositProds.length) {
      setCompositProds([
        ...product.compositProds.map((j) => {
          if (j.isVariant && j.varProd) {
            return {
              ...j,
              skuCode: j.varProd.skuCode,
              rtlPrice: j.varProd.rtlPrice,
              splrPrice: j.varProd.inventories
                ? j.varProd.inventories[0].lastSplrPrice || j.varProd.splrPrice
                : j.varProd.splrPrice,
              inventories: j.varProd.inventories || [],
              imgUrl: j.varProd.imgs[0] ? j.varProd.imgs[0].imgUrl : no_img,
              label:
                j.varProd &&
                j.varProd.product.name +
                  " / " +
                  j.varProd.attrVal1 +
                  (j.varProd.attrVal2 && ` / ${j.varProd.attrVal2}`) +
                  (j.varProd.attrVal3 && ` / ${j.varProd.attrVal3}`),
            };
          } else {
            return {
              ...j,
              skuCode: j.prod.skuCode,
              rtlPrice: j.prod.rtlPrice,
              splrPrice: j.prod.inventories
                ? j.prod.inventories[0].lastSplrPrice || j.prod.splrPrice
                : j.prod.splrPrice,
              inventories: j.prod.inventories || [],
              imgUrl: j.prod.imgs[0] ? j.prod.imgs[0].imgUrl : no_img,
              label: j.prod.name,
            };
          }
        }),
      ]);
    } else {
      setCompositProds([]);
    }

    return () => {};
  }, [product]);

  return (
    <div>
      {compositProds ? (
        <TableContainer className="max-h-[500px] overflow-auto">
          <Table className="min-w-[650px]" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="!text-gray-500">Stock</TableCell>
                <TableCell className="!text-gray-500">Unit</TableCell>
                <TableCell className="!text-gray-500">Product</TableCell>
                <TableCell className="!text-gray-500">Supplier Price</TableCell>
                <TableCell className="!text-gray-500">Retail Price</TableCell>
                {outlets.map((outlet, ind) => (
                  <TableCell key={ind}>
                    <div className="grid leading-snug text-gray-500">
                      <span className="text-[12px] font-normal">
                        Inventory on{" "}
                      </span>
                      <span>{outlet.outletName}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {compositProds.map((compProd, ind) => (
                <TableRow key={ind}>
                  <TableCell>
                    <Tooltip title="View Stock Details">
                      <IconButton
                        onClick={() => handleShowStock(compProd, 3)}
                        color="primary"
                        size="small"
                        className="shadow-lg !m-[5px] !bg-slate-200"
                      >
                        <Inventory2Rounded className="!text-[18px] !m-[2px]" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <span className="text-[18px] text-gray-500">
                      {compProd.qty}
                    </span>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-3 items-center text-gray-500">
                      <img
                        className="w-[50px] h-[50px] object-cover rounded-lg shadow-md"
                        src={compProd.imgUrl}
                        alt={compProd.label}
                      />

                      <div className="grid gap-[1px]">
                        <span className="text-[16px]">{compProd.label}</span>
                        <span className="text-[12px]">{compProd.skuCode}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <span className="text-[18px] text-gray-500">
                      $ {compProd.splrPrice}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-[18px] text-gray-500">
                      $ {compProd.rtlPrice}
                    </span>
                  </TableCell>
                  {outlets.map((outlet, ind) => {
                    var inv = compProd.inventories.find(
                      (j) => j.outletId === outlet.id
                    );
                    return (
                      <TableCell key={ind}>
                        <span className="text-[18px] text-gray-500">
                          {inv ? inv.inInv : 0}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default CompositProdInv;
