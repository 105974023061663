import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import { Button } from "../../../controls";
import { IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import AddEditAttr from "./AddEditAttr";
import moment from "moment";

const ProdAttrs = () => {
  const [updData, setUpdData] = useState();
  const { authUser, setLoader } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [attrs, setAttrs] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser) {
      setLoader(true);
      API(controller.ProdAttr + action.GetAllByOrg).get((res) => {
        res && res.status === 200 && setAttrs([...res.result]);
        setLoader(false);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this attribute?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res) {
            if (res && res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setAttrs((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
          }
        };
        API(controller.ProdAttr).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val)}
          size="small"
          color="primary"
          className="mr-5 p-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
          className="mr-5 p-5"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Attribute</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "name", label: "Name" },
          { id: "createDate", label: "Create Date" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={[
          ...attrs.map((j) => ({
            ...j,
            createDate: moment(j.createDate).format("ddd, DD MMM YYYY hh:mm A"),
          })),
        ]}
      />

      {/* Attribute Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditAttr
            {...{ setAttrs, setShowForm: setShowForm, updData, setAlert }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default ProdAttrs;
