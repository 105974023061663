import moment from "moment";
import { useEffect, useState } from "react";

export const WinDims = () => {
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);
  return windowDimensions;
};

export const GetHours = (
  startTime = moment().set({ hour: 8, minute: 0, second: 0 }),
  endTime = moment().set({ hour: 22, minute: 0, second: 0 })
) => {
  // Create an array to store the times
  const timeList = [];

  // Start from the startTime and add one hour until we reach or exceed the endTime
  let currentTime = startTime.clone();
  while (currentTime.isSameOrBefore(endTime)) {
    timeList.push(currentTime.format("h:mm A"));
    currentTime.add(1, "hour");
  }
  return timeList;
};

export const GetMonths = () => {
  const date = new Date();
  return [
    `01/${date.getFullYear()}`,
    `02/${date.getFullYear()}`,
    `03/${date.getFullYear()}`,
    `04/${date.getFullYear()}`,
    `05/${date.getFullYear()}`,
    `06/${date.getFullYear()}`,
    `07/${date.getFullYear()}`,
    `08/${date.getFullYear()}`,
    `09/${date.getFullYear()}`,
    `10/${date.getFullYear()}`,
    `11/${date.getFullYear()}`,
    `12/${date.getFullYear()}`,
  ];
};

export const IsEmptyObj = (obj) =>
  obj !== undefined && obj !== null && Object.keys(obj).length === 0
    ? true
    : false;

export const RmvEmptyProp = (obj) =>
  !IsEmptyObj(obj) &&
  Object.fromEntries(
    Object.keys(obj)
      .filter((k) => obj[k] !== null && obj[k] !== "" && obj[k] !== undefined)
      .map((k) => [k, obj[k]])
  );

export const RmvArryEmptyProp = (val) =>
  Object.fromEntries(
    Object.keys(val)
      .filter((k) => val[k] !== "")
      .map((key) => [
        key,
        Array.isArray(val[key])
          ? val[key].map((j) => RmvEmptyProp(j))
          : val[key],
      ])
  );

export const ToFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) =>
    !Array.isArray(object[key])
      ? formData.append(key, object[key])
      : object[key].forEach((val) => formData.append(key, val))
  );
  return formData;
};

export const GetDueDate = (date) => {
  return `${moment(date, "mm/DD/yyyy").format("DD")}/${moment().format(
    "MM/yyyy"
  )}`;
};

export const NestedFormData = (object, keyName = "imgFiles") => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (!Array.isArray(object[key])) formData.append(key, object[key]);
    else if (key === keyName)
      object[key].forEach((val) => formData.append(key, val));
    else
      object[key].forEach((val, i) =>
        Object.keys(val).forEach((key1) =>
          formData.append(`${key}[${i}][${key1}]`, val[key1])
        )
      );
  });
  return formData;
};

export const BGNestedObjToFromData = (obj) => {
  const formData = new FormData();

  const traverse = (current, parentKey = "") => {
    for (const key in current) {
      if (current.hasOwnProperty(key)) {
        const value = current[key];

        // Skip if the property is empty or null
        if (value === null || value === undefined || value === "") {
          continue;
        }

        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (value instanceof File) {
          formData.append(newKey, value);
        } else if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            if (value[i] instanceof File) {
              formData.append(`${newKey}`, value[i]);
            } else if (value[i] instanceof Object) {
              traverse(value[i], `${newKey}[${i}]`);
            } else {
              formData.append(`${newKey}[${i}]`, value[i]);
            }
          }
        } else if (value instanceof Object) {
          traverse(value, newKey);
        } else {
          formData.append(newKey, value);
        }
      }
    }
  };

  traverse(obj);
  return formData;
};

export const PrintDiv = (printDivId) => {
  var printContents = document.getElementById(printDivId).innerHTML;
  var frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  var frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;

  frameDoc.document.open();
  frameDoc.document.write("<html><head><title></title>");
  frameDoc.document.write("</head><body>");
  frameDoc.document.write(printContents);
  frameDoc.document.write("</body></html>");
  frameDoc.document.close();

  setTimeout(function () {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 100);

  return false;
};

export const RandomCode = (length = 10) => {
  if (typeof length !== "number" || length <= 0) {
    throw new Error("Length must be a positive number");
  }

  const maxRandomValue = Math.pow(10, length) - 1; // Calculate the maximum random value
  const randomNum = Math.floor(Math.random() * maxRandomValue); // Generate a random number within the desired length

  return randomNum;
};

export const MaxLength = (val, length = 5) => {
  return val && val.replace(/[^0-9.]/g, "").slice(0, length);
};

export const getDaysByMonth = (month) => {
  const daysInMonth = moment(month, "YYYY/MM").daysInMonth();
  return Array.from({ length: daysInMonth }, (v, k) => k + 1);
};

export const getCurrentWeek = () => {
  const today = moment();
  const startOfWeek = today.clone().startOf("week");
  const endOfWeek = today.clone().endOf("week");

  const weekDays = [];
  let currentDay = startOfWeek.clone();

  while (currentDay.isSameOrBefore(endOfWeek, "day")) {
    weekDays.push(currentDay.format("MMM DD"));
    currentDay.add(1, "day");
  }

  return weekDays;
};

// Locale Storage, Create, Read, Delete, Update
export const lsSave = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const lsGet = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const lsUpdate = (key, newValue) => {
  localStorage.setItem(key, JSON.stringify(newValue));
};

export const lsDelete = (key) => {
  localStorage.removeItem(key);
};
