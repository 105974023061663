import { Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputCheck,
  InputImg,
} from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Form } from "../../../layout";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditType = (props) => {
  const { setAlert, setShowForm, updData, setCats } = props;
  const { CategoryMdl } = Model();
  const [loading, setLoading] = useState(false);
  const [parentCats, setParentCats] = useState([]);
  const { selOutlet, authUser } = useContext(AppContext);

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    handleInpCheck,
    resetFormControls,
  } = UseForm(CategoryMdl);

  useEffect(() => {
    if (authUser) {
      API(controller.Category + action.GetAllMainCatByOrg).get((res) => {
        res.status === 200 &&
          setParentCats([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    updData && setValues((x) => ({ ...x, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : "Name is required.";
    if (!values.isMainCat)
      temp.parentCatId =
        values.parentCatId !== "" ? "" : "Parent category is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && (res.status === 201 || res.status === 200)) {
          if (setCats && res.result.id) {
            updData
              ? setCats((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setCats((x) => [res.result, ...x]);
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
          setShowForm(false);
        } else if (res)
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.orgId = selOutlet.orgId;
        values.userId = authUser.id;
        values.outletId = selOutlet.id;
        API(controller.Category + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else
        API(controller.Category + action.Put).put(
          ToFormData(
            RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
          ),
          onComplete
        );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    values.isMainCat &&
      !updData &&
      setValues((x) => ({ ...x, parentCatId: "" }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isMainCat]);

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <Grid container spacing={2} className="p-[15px]">
            <Grid
              item
              xs={12}
              className="text-center !p-[20px] !pt-[30px] w-[100%]"
            >
              <span className="font-medium text-[24px] uppercase text-gray-500">
                {updData ? "Update" : "Add"} Category
              </span>
              <div className="relative flex justify-center mt-[5px]">
                <div className="border-bottom w-[60px]" />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2.5}>
                <Grid item xs={12}>
                  <Input
                    label="Name"
                    name="name"
                    value={values.name}
                    error={errors.name}
                    required={true}
                    onChange={handleInpChg}
                  />
                  {!values.id && (
                    <div className="ml-[10px]">
                      <InputCheck
                        label="Main Category"
                        name="isMainCat"
                        size="small"
                        checked={values.isMainCat}
                        onChange={handleInpCheck}
                      />
                    </div>
                  )}
                </Grid>
                {!values.isMainCat && (
                  <Grid item xs={12}>
                    <InputAuto
                      label="Parent Category"
                      name="parentCatId"
                      value={values.parentCatId}
                      error={errors.parentCatId}
                      required={true}
                      onChange={handleInpChg}
                      options={parentCats}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Input
                    label="Description"
                    name="desc"
                    value={values.desc}
                    error={errors.desc}
                    multiline
                    rows={3}
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputImg
                label="Image"
                name="imgFile"
                src={values.img}
                value={values.imgFile}
                rmv={() => {
                  setValues({ ...values, imgFile: "", img: "" });
                }}
                onChange={handleFile}
                error={errors.imgFile}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="flex gap-[10px]">
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button fullWidth size="large" loading={loading} type="submit">
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

export default AddEditType;
