import {
  DoneAllRounded,
  EditRounded,
  LocalShippingRounded,
  RemoveRedEyeRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { action, API, controller } from "../../../api/api";

const StockAction = ({ setAlert, setStocks, val }) => {
  const navigate = useNavigate();

  const handleAddEdit = (id) => {
    navigate(`/order_stock_form/${id || ""}`);
  };

  const handleViewOrder = (id) => {
    navigate(`/view_order/${id}`);
  };

  const handleMarkAsSent = (val) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: `You’re about to change the order "${val.orderNum}" to sent`,
      subTitle:
        "You’ll be able to see which orders have already been sent to suppliers. If you need to make changes after hearing back from your supplier, you can edit your sent order. This action cannot be undone.",
      confirmBtnTitle: "Change status to sent",
      onConfirm: () => {
        API(controller.Stock + action.MarkStatusAsSent).getById(
          val.id,
          (res) => {
            if (res && res.result && res.status === 200) {
              setStocks((j) =>
                j.map((x) => (x.id === res.result.id ? res.result : x))
              );
              setAlert({
                isOpen: true,
                type: "success",
                title: "Success",
                subTitle: res.msg,
              });
            } else
              setAlert({
                isOpen: true,
                type: "error",
                title: "Error",
                subTitle: res.msg,
              });
          }
        );
      },
    });
  };

  const handleReceived = (id) => {
    navigate(`/receive_delivery_form/${id}`);
  };

  return (
    <div className="border !border-slate-200 shadow-md rounded-[8px] w-fit flex gap-2 p-[2px]">
      <Tooltip title="View">
        <IconButton
          onClick={() => handleViewOrder(val.id)}
          size="small"
          color="primary"
          className="ml-[5px] mr-[5px] p-[5px]"
        >
          <RemoveRedEyeRounded className="!text-[18px] m-[2px]" />
        </IconButton>
      </Tooltip>
      {val.status !== 3 && val.status !== 2 && (
        <>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleAddEdit(val.id)}
              size="small"
              color="primary"
              className="mr-[5px] p-[5px]"
            >
              <EditRounded className="!text-[18px] m-[2px]" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Receive">
            <IconButton
              onClick={() => handleReceived(val.id)}
              size="small"
              color="primary"
              className="mr-[5px] p-[5px]"
            >
              <DoneAllRounded className="!text-[18px] m-[2px]" />
            </IconButton>
          </Tooltip>
          {val.status !== 4 && (
            <Tooltip title="Mark as Sent">
              <IconButton
                onClick={() => handleMarkAsSent(val)}
                size="small"
                color="primary"
                className="mr-[5px] p-[5px]"
              >
                <LocalShippingRounded className="!text-[18px] m-[2px]" />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default StockAction;
