import { CloseRounded } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import React, { Fragment } from "react";
import { Input } from "../../../../controls";

const EditAttrValueName = (props) => {
  const { setValues, values, setShowForm } = props;

  const handleClose = () => {
    setShowForm((x) => !x);
  };

  const handleChg = (e, prevVal, attr) => {
    setValues((j) => ({
      ...j,
      prodVarAttrs: [
        ...j.prodVarAttrs.map((y) =>
          y.id === attr.id
            ? {
                ...y,
                value: [
                  ...y.value.map((x) => (x === prevVal ? e.target.value : x)),
                ],
              }
            : y
        ),
      ],
    }));
  };

  return (
    <>
      <Box sx={{ minWidth: { xs: "auto", md: "450px" } }}>
        <div
          className="flex items-center justify-between p-[10px]"
          style={{ borderBottom: "1px solid var(--graySM-color)" }}
        >
          <div>
            <span className="text-[20px] uppercase text-gray-500">
              Rename Product Value
            </span>
          </div>
          <div>
            <IconButton onClick={handleClose}>
              <CloseRounded className="text-[28px]" />
            </IconButton>
          </div>
        </div>

        <Grid container spacing={3} className="p-[20px]">
          {values.prodVarAttrs &&
            values.prodVarAttrs.map((attr, ind) => (
              <Fragment key={ind}>
                <Grid item xs={12} md={6}>
                  <div>{attr.name}</div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="grid gap-[15px]">
                    {attr.value.map((j, index) => (
                      <div key={index}>
                        <Input
                          defaultValue={j}
                          onBlur={(e) => handleChg(e, j, attr)}
                          className="shadow-sm"
                          variant="outlined"
                          size="small"
                          inputProps={{ maxLength: 200 }}
                          sx={{ minWidth: "130px" }}
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
                {ind + 1 !== values.prodVarAttrs.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </Fragment>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default EditAttrValueName;
