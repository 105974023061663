import { Divider, Paper } from "@mui/material";
import React from "react";
import SaleHistory from "./SaleHistory";

const Accounts = () => {
  return (
    <Paper className="pt-[5px] p-[15px]">
      <div className="grid g-[10px] mt-[5px]">
        <div className="text-gray-color flex justify-between">
          <span className="font-medium">Allow on account balance:</span>
          <span>Yes, unlimited</span>
        </div>
        <Divider />
        <div className="text-gray-color flex justify-between">
          <span className="font-medium">Balance:</span>
          <span>$0.00</span>
        </div>
        <Divider />
        <div className="text-gray-color flex justify-between">
          <span className="font-medium">Total spent:</span>
          <span>$0.00</span>
        </div>
        <Divider />
        <div className="text-gray-color flex justify-between">
          <span className="font-medium">Last 12 months:</span>
          <span>$0.00</span>
        </div>
      </div>
      <div className="flex items-center mt-[30px]">
        <span
          className="text-gray-500 uppercase font-medium text-[14px] min-w-[70px]"
          style={{ minWidth: "70px" }}
        >
          History
        </span>
        <div className="border border-gray-500 w-[100%]" />
      </div>
      <div className="mt-[30px]">
        <SaleHistory />
      </div>
    </Paper>
  );
};

export default Accounts;
