import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { Input, Select } from "../../../../controls";
import { MaxLength, RandomCode } from "../../../../hooks/Method";

const StandardProd = (props) => {
  const { setValues, values, handleInpChg, SkuTypes } = props;

  const handleInpInvChg = (e, invt) => {
    const { name, value } = e.target;
    setValues((j) => ({
      ...j,
      inventories: [
        ...j.inventories.map((y) =>
          y.outletId === invt.outletId ? { ...y, [name]: value } : y
        ),
      ],
    }));
  };

  useEffect(() => {
    if (values.prodType !== 2) {
      setValues((j) => ({ ...j, skuCode: j.skuCode || RandomCode() }));
    } else {
      setValues((j) => ({ ...j, skuCode: "" }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.prodType]);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Select
          label="SKU Code Type"
          name="skuType"
          value={values.skuType}
          onChange={handleInpChg}
          options={SkuTypes}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Input
          label="SKU Code"
          name="skuCode"
          value={values.skuCode}
          inputProps={{ maxLength: 100 }}
          onChange={handleInpChg}
        />
      </Grid>
      {values.trackInv && (
        <Grid item xs={12}>
          <TableContainer className="">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Outlet</TableCell>
                  <TableCell>Current inventory </TableCell>
                  <TableCell>Re-order point </TableCell>
                  <TableCell>Re-order quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.inventories.map((invt, ind) => (
                  <TableRow key={ind}>
                    <TableCell>
                      <span className="text-[16px] font-medium text-gray-500">
                        {invt.outletName ||
                          (invt.outlet && invt.outlet.outletName)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Input
                        onBlur={(e) => handleInpInvChg(e, invt)}
                        defaultValue={invt.inInv}
                        name="inInv"
                        disabled={values.id ? true : false}
                        className="shadow-sm"
                        variant="outlined"
                        size="small"
                        type="number"
                        onInput={(e) =>
                          (e.target.value = MaxLength(e.target.value, 10))
                        }
                        sx={{ width: "140px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        onBlur={(e) => handleInpInvChg(e, invt)}
                        defaultValue={invt.reOrder}
                        name="reOrder"
                        className="shadow-sm"
                        variant="outlined"
                        size="small"
                        type="number"
                        onInput={(e) =>
                          (e.target.value = MaxLength(e.target.value, 10))
                        }
                        sx={{ width: "140px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        onBlur={(e) => handleInpInvChg(e, invt)}
                        defaultValue={invt.reOrderQty}
                        name="reOrderQty"
                        className="shadow-sm"
                        variant="outlined"
                        size="small"
                        type="number"
                        onInput={(e) =>
                          (e.target.value = MaxLength(e.target.value, 10))
                        }
                        sx={{ width: "140px" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
};

export default StandardProd;
