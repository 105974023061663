import { Button } from "@mui/material";
import React from "react";

const ButtonGroup = ({ options, value, name, onChange }) => {
  const handleInpChg = (val) => {
    var e = {
      target: {
        value: val.id,
        name: name,
      },
    };
    onChange(e);
  };

  return (
    <div className="flex ml-[10px] bg-primarySM rounded-[12px]">
      {options &&
        options.map((item, ind) => (
          <Button
            key={ind}
            variant="text"
            size="small"
            onClick={() => handleInpChg(item)}
            className={`h-[100%] !text-[16px] ${
              item.id === value && "bg-primary !text-white"
            }`}
            sx={{ bgcolor: "transparent" }}
          >
            {item.label}
          </Button>
        ))}
    </div>
  );
};

export default ButtonGroup;
