import { Box, Grid } from "@mui/material";
import React from "react";
import Dialog from "./Dialog";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button } from "../controls";
import { useState } from "react";

const AlertIcon = ({ alert }) => {
  switch (alert.type) {
    case "success":
      return (
        <CheckCircleRoundedIcon color={alert.type} sx={{ fontSize: "70px" }} />
      );
    case "warning":
      return (
        <WarningAmberRoundedIcon color="primary" sx={{ fontSize: "70px" }} />
      );
    case "error":
      return (
        <ErrorOutlineRoundedIcon color="primary" sx={{ fontSize: "70px" }} />
      );
    case "info":
      return <InfoOutlinedIcon color={alert.type} sx={{ fontSize: "70px" }} />;
    default:
      break;
  }
};

const AlertTextIcon = ({ alert }) => {
  return (
    <div className="text-center grid pl-[10px] pr-[10px]">
      <div>
        {alert.hideIcon !== true ? (
          <div className="mt-[5px] mb-[20px]">
            {" "}
            <AlertIcon {...{ alert }} />{" "}
          </div>
        ) : (
          <div className="mt-[10px]" />
        )}
      </div>
      <div className="text-gray-600 grid gap-4 ">
        <span className="text-[22px] leading-tight font-medium">
          {alert.title}
        </span>
        <span>{alert.subTitle}</span>
      </div>
    </div>
  );
};

const ConfirmationCom = (props) => {
  const { alert, setAlert } = props;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="grid gap-7 text-right">
        <AlertTextIcon alert={{ ...alert, type: "error" }} />
        <div className="flex justify-center gap-2">
          <Button
            className="text-[16px] min-w-[120px] shadow-none w-fit"
            variant="contained"
            color={alert.cancelBtnColor ? alert.cancelBtnColor : "primaryXS"}
            onClick={() =>
              alert.onCancel !== undefined
                ? alert.onCancel
                : setAlert({ ...alert, isOpen: false })
            }
          >
            {alert.cancelBtnTitle ? alert.cancelBtnTitle : "Cancel"}
          </Button>
          <Button
            className="text-[16px] shadow-none w-fit"
            variant="contained"
            color={alert.confirmBtnColor ? alert.confirmBtnColor : "primary"}
            loading={loading}
            onClick={() => {
              setLoading(true);
              alert.onConfirm && alert.onConfirm();
            }}
          >
            {alert.confirmBtnTitle ? alert.confirmBtnTitle : "Yes"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default function Alert(props) {
  const { alert, setAlert } = props;

  return (
    <>
      {alert && (
        <Dialog show={alert.isOpen} maxWidth="md">
          <Box
            className="p-[14px]"
            sx={{ minWidth: { xs: "auto", sm: "300px" }, maxWidth: "500px" }}
          >
            {alert.type === "confirmation" ? (
              <ConfirmationCom {...{ alert, setAlert }} />
            ) : (
              <div>
                <AlertTextIcon {...{ alert }} />
                <Grid className="mt-[20px]">
                  <Button
                    className="text-[18px] font-medium"
                    variant="contained"
                    sx={{ boxShadow: "none" }}
                    color={alert.type !== "" ? alert.type : "primary"}
                    fullWidth
                    onClick={() => {
                      setAlert({ ...alert, isOpen: false });
                      alert.onClick && alert.onClick();
                    }}
                  >
                    Ok
                  </Button>
                </Grid>
              </div>
            )}
          </Box>
        </Dialog>
      )}
    </>
  );
}
