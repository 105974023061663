import { Dialog, Tabs } from "../../../../layout";
import React, { useEffect, useState } from "react";
import ProdInv from "./ProdInv";
import ProdDtls from "./ProdDtls";
import { IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { action, API, controller } from "../../../../api/api";
import VarProdInv from "./VarProdInv";
import CompositProdInv from "./CompositProdInv";
import ProdShortDtls from "./ProdShortDtls";
import StockDetails from "./StockDetails";
import { no_img } from "../../../../assets";

const ViewProd = ({ id, setShowForm }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [showStock, setShowStock] = useState(false);
  const [viewStockProd, setViewStockProd] = useState();

  useEffect(() => {
    if (id) {
      setLoading(true);
      API(controller.Product + action.GetById).getById(id, (res) => {
        res.status === 200 &&
          setProduct({
            ...res.result,
            imgUrl:
              res.result && res.result.imgs[0]
                ? res.result.imgs[0].imgUrl
                : no_img,
          });
        setLoading(false);
      });
    }
    return () => {};
  }, [id]);

  const handleClose = () => {
    setShowForm((j) => !j);
  };

  const handleShowStock = (prod, type) => {
    setViewStockProd({ ...prod, type });
    setShowStock(!showStock);
  };

  const renderInv = (product) => {
    switch (product.prodType) {
      case 1:
        return <ProdInv {...{ product, handleShowStock }} />;
      case 2:
        return <VarProdInv {...{ product, handleShowStock }} />;
      case 3:
        return <CompositProdInv {...{ product, handleShowStock }} />;
      default:
        break;
    }
  };

  return (
    <>
      <div className="grid">
        {/* Action Section */}
        <div className="flex justify-between items-center gap-2 border-b p-[5px]">
          <span className="text-[20px] ml-[10px] uppercase text-gray-500 font-medium">
            Product Details
          </span>
          <div>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </div>
        </div>

        <div>
          <ProdShortDtls {...{ product, handleShowStock }} />
        </div>

        {loading ? (
          "Loading..."
        ) : (
          <>
            <div>
              <Tabs header={[{ label: "Inventory" }, { label: "Details" }]}>
                {renderInv(product)}
                <ProdDtls {...{ product }} />
              </Tabs>
            </div>
          </>
        )}
      </div>

      {/* View Product */}
      {showStock && (
        <Dialog show={showStock} onClose={false} maxWidth="lg">
          <StockDetails
            {...{ setShowForm: setShowStock, product: viewStockProd }}
          />
        </Dialog>
      )}
    </>
  );
};

export default ViewProd;
