import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../../layout";
import { IconButton, Tooltip } from "@mui/material";
import {
  AppRegistrationRounded,
  ClearRounded,
  EditRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../controls";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";

const Outlets = () => {
  const navigate = useNavigate();
  const { authUser, setLoader } = useContext(AppContext);
  const [outlets, setOutlets] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    if (authUser.id) {
      API(controller.Outlet + action.GetAllByOrg).get((res) => {
        res && res.status === 200 && setOutlets([...res.result]);
        setLoader(false);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (id) => {
    navigate(`/outlet_form/${id || ""}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this outlet?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setOutlets((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        API(controller.Outlet).Delete(id, onComplete);
      },
    });
  };

  const handleReg = (outletId) => {
    navigate(`/outlet/registers/${outletId || ""}`);
  };

  const ActionComp = (val) => (
    <div className="border !border-gray-100 flex gap-2 p-[3px] shadow-sm rounded-[12px] w-fit">
      <Tooltip title="Registers">
        <IconButton
          onClick={() => handleReg(val.id)}
          size="small"
          color="primary"
        >
          <AppRegistrationRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val.id)}
          size="small"
          color="primary"
        >
          <EditRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
    </div>
  );
  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Outlet</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "nameAndTax", label: "Outlet" },
          { id: "contact", label: "Contact" },
          { id: "location", label: "Location" },
        ]}
        tblData={outlets.map((j) => {
          return {
            ...j,
            nameAndTax: (
              <div className="grid font-medium gap-[1px]">
                <span className="text-[15px]">{j.outletName}</span>
                <span className="text-[12px]">
                  Tax: {(j.tax && j.tax.rate) || 0}%
                </span>
              </div>
            ),
            contact: (
              <div className="grid">
                {j.email && (
                  <a
                    className="text-[14px] primary-color cursor-pointer mb-[3px]"
                    href={`mailto:${j.email}`}
                  >
                    ✉️ {j.email}
                  </a>
                )}
                <a
                  className="text-[14px] primary-color cursor-pointer"
                  href={`tel:1955206146`}
                >
                  📞 {j.phone}
                </a>
              </div>
            ),
            location: (
              <span className="whitespace-pre-wrap text-[14px]">{`${j.address1}, ${j.address2}, ${j.city}, ${j.state} ${j.zip}`}</span>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Outlets;
