import React from "react";
import { Divider, Paper } from "@mui/material";

const SalesAvg = () => {
  return (
    <Paper className="shadow-xs bg-white h-[100%] grid items-center">
      <div className="grid text-gray-500 gap-[10px] mb-[30px] p-[20px]">
        <span className="text-[20px] uppercase">Average sale value</span>
        <span className="text-[28px] font-medium">$83.79</span>
        <span className="text-[14px]">$1.96 more than last Thursday</span>
      </div>
      <Divider />
      <div className="grid text-gray-500 gap-[10px] p-[20px] mt-[30px]">
        <span className="text-[20px] uppercase">Average sale value</span>
        <span className="text-[28px] font-medium">3.7</span>
        <span className="text-[14px]">0.96 more than last Thursday</span>
      </div>
    </Paper>
  );
};

export default SalesAvg;
