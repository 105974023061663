import React from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const StoreMetricsTable = () => {
  return (
    <Paper className="bs-primaryMD">
      <div className="p-[10px] mb-[20] mt-[5px]">
        <span className="w-[100%] text-[18px] font-medium text-gray-500 border-left uppercase">
          hs - store metrics
        </span>
      </div>
      <TableContainer className="mb-[10px]">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="primary-color whitespace-nowrap">
                Indicator
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Today
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Same Day Last Week
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Change Same Day Last Week
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                This Week
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Change Last Week
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Last Week To Date
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Last Week
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                This Month
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Change Last Month
              </TableCell>
              <TableCell
                className="primary-color whitespace-nowrap"
                align="right"
              >
                Last Month To Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {storeMetrics.map((item, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="whitespace-nowrap">{item.name}</TableCell>
                <TableCell align="right">{item.today}</TableCell>
                <TableCell align="right">{item.sameDayLastWeek}</TableCell>
                <TableCell align="right">
                  <div className="flex gap-[10px] justify-end">
                    {item.chgSameDayLastWeek < 0 ? (
                      <>
                        <ArrowDownwardRounded className="text-[20px] error-color" />
                        <span>{item.chgSameDayLastWeek}%</span>
                      </>
                    ) : (
                      <>
                        <ArrowUpwardRounded className="text-[20px] success-color" />
                        <span>+{item.chgSameDayLastWeek}%</span>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">{item.thisWeek}</TableCell>
                <TableCell align="right">
                  <div className="flex gap-[10px] justify-end">
                    {item.chgSameDayLastWeek < 0 ? (
                      <>
                        <ArrowDownwardRounded className="text-[20px] error-color" />
                        <span>{item.chgSameDayLastWeek}%</span>
                      </>
                    ) : (
                      <>
                        <ArrowUpwardRounded className="text-[20px] success-color" />
                        <span>+{item.chgSameDayLastWeek}%</span>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">{item.thisWeek}</TableCell>
                <TableCell align="right">{item.thisWeek}</TableCell>
                <TableCell align="right">{item.thisWeek}</TableCell>
                <TableCell align="right">
                  <div className="flex gap-[10px] justify-end">
                    {item.chgSameDayLastWeek < 0 ? (
                      <>
                        <ArrowDownwardRounded className="text-[20px] error-color" />
                        <span>{item.chgSameDayLastWeek}%</span>
                      </>
                    ) : (
                      <>
                        <ArrowUpwardRounded className="text-[20px] success-color" />
                        <span>+{item.chgSameDayLastWeek}%</span>
                      </>
                    )}
                  </div>
                </TableCell>
                <TableCell align="right">{item.thisWeek}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StoreMetricsTable;

const storeMetrics = [
  {
    id: 1,
    name: "Net Sales (ALL DEPT.)",
    today: "$699",
    sameDayLastWeek: "$4,006",
    chgSameDayLastWeek: -20,
    thisWeek: "$637",
    lastWeek: -40,
    lastWeekToDate: "$3,829",
  },
  {
    id: 1,
    name: "Retail Sales",
    today: "$699",
    sameDayLastWeek: "$4,006",
    chgSameDayLastWeek: -20,
    thisWeek: "$637",
    lastWeek: -40,
    lastWeekToDate: "$3,829",
  },
  {
    id: 1,
    name: "# of Sales",
    today: "$699",
    sameDayLastWeek: "$4,006",
    chgSameDayLastWeek: 50,
    thisWeek: "$637",
    lastWeek: 60,
    lastWeekToDate: "$3,829",
  },
  {
    id: 1,
    name: "Retail AT",
    today: "$699",
    sameDayLastWeek: "$4,006",
    chgSameDayLastWeek: -20,
    thisWeek: "$637",
    lastWeek: -40,
    lastWeekToDate: "$3,829",
  },
  {
    id: 1,
    name: "Retail UPT",
    today: "$699",
    sameDayLastWeek: "$4,006",
    chgSameDayLastWeek: -20,
    thisWeek: "$637",
    lastWeek: -40,
    lastWeekToDate: "$3,829",
  },
  {
    id: 1,
    name: "Food Sales",
    today: "$699",
    sameDayLastWeek: "$4,006",
    chgSameDayLastWeek: 50,
    thisWeek: "$637",
    lastWeek: 60,
    lastWeekToDate: "$3,829",
  },
];
