import React from "react";
import { Grid, Paper } from "@mui/material";

const Details = (props) => {
  const { cstmr, gender, accBalType } = props;
  return (
    <Paper className="pt-[5px] bg-white shadow-xs">
      {cstmr ? (
        <Grid
          container
          spacing={3}
          className="p-[15px] text-gray-700 mb-[10px]"
        >
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Customer Id:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.id}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Customer Code:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.cstmrCode}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Customer Group:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.cstmrGroup && cstmr.cstmrGroup.name}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              First Name:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.firstName}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Last Name:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {" "}
              {cstmr.lastName}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Gender:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {" "}
              {gender}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Phone:
            </span>
            <a
              className="link text-[16px] font-medium text-gray-400"
              href={`tel:${cstmr.phone}`}
            >
              {cstmr.phone}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Email:
            </span>
            <a
              className="link text-[16px] font-medium text-gray-400"
              href={`mailto:${cstmr.email}`}
            >
              {cstmr.email}
            </a>
          </Grid>

          <Grid item xs={12}>
            <div className="flex items-center mt-[20px]">
              <span
                className="text-gray-500 uppercase font-medium text-[14px]"
                style={{ minWidth: "70px" }}
              >
                Address
              </span>
              <div className="border border-gray-500 w-[100%]" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Address Line 1:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.address1}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Address Line 2:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.address2}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              City:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.city}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              State:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.state}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              ZIP:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.zip}
            </span>
          </Grid>
          <Grid item xs={12}>
            <div className="flex items-center mt-[20px]">
              <span
                className="text-gray-500 uppercase font-medium text-[14px]"
                style={{ minWidth: "150px" }}
              >
                Additional Details
              </span>
              <div className="border border-gray-500 w-[100%]" />
            </div>
          </Grid>

          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Company Name
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.company}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Date of Birth
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.dob}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Website:
            </span>
            <a
              className="link text-[16px] font-medium text-gray-400"
              href={cstmr.website}
              target="_blank"
              rel="noreferrer"
            >
              {cstmr.website}
            </a>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Twitter:
            </span>
            <a
              className="link text-[16px] font-medium text-gray-400"
              href={cstmr.twitter}
              target="_blank"
              rel="noreferrer"
            >
              {cstmr.twitter}
            </a>
          </Grid>
          <Grid item xs={12}>
            <div className="flex items-center mt-[20px]">
              <span
                className="text-gray-500 uppercase font-medium text-[14px]"
                style={{ minWidth: "80px" }}
              >
                Settings
              </span>
              <div className="border border-gray-500 w-[100%]" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Allow an on account balance:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {accBalType}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Balance Limit:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              ${cstmr.balLimit}
            </span>
          </Grid>
          <Grid item xs={12} md={4} className="grid">
            <span className="text-[12px] uppercase mb-[5px] text-gray-400">
              Tax:
            </span>
            <span className="text-[16px] font-medium text-gray-400">
              {cstmr.tax && cstmr.tax.name}
            </span>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </Paper>
  );
};

export default Details;
