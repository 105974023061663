import React from "react";
import { Grid, Paper } from "@mui/material";
import StoreMetricsWidget from "./StoreMetricsWidget";
import StoreMetricsTable from "./StoreMetricsTable";
import FoodSalesByCat from "./FoodSalesByCat";
import AssociatePerf from "./AssociatePerf";

const StoreMetrics = () => {
  return (
    <Paper className="shadow-xs p-[15px] bg-white mt-[30px] !rounded-[25px]">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={5}>
          <StoreMetricsWidget />
        </Grid>
        <Grid item xs={12} lg={7}>
          <FoodSalesByCat />
        </Grid>
        <Grid item xs={12}>
          <StoreMetricsTable />
        </Grid>
        <Grid item xs={12}>
          <AssociatePerf />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StoreMetrics;
