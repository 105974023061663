import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../../layout";
import { Button } from "../../../../controls";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { AppContext } from "../../../../App";
import { API, action, controller } from "../../../../api/api";
import moment from "moment";
import AddEditRegister from "./AddEditRegister";
import { useParams } from "react-router-dom";

const Registers = () => {
  const { id } = useParams();
  const [updData, setUpdData] = useState();
  const { authUser, setLoader } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [regs, setRegs] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser) {
      setLoader(true);
      API(controller.Register + action.GetAllByOutlet).getById(id, (res) => {
        res.status === 200 && setRegs([...res.result]);
        setLoader(false);
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this register?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res) {
            if (res && res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setRegs((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
          }
        };
        API(controller.Register).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="border !border-gray-100 flex gap-2 p-[3px] shadow-sm rounded-[12px] w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val)}
          size="small"
          color="primary"
        >
          <EditRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Register</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "name", label: "Reg. Name" },
          { id: "statusChip", label: "Status" },
          { id: "details", label: "Details" },
          { id: "create", label: "Create" },
        ]}
        tblData={[
          ...regs.map((j) => {
            return {
              ...j,
              statusChip:
                j.status === 1 ? (
                  <Chip label="Open" color="success" />
                ) : (
                  <Chip label="Closed" color="grayXS" />
                ),
              details: (
                <div className="grid grid-rows-2 grid-flow-col gap-1 font-medium text-[14px]">
                  {j.printReceipt && <span>Print Receipt</span>}
                  {j.emailReceipt && <span>Email Receipt</span>}
                </div>
              ),
              create: (
                <div className="grid gap-[1px] font-medium">
                  {j.user && (
                    <span>{j.user.firstName + " " + j.user.lastName}</span>
                  )}
                  <span className="text-[14px]">
                    {moment(j.createDate).format("ddd, DD MMM YYYY hh:mm A")}
                  </span>
                </div>
              ),
            };
          }),
        ]}
      />

      {/* Add Brand Form*/}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditRegister
            {...{
              setRegs,
              setShowForm: setShowForm,
              updData,
              setAlert,
              outletId: id,
            }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Registers;
