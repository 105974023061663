import { CloseRounded } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { action, API, controller } from "../../../../api/api";
import { ToFormData } from "../../../../hooks/Method";
import moment from "moment";
import { AppContext } from "../../../../App";

const StockDetails = ({ product, setShowForm }) => {
  const { outlets } = useContext(AppContext);
  const [stocks, setStocks] = useState([]);

  const handleClose = () => {
    setShowForm((x) => !x);
  };

  useEffect(() => {
    var _param = null;

    switch (product.type) {
      case 1:
        _param = { id: product.id, type: 1 };
        break;
      case 2:
        _param = { id: product.id, type: 2 };
        break;
      case 3:
        _param = product.isVariant
          ? { id: product.varProdId, type: 2 }
          : { id: product.prodId, type: 1 };
        break;
      default:
        break;
    }

    _param &&
      API(controller.Stock + action.GetStockByProd).post(
        ToFormData(_param),
        (res) =>
          res.status === 200
            ? setStocks([...res.result])
            : console.error("Error: ", res)
      );

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <div className="grid">
      {/* Action Section */}
      <div className="flex justify-between items-center gap-2 border-b p-[5px]">
        <span className="text-[20px] ml-[10px] uppercase text-gray-500 font-medium">
          Stock Details
        </span>
        <div>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <div className="flex gap-3 items-center text-gray-500 p-[15px] border-b-2">
        <img
          className="w-[50px] h-[50px] object-cover rounded-lg shadow-md"
          src={product.imgUrl}
          alt={product.label}
        />

        <div className="grid gap-[1px]">
          <span className="text-[16px] font-medium">{product.label}</span>
          <span className="text-[12px]">{product.skuCode}</span>
        </div>
      </div>
      {/* Data Table */}
      <div>
        <TableContainer className="max-h-[500px] overflow-auto">
          <Table className="min-w-[650px]" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="!text-gray-500">Date</TableCell>
                <TableCell className="!text-gray-500">Order Number</TableCell>
                <TableCell className="!text-gray-500">Supplier</TableCell>
                <TableCell className="!text-gray-500">Delivery To</TableCell>
                <TableCell className="!text-gray-500">Cost Price</TableCell>
                {outlets.map((outlet, ind) => (
                  <TableCell key={ind}>
                    <div className="grid leading-snug text-gray-500">
                      <span className="text-[12px] font-normal">
                        Order for{" "}
                      </span>
                      <span>{outlet.outletName}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stocks.map((stock, ind) => (
                <TableRow key={ind}>
                  <TableCell>
                    {stock.stock && stock.stock.receiveDate && (
                      <span className="text-[16px] text-gray-500">
                        {moment(stock.stock.receiveDate).format(
                          "dddd, DD MMM YYYY hh:mm A"
                        )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {stock.stock && (
                      <span className="text-[16px] text-gray-500">
                        {stock.stock.orderNum}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {stock.stock && stock.stock.splr && (
                      <span className="text-[16px] text-gray-500">
                        {stock.stock.splr.company}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {stock.stock && stock.stock.recipientOutlet && (
                      <span className="text-[16px] text-gray-500">
                        {stock.stock.recipientOutlet.outletName}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    <span className="text-[18px] text-gray-500 font-medium">
                      $ {stock.unitPrice}
                    </span>
                  </TableCell>
                  {outlets.map((outlet, ind) => {
                    var inv = stock.orderInvsFor.find(
                      (j) => j.outletId === outlet.id
                    );
                    return (
                      <TableCell key={ind}>
                        <span className="text-[18px] text-gray-500 font-medium">
                          {inv ? inv.qty : 0}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default StockDetails;
