import axios from "axios";

// const BaseUrl = "http://localhost:5124/api/";
const BaseUrl = "https://dev.sm876.com/api/";

export const controller = {
  User: "User",
  Auth: "Auth",
  Org: "Org",
  Outlet: "Outlet",
  Tax: "Tax",
  PayType: "PayType",
  CstmrGroup: "CstmrGroup",
  Customer: "Customer",
  Category: "Category",
  Supplier: "Supplier",
  Brand: "Brand",
  Tag: "Tag",
  Product: "Product",
  ProdAttr: "ProdAttr",
  Stock: "Stock",
  Inventory: "Inventory",
  Register: "Register",
  SellReg: "SellReg",
  Sell: "Sell",
};

export const action = {
  Search: "/Search",
  GetAll: "/GetAll",
  GetById: "/GetById",
  Post: "/Post",
  Put: "/Put",
  Delete: "/Delete",
  GetAuthUser: "/GetAuthUser",
  Login: "/Login",
  Logout: "/Logout",
  Forgot: "/Forgot",
  ChgPass: "/ChgPass",
  GetAllByOutlet: "/GetAllByOutlet",
  GetAllByOrg: "/GetAllByOrg",
  GetAuthUserOutlets: "/GetAuthUserOutlets",
  GetAuthUserOrg: "/GetAuthUserOrg",
  CheckCstmrCode: "/CheckCstmrCode",
  GetAllMainCatByOrg: "/GetAllMainCatByOrg",
  GetAllCatBySubcat: "/GetAllCatBySubcat",
  FindProducts: "/FindProducts",
  MarkAsReceived: "/MarkAsReceived",
  CancelOrder: "/CancelOrder",
  GetInventory: "/GetInventory",
  GetDetailsById: "/GetDetailsById",
  ReceiveDelivery: "/ReceiveDelivery",
  MarkStatusAsSent: "/MarkStatusAsSent",
  ActiveAndInactive: "/ActiveAndInactive",
  GetStockByProd: "/GetStockByProd",
  GetOutletsWithRegisters: "/GetOutletsWithRegisters",
  GetCashierOpenReg: "/GetCashierOpenReg",
  CheckInvExist: "/CheckInvExist",
};

export const API = (endpoint) => {
  const url = BaseUrl + endpoint + "/";
  const get = (onComplete) =>
    axios
      .get(url, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const getById = (id, onComplete) =>
    axios
      .get(url + id, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const getByIdAngPg = (id, pageNumber, rowsPerPage, onComplete) =>
    axios
      .get(url, {
        params: { id: id, pageNumber: pageNumber, rowsPerPage: rowsPerPage },
        withCredentials: true,
      })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const post = (data, onComplete) =>
    axios
      .post(url, data, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const put = (data, onComplete) =>
    axios
      .put(url, data, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const Delete = (id, onComplete) =>
    axios
      .delete(url + id, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });
  return {
    get,
    getById,
    post,
    put,
    Delete,
    getByIdAngPg,
  };
};
