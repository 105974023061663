import {
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { no_img } from "../../../../assets";
import { Input } from "../../../../controls";
import { ClearRounded } from "@mui/icons-material";
import { MaxLength } from "../../../../hooks/Method";
import { Alert } from "../../../../layout";
import InputSelectItem from "./InputSelectItem";

const OrderItems = ({ values, setValues, stores, isReceiveForm }) => {
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setValues((j) => ({
      ...j,
      stockProds: j.stockProds.map((x) => {
        var _orderInvsFor = x.orderInvsFor;
        var _totalPrice = x.totalPrice;
        var _totalQty = x.totalQty;

        // Add or Delete Outlets
        if (x.orderInvsFor.length > j.orderingFor.length) {
          _orderInvsFor = x.orderInvsFor.filter((orderInv) =>
            j.orderingFor.includes(orderInv.outletId)
          );
        } else if (j.orderingFor.length > x.orderInvsFor.length) {
          var _newOutlets = j.orderingFor.filter(
            (outletId) => !x.orderInvsFor.some((z) => z.outletId === outletId)
          );
          if (_newOutlets) {
            _orderInvsFor = [
              ..._newOutlets.map((newOutletId) => ({
                outletId: newOutletId,
                qty: "",
              })),
              ..._orderInvsFor,
            ];
          }
        }

        // Calculate Price and Quantity
        _totalQty =
          _orderInvsFor
            .map((y) => parseFloat(y.qty))
            .reduce((x, y) => x + y, 0) || 0;
        _totalPrice = _totalQty * parseFloat(x.unitPrice);

        return {
          ...x,
          orderInvsFor: _orderInvsFor,
          totalPrice: _totalPrice,
          totalQty: _totalQty,
        };
      }),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.orderingFor]);

  const handlePrice = (e, prod) => {
    const price = e.target.value;
    setValues((j) => ({
      ...j,
      stockProds: j.stockProds.map((x) => {
        // Default Price Per Product
        var updPrice = x.unitPrice;
        // Total Price
        var updTotalPrice = x.totalPrice;

        if (x.prodId === prod.prodId && x.varProdId === prod.varProdId) {
          updPrice = price;

          // total quantity
          var _totalQty = x.orderInvsFor
            .map((inv) => parseFloat(inv.qty))
            .reduce((x, y) => x + y, 0);

          // Total Price
          updTotalPrice = _totalQty * parseFloat(price);
        }

        return {
          ...x,
          unitPrice: updPrice,
          totalPrice: updTotalPrice,
        };
      }),
    }));

    setValues((j) => {
      return {
        ...j,
      };
    });
  };

  const handleQty = (e, prod, outletId) => {
    const qty = e.target.value;
    setValues((stock) => ({
      ...stock,
      stockProds: stock.stockProds.map((_prod) => {
        var _orderInvsFor = _prod.orderInvsFor;
        var _totalPrice = _prod.totalPrice;

        if (
          _prod.prodId === prod.prodId &&
          _prod.varProdId === prod.varProdId
        ) {
          // Upadte Quantity
          _orderInvsFor = _prod.orderInvsFor.map((j) =>
            j.outletId === outletId
              ? { ...j, qty: qty, receiveQty: isReceiveForm ? qty : 0 }
              : j
          );

          // Total quantity
          var _totalQty = _orderInvsFor
            .map((j) => parseFloat(j.qty || 0))
            .reduce((x, y) => x + y, 0);

          // Total Price
          _totalPrice = _totalQty * parseFloat(prod.unitPrice);

          return {
            ..._prod,
            orderInvsFor: _orderInvsFor,
            totalPrice: _totalPrice,
            totalQty: _totalQty,
          };
        } else {
          return {
            ..._prod,
          };
        }
      }),
    }));
  };

  const handleDlt = (prod) => {
    setValues((j) => ({
      ...j,
      stockProds: [
        ...j.stockProds.filter(
          (x) => x.prodId !== prod.prodId || x.varProdId !== prod.varProdId
        ),
      ],
    }));
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* Component Title */}
        <Grid item xs={12}>
          <div className="flex items-center pl-[5px] mt-[20px]">
            <span
              className="text-gray-color uppercase font-medium"
              style={{ minWidth: "100px" }}
            >
              Products
            </span>
            <div className="bs-secondary w-[100%]" />
          </div>
        </Grid>
        {/* Search Box */}
        {isReceiveForm === true && values.id ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <InputSelectItem {...{ values, setValues, setAlert }} />
          </Grid>
        )}
        <div></div>
        {/* Item Table */}
        <Grid item xs={12}>
          <TableContainer className="bs-secondary rounded-[15px] bg-inherit overflow-auto">
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  {values.stockProds[0] &&
                    values.stockProds[0].orderInvsFor.map((val, i) => {
                      var _store = stores.find((j) => j.id === val.outletId);
                      return (
                        _store && (
                          <TableCell key={i}>{_store.outletName}</TableCell>
                        )
                      );
                    })}
                  <TableCell>Cost Price</TableCell>
                  <TableCell>
                    <div className="grid font-bold">
                      <div
                        className="uppercase text-gray-500 flex gap-5 text-xs"
                        style={{ width: "max-content" }}
                      >
                        <span>Total Quantity: </span>
                        <span className="text-sm !font-medium text-gray-500 uppercase">
                          {values.stockProds &&
                            values.stockProds
                              .map((j) => parseFloat(j.totalQty))
                              .reduce((x, y) => x + y, 0)
                              .toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })}
                        </span>
                      </div>
                      <div
                        className="uppercase text-gray-500 flex gap-9 text-xs"
                        style={{ width: "max-content" }}
                      >
                        <span>Total Cost: </span>
                        <span className="text-sm !font-medium text-gray-500 uppercase">
                          ${" "}
                          {values.stockProds &&
                            values.stockProds
                              .map((j) => parseFloat(j.totalPrice))
                              .reduce((x, y) => x + y, 0)
                              .toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.stockProds.map((prod, i) => (
                  <TableRow hover key={i}>
                    <TableCell>
                      <div
                        className="flex items-center gap-[10px]"
                        style={{ minWidth: "400px" }}
                      >
                        {/* if it is Recevied Delivery form then hide delete product action */}
                        {isReceiveForm === true && values.id ? (
                          <></>
                        ) : (
                          <div className="mr-[10px]">
                            <Tooltip title="Delete">
                              <button
                                onClick={() => handleDlt(prod)}
                                className="bg-red-50 hover:text-white text-red-600 hover:bg-red-500 shadow-lg rounded-full border-none h-[20px] w-[20px]"
                              >
                                <ClearRounded className="!text-[18px]" />
                              </button>
                            </Tooltip>
                          </div>
                        )}

                        <img
                          src={prod.img ? prod.img : no_img}
                          alt="product_img"
                          className="img-fluid w-[60px] object-cover rounded-[10px] shadow-xs"
                        />

                        <div className="mr-[20px] grid ml-[10px]">
                          <span className="text-[14px] font-medium">
                            {prod.name}
                          </span>
                          <span className="text-[12px] text-gray-500">
                            {prod.subtitle}
                          </span>
                        </div>
                      </div>
                    </TableCell>

                    {prod.orderInvsFor.map((_val, i) => (
                      <TableCell key={i}>
                        <div className="grid gap-1">
                          <Input
                            variant="outlined"
                            size="small"
                            placeholder="Quantity"
                            sx={{ minWidth: "120px" }}
                            onChange={(e) => handleQty(e, prod, _val.outletId)}
                            value={_val.qty}
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 8))
                            }
                          />
                          <span className="text-[12px] font-semibold tracking-wider ml-1 text-slate-500 ">
                            In Stock: {_val.previousStock}
                          </span>
                        </div>
                      </TableCell>
                    ))}
                    <TableCell>
                      <Input
                        variant="outlined"
                        placeholder="0.00"
                        size="small"
                        sx={{ minWidth: "120px" }}
                        value={prod.unitPrice}
                        onChange={(e) => handlePrice(e, prod)}
                        onInput={(e) =>
                          (e.target.value = MaxLength(e.target.value, 8))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    {/* Accounts || Total Calculation */}
                    <TableCell>
                      <div className="text-gray-500 grid gap-1">
                        <div
                          className="flex gap-3"
                          style={{ width: "max-content" }}
                        >
                          <span>Total Quantity: </span>
                          <span className="font-bold">
                            {(prod.totalQty || 0).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        <div
                          className="flex gap-3"
                          style={{ width: "max-content" }}
                        >
                          <span>Total Cost: </span>
                          <span className=" font-bold">
                            &nbsp;&nbsp;&nbsp;$&nbsp;
                            {(prod.totalPrice || 0).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default OrderItems;
