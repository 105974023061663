import {
  Collapse,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useState } from "react";
import { EastRounded } from "@mui/icons-material";

const NavItem = (props) => {
  const { icon, path, title, child = [] } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handlePage = (url) => {
    navigate(url);
  };

  const btnClass = {
    padding: "7px 20px 7px 20px",
  };

  const sxActive = {
    "& .MuiListItemIcon-root": {
      color: "var(--primaryDark-color) !important",
    },
    "& .MuiTypography-root": {
      color: "var(--primaryDark-color)",
      fontWeight: "500",
    },
    "&": {
      padding: "7px 20px 7px 20px",
      backgroundColor: "var(--white-color)",
    },
  };

  useEffect(() => {
    child.length > 0 &&
      !child.some((j) => location.pathname.includes(j.path)) &&
      setOpen(false);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Grid className="mb-[15px]">
      {child.length > 0 ? (
        <div className={open ? "bg-primaryXS" : ""}>
          <ListItemButton
            onClick={() => setOpen(!open)}
            sx={open ? sxActive : btnClass}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
            {/* Child Menu */}
            {open ? (
              <ExpandLessRoundedIcon color="primary" />
            ) : (
              <ExpandMoreRoundedIcon />
            )}
          </ListItemButton>
          <Collapse in={open}>
            <div className="mt-[10px]">
              {child.map((item, i) => (
                <ListItemButton
                  key={i}
                  onClick={() => handlePage(item.path)}
                  component={NavLink}
                  to={item.path}
                  sx={{
                    padding: "5px 0px 5px 20px",
                    mb: "7px !important",
                    borderRadius: "0px !important",
                    fontWeight: "400",
                    "&.active": {
                      borderRight: "0.3rem solid var(--primaryDark-color)",
                      backgroundColor: "var(--white-color) !important",

                      "& .MuiTypography-root": {
                        color: "var(--primaryDark-color)",
                      },
                      "& .MuiListItemIcon-root": {
                        color: "var(--primaryDark-color) !important",
                      },
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "35px !important" }}>
                    <EastRounded className="fs-18" />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              ))}
            </div>
          </Collapse>
        </div>
      ) : (
        <ListItemButton
          onClick={() => handlePage(path)}
          component={NavLink}
          to={path}
          sx={{
            "&.active": {
              ...sxActive,
              borderRight: "0.2rem solid var(--primary-color)",
            },
            "&": btnClass,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      )}
    </Grid>
  );
};

export default NavItem;
