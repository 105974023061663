import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import { Button } from "../../../controls";
import { IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import AddEditTag from "./AddEditTag";
import moment from "moment";

const Tags = () => {
  const [updData, setUpdData] = useState();
  const { authUser, setLoader } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [tags, setTags] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser.id) {
      setLoader(true);
      API(controller.Tag + action.GetAllByOrg).get((res) => {
        res && res.status === 200 && setTags([...res.result]);
        setLoader(false);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this tag?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res) {
            if (res && res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setTags((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
          }
        };
        API(controller.Tag).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="border !border-gray-100 flex gap-2 p-[3px] shadow-sm rounded-[12px] w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val)}
          size="small"
          color="primary"
        >
          <EditRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Tag</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "name", label: "Name" },
          { id: "desc", label: "Description" },
          { id: "prod", label: "Number of Products" },
          { id: "createDate", label: "Create Date" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={[
          ...tags.map((j) => ({
            ...j,
            createDate: moment(j.createDate).format("ddd, DD MMM YYYY hh:mmA"),
          })),
        ]}
      />

      {/* Add/Edit Tag */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="sm">
          <AddEditTag
            {...{ setTags, setShowForm: setShowForm, updData, setAlert }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Tags;
