import React, { useContext, useEffect, useState } from "react";
import { Alert } from "../../../layout";
import { Button } from "../../../controls";
import {} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import StatusWidget from "./ViewOrder/StatusWidget";
import StockAction from "./StockAction";
import StockFilter from "./StockFilter";
import Table from "../../../layout/Table/Table";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const Stocks = () => {
  const navigate = useNavigate();
  const { selOutlet, setLoader, rowsPerTable } = useContext(AppContext);
  const [totalRecord, setTotalRecord] = useState(null);
  const [activePgNum, setActivePgNum] = useState(0);
  const [stocks, setStocks] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  // Search Tearms
  const [fltStatus, setFltStatus] = useState("");
  const [fltOutletId, setFltOutletId] = useState();
  const [searchType, setSearchType] = useState();
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    getStock(0, rowsPerTable);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  useEffect(() => {
    setTblData([...stocks]);
    return () => {};
  }, [stocks]);

  const getStock = (pageNum, rowsPerPage) => {
    if (selOutlet.id) {
      setLoader(true);
      API(controller.Stock + action.GetAllByOrg).getByIdAngPg(
        selOutlet.orgId,
        pageNum,
        rowsPerPage,
        (res) => {
          if (res && res.status === 200) {
            setStocks([...res.result]);
            setTotalRecord(res.totalRecord);
          } else setStocks([]);
          setLoader(false);
        }
      );
    }
  };

  const handleNextPg = (pageNum, rowsPerPage) => {
    if (fltStatus !== "all" || fltOutletId || searchType || searchVal) {
      handleSearch(
        searchType,
        searchVal,
        fltStatus,
        fltOutletId,
        pageNum,
        rowsPerPage
      );
    } else getStock(pageNum, rowsPerTable);
  };

  const handleOrder = () => {
    navigate("/order_stock_form");
  };
  const handleReceive = () => {
    navigate("/receive_stock_form");
  };

  const handleSearchSubmit = (type, value, rowsPerPage) => {
    setActivePgNum(0);
    handleSearch(type, value, fltStatus, fltOutletId, 0, rowsPerPage);
  };

  const handleSearch = (
    type,
    value,
    status,
    outletId,
    pageNumber,
    rowsPerPage
  ) => {
    setLoader(true);
    API(controller.Stock + action.Search).post(
      ToFormData(
        RmvEmptyProp({
          type,
          value,
          status: status === "all" ? "" : status,
          outletId: outletId,
          pageNumber,
          rowsPerPage,
        })
      ),
      (res) => {
        setLoader(false);
        if (res && res.status === 200) {
          setStocks([...res.result]);
          setTotalRecord(res.totalRecord);
        } else {
          setStocks([]);
          setTotalRecord(0);
        }
      }
    );
  };

  const handleFltChg = (status, outletId) => {
    setFltStatus(status);
    setFltOutletId(outletId);
    setActivePgNum(0);
    handleSearch(searchType, searchVal, status, outletId, 0, rowsPerTable);
  };

  const handleSearchChg = (type, value) => {
    setSearchType(type);
    setSearchVal(value);
  };

  const ActionComp = (val) => <StockAction {...{ val, setAlert, setStocks }} />;

  const HeaderComp = () => (
    <div className="flex flex-wrap gap-[10px]">
      <Button onClick={() => handleOrder()}>Order Stock</Button>
      <Button
        onClick={() => handleReceive()}
        variant="text"
        className="!pl-[15px] !pr-[15px]"
      >
        Receive Stock
      </Button>
    </div>
  );

  return (
    <>
      <StockFilter onChange={handleFltChg} />
      <Table
        onChange={handleSearchChg}
        onSubmit={handleSearchSubmit}
        onPageChg={handleNextPg}
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        totalRecord={totalRecord}
        pageNum={activePgNum}
        setPageNum={setActivePgNum}
        searchOptions={[
          { id: "order_number", label: "Order Number" },
          { id: "supplier", label: "Supplier" },
          { id: "invoice", label: "Supplier Invoice" },
          { id: "company", label: "Company" },
        ]}
        tblHead={[
          { id: "number", label: "Number" },
          { id: "from", label: "From" },
          { id: "to", label: "To" },
          { id: "orderStatus", label: "Status" },
          { id: "date", label: "Date Create/Update" },
          { id: "qty", label: "Total Qty" },
          { id: "cost", label: "Total Cost" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={tblData.map((j) => {
          return {
            ...j,
            number: (
              <div className="pb-[5px] pt-[5px] grid">
                <span>{j.orderNum}</span>

                <span className="text-[12px]">
                  Due Date:{" "}
                  <span className="font-medium">
                    {new Date(j.deliveryDate).toDateString()}
                  </span>
                </span>
              </div>
            ),
            splrName: j.splrName,
            splrCompany: j.splrCompany,
            from: (
              <div className="grid">
                <span>{j.splrCompany}</span>
                <span className="text-[14px]">{j.splrName}</span>
              </div>
            ),
            to: j.recipientOutlet && j.recipientOutlet.outletName,
            orderStatus: (
              <StatusWidget
                status={j.status}
                isOverdue={j.isOverdue}
                className="!h-[25px]"
              />
            ),

            date: (
              <div className="grid">
                <span className="text-[14px]">
                  Create: {new Date(j.createDate).toDateString()}
                </span>
                {j.updateDate && (
                  <span className="text-[14px]">
                    Update: {new Date(j.updateDate).toDateString()}
                  </span>
                )}
              </div>
            ),
            qty: j.totalQty.toLocaleString("en-US", {
              maximumFractionDigits: 3,
            }),
            cost:
              "$ " +
              j.totalOrderAmt.toLocaleString("en-US", {
                maximumFractionDigits: 3,
              }),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Stocks;
