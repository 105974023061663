import { Divider, Grid, IconButton, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputImg,
  InputPass,
  Select,
} from "../../../controls";
import { DropLi, Model, UseForm } from "../../../hooks";
import { Alert, Form, Tabs } from "../../../layout";
import { ArrowBackRounded } from "@mui/icons-material";
import { PassValidation } from "../../../utils";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";

const AddEditUser = (props) => {
  const { fromMyAcc = false } = props;
  const { id } = useParams();
  const { UserMdl } = Model();
  const { selOutlet, authUser } = useContext(AppContext);
  const { Role, Status, Gender } = DropLi();
  const navigate = useNavigate();
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    setValues,
    handleFile,
    handleInpPass,
    passValid,
    handleInpDate,
  } = UseForm(UserMdl);
  const [loading, setLoading] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser.id) {
      API(controller.Outlet + action.GetAllByOrg).get((res) => {
        res &&
          res.status === 200 &&
          setOutlets([
            ...res.result.map((j) => ({ ...j, label: j.outletName })),
          ]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    id &&
      API(controller.User + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((x) => ({ ...x, ...val }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.firstName = values.firstName !== "" ? "" : "First name is required.";
    temp.lastName = values.lastName !== "" ? "" : "Last name is required.";
    temp.email = values.email !== "" ? "" : "Email is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.outletId = values.outletId !== "" ? "" : "Outlet is required.";
    temp.role = values.role !== "" ? "" : "Role is required.";
    temp.status = values.status !== "" ? "" : "Status is required.";
    if (!values.id || values.password) {
      temp.password = values.password !== "" ? "" : "Password is required.";
      temp.confirmPass =
        values.confirmPass !== ""
          ? values.confirmPass === values.password
            ? ""
            : "Password and Confirm password not match"
          : "Confirm Password is required.";
    }
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && selOutlet && selOutlet.id) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201 || res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: `User ${id ? "Updated" : "Added"} Successfully.`,
              onClick: () => !fromMyAcc && navigate("/users"),
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        }
      };
      if (values.id === 0) {
        values.outletId = selOutlet.id;
        values.orgId = selOutlet.orgId;
        API(controller.User + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else {
        API(controller.User + action.Put).put(
          ToFormData(
            RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
          ),
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-[10px] flex justify-between items-center pt-[15px] pl-[15px] pr-[15px]">
            <div className="flex items-center gap-[10px]">
              <div>
                <IconButton size="small" onClick={() => navigate(-1)}>
                  <ArrowBackRounded />
                </IconButton>
              </div>
              <div>
                <h1 className="text-[22px] font-medium text-gray-500 uppercase">
                  {fromMyAcc ? "My Account" : id ? "Edit User" : "Add User"}
                </h1>
              </div>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {id ? "Save Changes" : "Submit"}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-[15px] pr-[15px] pb-[15px]">
            <Tabs
              className="mt-[30px]"
              header={[
                { label: "General Fields", icon: "" },
                { label: "Additional Fields", icon: "" },
              ]}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    label="First Name"
                    name="firstName"
                    required={true}
                    value={values.firstName}
                    error={errors.firstName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Last Name"
                    name="lastName"
                    required={true}
                    value={values.lastName}
                    error={errors.lastName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Email"
                    name="email"
                    required={true}
                    value={values.email}
                    error={errors.email}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                    disabled={fromMyAcc}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Phone"
                    name="phone"
                    required={true}
                    value={values.phone}
                    error={errors.phone}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="flex items-center pl-[5px] mt-[20px]">
                    <span
                      className="text-gray-500 uppercase font-medium italic"
                      style={{ minWidth: "200px" }}
                    >
                      Roles and Permissions
                    </span>
                    <div className="border border-gray-500 w-[100%]" />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputAuto
                    label="Outlet"
                    name="outletId"
                    required={true}
                    value={values.outletId}
                    error={errors.outletId}
                    onChange={handleInpChg}
                    options={outlets}
                    disabled={fromMyAcc}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    label="Role"
                    name="role"
                    required={true}
                    error={errors.role}
                    value={values.role}
                    onChange={handleInpChg}
                    options={Role}
                    disabled={fromMyAcc}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    label="Status"
                    name="accStatus"
                    required={true}
                    error={errors.accStatus}
                    value={values.accStatus}
                    onChange={handleInpChg}
                    options={Status}
                    disabled={fromMyAcc}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputPass
                    label="Password"
                    name="password"
                    value={values.password}
                    required={true}
                    onChange={handleInpPass}
                    error={errors.password}
                    className="input-primary w-[100%]"
                  />
                  <PassValidation
                    {...{ password: values.password, passValid }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputPass
                    label="Confirm Password"
                    name="confirmPass"
                    required={true}
                    value={values.confirmPass}
                    onChange={(e) => handleInpPass(e, true)}
                    error={errors.confirmPass}
                    className="input-primary w-[100%]"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Daily Target"
                    name="dailyTarget"
                    value={values.dailyTarget}
                    type="number"
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Weekly Target"
                    name="weeklyTarget"
                    value={values.weeklyTarget}
                    type="number"
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="Monthly Target"
                    name="monthlyTarget"
                    value={values.monthlyTarget}
                    type="number"
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label="Gender"
                    name="gender"
                    value={values.gender}
                    onChange={handleInpChg}
                    options={Gender}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDate
                    label="Date of Birth"
                    name="dob"
                    value={values.dob}
                    error={errors.dob}
                    maxDate={new Date()}
                    onChange={(date) =>
                      handleInpDate("dob", new Date(date).toLocaleDateString())
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="flex items-center pl-[5px] mt-[20px]">
                    <span
                      className="text-gray-500 uppercase font-medium italic"
                      style={{ minWidth: "100px" }}
                    >
                      Addresses
                    </span>
                    <div className="border border-gray-500 w-[100%]" />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 1"
                    name="address1"
                    multiline
                    rows={2}
                    value={values.address1}
                    error={errors.address1}
                    inputProps={{ maxLength: 600 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 2"
                    name="address2"
                    multiline
                    rows={2}
                    value={values.address2}
                    inputProps={{ maxLength: 600 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="City"
                    name="city"
                    value={values.city}
                    inputProps={{ maxLength: 300 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="State"
                    name="state"
                    value={values.state}
                    inputProps={{ maxLength: 300 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input
                    label="ZIP"
                    name="zip"
                    value={values.zip}
                    error={errors.zip}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputImg
                    label="Profile Photo"
                    name="photoFile"
                    src={values.photo}
                    value={values.photoFile}
                    rmv={() => {
                      setValues({ ...values, photoFile: "", photo: "" });
                    }}
                    onChange={handleFile}
                    error={errors.photoFile}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={10}></Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditUser;
