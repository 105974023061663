import { Collapse, Grid, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { ButtonGroup, Input } from "../../../../controls";
import { DropLi } from "../../../../hooks";

const Accounting = ({ values, setValues, handleInpChg }) => {
  const { DiscountTypes } = DropLi();

  useEffect(() => {
    var subtotal = values.stockProds
      .map((j) => j.totalPrice)
      .reduce((x, y) => x + y, 0);

    setValues((j) => ({
      ...j,
      subtotal: subtotal,
      totalOrderAmt: subtotal,
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stockProds]);

  // Calculate Discount;
  useEffect(() => {
    var disAmt = 0;
    if (values.discountType === 1) {
      disAmt = values.subtotal * (parseFloat(values.discount || 0) / 100);
    } else if (values.discountType === 2) disAmt = values.discount;
    setValues((j) => ({
      ...j,
      disAmt: disAmt,
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discountType, values.discount]);

  useEffect(() => {
    setValues((j) => ({
      ...j,
      totalOrderAmt:
        parseFloat(values.subtotal || 0) +
        parseFloat(values.shippingFee || 0) +
        parseFloat(values.importDuty || 0) -
        parseFloat(values.disAmt || 0),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.subtotal,
    values.disAmt,
    values.shippingFee,
    values.importDuty,
    values.totalOrderAmt,
  ]);

  return (
    <Collapse
      in={values.stockProds && values.stockProds.length > 0 ? true : false}
      orientation="vertical"
      timeout={500}
    >
      <div>
        <Grid container spacing={1} className="!mt-[25px]">
          <Grid item xs={12} className="border-b-[1px]">
            <div className="mb-[10px] flex justify-between text-gray-500">
              <span className="uppercase md:text-[18px] max-sm:text-[15px]">
                Subtotal of products :
              </span>
              <span className="font-medium md:text-[20px] max-sm:text-[16px]">
                ${" "}
                {values.subtotal.toLocaleString("en-us", {
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
            {/* <Divider /> */}
          </Grid>
          <Grid item xs={12} className="border-b-[1px]">
            <div className="mb-[10px] flex gap-2 flex-wrap justify-between items-end text-[18px] text-slate-500">
              <span className="uppercase md:text-[18px] max-sm:text-[14px]">
                Discount :
              </span>
              <div className="flex">
                <Input
                  fullWidth
                  placeholder={"0.00"}
                  name="discount"
                  value={values.discount}
                  onChange={handleInpChg}
                  className="rmv-arrow"
                  variant="outlined"
                  type="number"
                  size="small"
                  onClick={(e) => e.target.select()}
                />
                <ButtonGroup
                  value={values.discountType || 0}
                  onChange={handleInpChg}
                  name="discountType"
                  options={DiscountTypes}
                />
              </div>
              <span className="font-medium md:text-[20px] max-sm:text-[16px]">
                (-) ${" "}
                {values.disAmt.toLocaleString("en-us", {
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} className="border-b-[1px]">
            <div className="mb-[10px] flex justify-between items-center text-[18px] text-gray-500">
              <span className="uppercase md:text-[18px] max-sm:text-[14px]">
                Shipping :
              </span>
              <span className="font-medium">
                <Input
                  fullWidth
                  placeholder={"0.00"}
                  name="shippingFee"
                  value={values.shippingFee}
                  onChange={handleInpChg}
                  className="rmv-arrow"
                  variant="outlined"
                  type="number"
                  size="small"
                  InputProps={{
                    inputProps: {
                      className: "text-right !font-medium !text-gray-500",
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </span>
            </div>
          </Grid>
          <Grid item xs={12} className="border-b-[1px]">
            <div className="mb-[10px] flex flex-wrap justify-between items-center text-[18px] text-gray-500">
              <span className="uppercase md:text-[18px] max-sm:text-[14px]">
                Import duty :
              </span>
              <span className="font-medium">
                <Input
                  fullWidth
                  placeholder={"0.00"}
                  name="importDuty"
                  value={values.importDuty}
                  onChange={handleInpChg}
                  className="rmv-arrow xs"
                  variant="outlined"
                  type="number"
                  size="small"
                  InputProps={{
                    inputProps: {
                      className: "text-right !font-medium !text-gray-500",
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="flex justify-between text-gray-500">
              <span className="uppercase font-bold tracking-wider text-[18px]">
                Order Total :
              </span>
              <span className="uppercase 500 text-[22px] font-bold">
                ${" "}
                {values.totalOrderAmt.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </Collapse>
  );
};

export default Accounting;
