import React, { useState, useEffect } from "react";
import { Typography, IconButton } from "@mui/material";
import { useRef } from "react";
import { ClearRounded, FileUploadRounded } from "@mui/icons-material";
import { Alert } from "../layout";

export default function InputImg(props) {
  const {
    label = "Attach Img",
    name,
    error = null,
    onChange,
    rmv,
    src,
    maxSize = 3,
    value,
    subtitle,
  } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [sizeErr, setSizeErr] = useState();
  const [photoSrc, setPhotoSrc] = useState(null);
  const fileRef = useRef(null);

  const showPreview = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      if (imageFile.size / 1048576 < maxSize) {
        const reader = new FileReader();
        reader.onload = (x) => {
          setPhotoSrc(x.target.result);
          onChange({ name, value: imageFile });
        };
        reader.readAsDataURL(imageFile);
        setSizeErr();
      } else setSizeErr(`Maximum Allowed ${maxSize}MB`);
    }
  };
  const rmvImg = () => {
    setPhotoSrc(null);
    rmv !== undefined && rmv();
  };

  useEffect(() => {
    src && setPhotoSrc(src);
    return () => {
      setPhotoSrc(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    value && !photoSrc && setPhotoSrc(URL.createObjectURL(value));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="grid">
        <div className="relative">
          <div className="w-[100%] flex flex-wrap justify-center bg-primaryXS rounded-[10px]">
            {photoSrc ? (
              <div className="relative">
                <img
                  className="w-[100%] object-cover cursor-pointer rounded-[12px]"
                  src={photoSrc}
                  alt={photoSrc}
                  style={{
                    height: "inherit",
                    maxWidth: "300px",
                  }}
                  onClick={() => fileRef.current.click()}
                />
                <div className="absolute m-[10px] right-0 top-0">
                  <IconButton color="error" size="small" onClick={rmvImg}>
                    <ClearRounded />
                  </IconButton>
                </div>
              </div>
            ) : (
              <>
                <FileUploadRounded
                  className="object-cover cursor-pointer"
                  color="whitemd"
                  onClick={() => fileRef.current.click()}
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  alt="Attach a PP Pic"
                />
                <div className="absolute bottom-[0px] p-[5px] text-gray-500 grid text-center">
                  <span className="uppercase">{label}</span>
                  <span className="text-[11] font-medium mt-[5px]">
                    {subtitle}
                  </span>
                </div>
              </>
            )}
          </div>

          <label htmlFor={name}>
            <input
              accept="image/png, image/gif, image/jpeg, image/jpg"
              className="d-none"
              name={name}
              id={name}
              onChange={showPreview}
              type="file"
              ref={fileRef}
            />
          </label>
        </div>
        <Typography color="error" className="mt-[10px]">
          {error || sizeErr}
        </Typography>
      </div>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
}
