import React, { useContext } from "react";
import { CartContext } from "../Sell";
import { no_img } from "../../../../assets";
import { IconButton } from "@mui/material";
import { CloseRounded, ReportProblemRounded } from "@mui/icons-material";

const CartProdHeader = ({ item }) => {
  const { setCartData } = useContext(CartContext);

  const rmvCartItem = (e, item) => {
    e.stopPropagation();
    setCartData((j) => ({
      ...j,
      sellItems: j.sellItems.filter((x) => x.key !== item.key),
    }));
  };

  return (
    <>
      <div className="mb-[5px] mt-[5px] w-[100%]">
        <div className="flex gap-15 justify-between items-center">
          <div>
            <div className="flex items-center gap-[10px]">
              {/* Inventory Warning */}
              {item.outOfStock && (
                <div>
                  <ReportProblemRounded fontSize="medium" color="error" />
                </div>
              )}
              <div>
                <img
                  src={
                    item.prod && item.prod.imgs[0]
                      ? item.prod.imgs[0].imgUrl
                      : no_img
                  }
                  className="rounded-[4px] !h-[50px] !w-[50px] object-cover"
                  alt="product_img"
                />
              </div>

              <div className="grid">
                {/* Product Name */}
                <span className="text-[14px] font-medium text-gray-500">
                  {item.prod.name}
                </span>
                {/* Product Description */}
                <span className="text-[12px] font-semibold text-gray-500">
                  Qty: {item.qty}
                  {item.variant &&
                    ` | ${item.variant.attrVal1} ${
                      item.variant.attrVal2 && " / " + item.variant.attrVal2
                    } ${
                      item.variant.attrVal3 && " / " + item.variant.attrVal3
                    }`}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[15px]">
            <div className="grid w-max">
              {/* Discount Amount */}
              <span className="font-medium text-gray-500">
                ${" "}
                {(
                  ((item.price || 0) - (item.disAmt || 0)) *
                  (item.qty || 0)
                ).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </span>
              {/* Without Discount */}
              {item.disAmt > 0 && (
                <span className="font-medium text-[13px] text-gray-500 line-through">
                  ${" "}
                  {((item.price || 0) * (item.qty || 0)).toLocaleString(
                    "en-US",
                    {
                      maximumFractionDigits: 2,
                    }
                  )}
                </span>
              )}
            </div>

            <IconButton
              onClick={(e) => rmvCartItem(e, item)}
              size="small"
              color="error"
              className="p-0"
            >
              <CloseRounded className="fs-18 m-5" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartProdHeader;
