import {
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { no_img } from "../../../../assets";
import { Input } from "../../../../controls";
import { MaxLength } from "../../../../hooks/Method";
import { Alert } from "../../../../layout";

const OrderItems = ({ values, setValues, stores }) => {
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setValues((j) => {
      return {
        ...j,
        subtotal: j.stockProds
          .map((x) => x.totalReceiveCost)
          .reduce((x, y) => x + y, 0),
      };
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stockProds]);

  const handlePrice = (e, prod) => {
    const price = e.target.value;
    setValues((j) => ({
      ...j,
      stockProds: j.stockProds.map((x) => {
        // Default Price Per Product
        var updPrice = x.unitPrice;
        // Total Price
        var _totalReceiveCost = x.totalReceiveCost;

        if (x.prodId === prod.prodId && x.varProdId === prod.varProdId) {
          updPrice = price;

          // total quantity
          var _totalReceiveQty = x.orderInvsFor
            .map((inv) => parseFloat(inv.receiveQty))
            .reduce((x, y) => x + y, 0);

          // Total Price
          _totalReceiveCost = _totalReceiveQty * parseFloat(price);
        }

        return {
          ...x,
          unitPrice: updPrice,
          totalReceiveCost: _totalReceiveCost,
        };
      }),
    }));

    setValues((j) => {
      return {
        ...j,
      };
    });
  };

  const handleAllReceive = (outletId) => {
    setValues((j) => ({
      ...j,
      stockProds: j.stockProds.map((stockProd) => {
        var _updInvs = [
          ...stockProd.orderInvsFor.map((inv) => ({
            ...inv,
            receiveQty: inv.outletId === outletId ? inv.qty : inv.receiveQty,
          })),
        ];

        var _totalReceiveQty = _updInvs
          .map((x) => parseFloat(x.receiveQty || 0))
          .reduce((x, y) => x + y, 0);

        return {
          ...stockProd,
          orderInvsFor: _updInvs,
          totalReceiveQty: _totalReceiveQty,
          totalReceiveCost: _totalReceiveQty * parseFloat(stockProd.unitPrice),
        };
      }),
    }));
  };

  const handleQty = (e, prod, outletId) => {
    const receiveQty = e.target.value;
    setValues((stock) => ({
      ...stock,
      stockProds: stock.stockProds.map((_prod) => {
        var _orderInvsFor = _prod.orderInvsFor;

        if (
          _prod.prodId === prod.prodId &&
          _prod.varProdId === prod.varProdId
        ) {
          // Upadte Quantity
          _orderInvsFor = _prod.orderInvsFor.map((j) =>
            j.outletId === outletId ? { ...j, receiveQty: receiveQty } : j
          );

          // Total received quantity
          var _totalReceiveQty = _orderInvsFor
            .map((j) => parseFloat(j.receiveQty || 0))
            .reduce((x, y) => x + y, 0);

          return {
            ..._prod,
            orderInvsFor: _orderInvsFor,
            totalReceiveQty: _totalReceiveQty,
            totalReceiveCost: _totalReceiveQty * parseFloat(prod.unitPrice),
          };
        } else {
          return {
            ..._prod,
          };
        }
      }),
    }));
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* Component Title */}
        <Grid item xs={12}>
          <div className="flex items-center pl-[5px] mt-[20px]">
            <span
              className="text-gray-color uppercase font-medium"
              style={{ minWidth: "100px" }}
            >
              Products
            </span>
            <div className="bs-secondary w-[100%]" />
          </div>
        </Grid>

        {/* Item Table */}
        <Grid item xs={12}>
          <TableContainer className="bs-secondary rounded-[15px] bg-inherit overflow-auto">
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  {values.stockProds[0] &&
                    values.stockProds[0].orderInvsFor.map((val, i) => {
                      var _store = stores.find((j) => j.id === val.outletId);
                      return (
                        _store && (
                          <TableCell key={i}>
                            <div className="grid">
                              <span>{_store.outletName}</span>
                              <span
                                onClick={() => handleAllReceive(val.outletId)}
                                className="text-[11px] uppercase text-gray-500 underline hover:cursor-pointer"
                              >
                                Receive all order
                              </span>
                            </div>
                          </TableCell>
                        )
                      );
                    })}
                  <TableCell>Cost Price</TableCell>
                  <TableCell>
                    <div className="flex font-bold gap-2">
                      <div className="grid">
                        <span className="w-max">Total Received: </span>
                        <span className="w-max">Received Cost: </span>
                      </div>
                      <div className="grid">
                        <span className="text-sm w-max !font-medium text-gray-500 uppercase">
                          {values.stockProds &&
                            values.stockProds
                              .map((j) => parseFloat(j.totalReceiveQty || 0))
                              .reduce((x, y) => x + y, 0)
                              .toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })}
                        </span>
                        <span className="text-sm w-max !font-medium text-gray-500 uppercase">
                          ${" "}
                          {values.stockProds &&
                            values.stockProds
                              .map((j) => parseFloat(j.totalReceiveCost || 0))
                              .reduce((x, y) => x + y, 0)
                              .toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.stockProds.map((prod, i) => (
                  <TableRow hover key={i}>
                    <TableCell>
                      <div
                        className="flex items-center gap-[10px]"
                        style={{ minWidth: "400px" }}
                      >
                        <img
                          src={prod.img ? prod.img : no_img}
                          alt="product_img"
                          className="img-fluid w-[60px] object-cover rounded-[10px] shadow-xs"
                        />

                        <div className="mr-[20px] grid ml-[10px]">
                          <span className="text-[14px] font-medium">
                            {prod.name}
                          </span>
                          <span className="text-[12px] text-gray-500">
                            {prod.subtitle}
                          </span>
                        </div>
                      </div>
                    </TableCell>

                    {prod.orderInvsFor.map((_val, i) => (
                      <TableCell key={i}>
                        <div className="grid gap-1">
                          <Input
                            placeholder="Received"
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: "120px" }}
                            onChange={(e) => handleQty(e, prod, _val.outletId)}
                            value={_val.receiveQty || ""}
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 8))
                            }
                          />
                          {parseFloat(_val.receiveQty || 0) !==
                            parseFloat(_val.qty || 0) && (
                            <span className="text-[12px] font-medium tracking-wider ml-1 text-slate-500 ">
                              Ordered: {_val.qty}
                            </span>
                          )}
                        </div>
                      </TableCell>
                    ))}
                    <TableCell>
                      <Input
                        variant="outlined"
                        placeholder="0.00"
                        size="small"
                        sx={{ minWidth: "120px" }}
                        value={prod.unitPrice}
                        onChange={(e) => handlePrice(e, prod)}
                        onInput={(e) =>
                          (e.target.value = MaxLength(e.target.value, 8))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    {/* Accounts || Total Calculation */}
                    <TableCell>
                      <div className="text-gray-500 flex gap-5">
                        <div className="grid">
                          <span className="w-max">Receive </span>
                          <span className="w-max">Ordered </span>
                          <span className="w-max">Receive Cost </span>
                        </div>
                        <div className="grid">
                          <span className="font-bold w-max">
                            :{" "}
                            {(prod.totalReceiveQty || 0).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                          <span className="font-bold w-max">
                            :{" "}
                            {(prod.orderedQty || 0).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                          <span className="font-bold w-max">
                            :{" $ "}
                            {(prod.totalReceiveCost || 0).toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default OrderItems;
