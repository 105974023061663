import React, { useContext, useEffect, useState } from "react";
import { Model, UseForm } from "../../../../hooks";
import { Button, Input } from "../../../../controls";
import { CartContext } from "../Sell";
import { InputAdornment } from "@mui/material";
import { Dialog } from "../../../../layout";
import ViewProd from "../../../Catalog/Product/ViewProduct/ViewProd";

const CartProdBody = ({ data }) => {
  const { setCartData } = useContext(CartContext);
  const { CartProdMdl } = Model();
  const { values, setValues, handleInpChg } = UseForm(CartProdMdl);
  const [showViewProd, setShowViewProd] = useState(false);

  useEffect(() => {
    setValues({ ...data });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.key]);

  useEffect(() => {
    setValues((j) => ({ ...j, qty: data.qty }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.qty]);

  const handleUpdateCart = (e) => {
    var name = e.target.name;
    var value = name === "note" ? e.target.value : e.target.value || 0;
    setCartData((j) => ({
      ...j,
      sellItems: [
        ...j.sellItems.map((x) =>
          x.key === data.key ? { ...x, [name]: value } : x
        ),
      ],
    }));
  };

  const CalculatePercent = (e) => {
    var mainPrice = parseFloat(data.price || 0);
    var updPrice = parseFloat(e.target.value || 0);
    var disPercent = (((mainPrice - updPrice) / mainPrice) * 100).toFixed(2);

    setValues((j) => ({
      ...j,
      discount: disPercent,
    }));

    // Update Cartdata
    setCartData((j) => ({
      ...j,
      sellItems: [
        ...j.sellItems.map((x) =>
          x.key === data.key
            ? { ...x, discount: disPercent, disAmt: mainPrice - updPrice }
            : x
        ),
      ],
    }));
  };

  const CalculateDisAmt = (e) => {
    var mainPrice = parseFloat(data.price);
    var discount = parseFloat(e.target.value || 0);
    var disAmt = (mainPrice * (discount / 100)).toFixed(2);

    setValues((j) => ({
      ...j,
      disAmt: disAmt,
      price: (mainPrice - disAmt).toFixed(2),
    }));

    // Update Cartdata
    setCartData((j) => ({
      ...j,
      sellItems: [
        ...j.sellItems.map((x) =>
          x.key === data.key ? { ...x, disAmt: disAmt, discount: discount } : x
        ),
      ],
    }));
  };

  const handleSkipInvError = (key) => {
    setCartData((j) => ({
      ...j,
      sellItems: j.sellItems.map((x) =>
        x.key === key ? { ...x, outOfStock: false } : x
      ),
    }));
  };

  return (
    <>
      <div className="grid gap-[15px] ml-[28px]">
        <div className="flex gap-[10px]">
          <Input
            placeholder="Quantity"
            name="qty"
            value={values.qty}
            type="number"
            variant="outlined"
            size="small"
            onBlur={handleUpdateCart}
            onChange={handleInpChg}
            error={
              data.outOfStock ? (
                <span>
                  {data.invMsg}{" "}
                  <span
                    onClick={() => handleSkipInvError(data.key)}
                    className="underline font-medium hover:cursor-pointer"
                  >
                    {" "}
                    Continue anyway?
                  </span>
                </span>
              ) : (
                ""
              )
            }
          />

          <Input
            placeholder="Price"
            name="price"
            value={values.price}
            type="number"
            variant="outlined"
            size="small"
            onBlur={CalculatePercent}
            onChange={handleInpChg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <Input
            placeholder="Discount"
            name="discount"
            value={values.discount}
            type="number"
            variant="outlined"
            size="small"
            onBlur={CalculateDisAmt}
            onChange={handleInpChg}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
        <Input
          note="Note"
          name="note"
          value={values.note}
          variant="outlined"
          size="small"
          multiline
          rows={2}
          onBlur={handleUpdateCart}
          onChange={handleInpChg}
        />
        <Button
          size="small"
          variant="text"
          onClick={() => setShowViewProd(!showViewProd)}
        >
          Show Inventory & Details
        </Button>
      </div>

      {/* View Product */}
      {showViewProd && (
        <Dialog show={showViewProd} onClose={false} maxWidth="lg">
          <ViewProd {...{ setShowForm: setShowViewProd, id: data.prodId }} />
        </Dialog>
      )}
    </>
  );
};

export default CartProdBody;
