import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputMultImg,
  InputSwitch,
  Select,
} from "../../../../controls";
import {
  AddRounded,
  ClearRounded,
  DeleteForeverRounded,
  EditRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import InputTag from "../../../../controls/InputTag";
import { Alert, Dialog } from "../../../../layout";
import { no_img } from "../../../../assets";
import Model from "../../../../hooks/Model";
import { API, action, controller } from "../../../../api/api";
import { DropLi } from "../../../../hooks";
import { MaxLength, RandomCode } from "../../../../hooks/Method";
import EditAttrValueName from "./EditAttrValueName";
import AddEditAttr from "../../ProdAttr/AddEditAttr";
import { AppContext } from "../../../../App";

const ProdVariants = (props) => {
  const { setValues, values, selOutlet, outlets } = props;
  const { VariantProdMdl } = Model();
  const [showAttrValForm, setShowAttrValForm] = useState(false);
  const [showAttrForm, setShowAttrForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [attrLabels, setAttrLabels] = useState([]);

  //-------- Attributes
  const handleAddAttr = () => {
    var lastVal = values.prodVarAttrs.slice(-1).pop();
    lastVal &&
      setValues((j) => ({
        ...j,
        prodVarAttrs: [
          ...j.prodVarAttrs,
          { id: lastVal.id + 1, attrId: "", name: "", value: "" },
        ],
      }));
  };

  const handleDltAttr = (val) => {
    var attrVal = attrLabels.find((j) => j.id === val.attrId);
    attrVal &&
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "Are you sure",
        subTitle: `You want to delete the '${attrVal.label}' variant?`,
        onConfirm: () => {
          // Delete Attribute
          setValues((j) => ({
            ...j,
            prodVarAttrs: [
              ...values.prodVarAttrs.filter((x) => x.id !== val.id),
            ],
          }));

          // Show success message.
          setAlert({
            isOpen: true,
            type: "success",
            title: "Deleted Successfully",
            subTitle: "You have successfully deleted.",
          });
        },
      });
  };

  //-------- Tags
  const handleChgAttr = (e, id) => {
    var attrId = e.target.value;
    var attrDtl = attrLabels.find((j) => j.id === attrId);
    setValues((x) => ({
      ...x,
      prodVarAttrs: x.prodVarAttrs.map((j) =>
        j.id === id
          ? { ...j, attrId: e.target.value, name: attrDtl && attrDtl.label }
          : j
      ),
    }));
  };

  const handleTag = (e, attr) => {
    setValues((j) => ({
      ...j,
      prodVarAttrs: [
        ...j.prodVarAttrs.map((x) =>
          x.id === attr.id ? { ...x, value: [...e.target.value] } : x
        ),
      ],
    }));
  };

  const handleDltProd = (prod) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: `You want to delete the '${prod.attrVal1} ${
        prod.attrVal2 && " > " + prod.attrVal2
      } ${prod.attrVal3 && " > " + prod.attrVal3}' product?`,
      onConfirm: () => {
        setValues((j) => ({
          ...j,
          prodVariants: j.prodVariants.filter((x) => x !== prod),
        }));
        setAlert({
          isOpen: true,
          type: "success",
          title: "Deleted Successfully",
          subTitle: "You have successfully deleted.",
        });
      },
    });
  };

  useEffect(() => {
    setValues((j) => ({
      ...j,
      prodVariants: [
        ...j.prodVariants.map((x) => ({
          ...x,
          splrCode: x.splrCode || values.splrCode,
          splrPrice: x.splrPrice || values.splrPrice,
          rtlPrice: x.rtlPrice || values.rtlPrice,
        })),
      ],
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.rtlPrice, values.splrCode, values.splrPrice]);

  useEffect(() => {
    setValues((j) => ({
      ...j,
      prodVariants: j.prodVariants.map((x) => ({
        ...x,
        taxId: x.taxId || values.taxId,
        taxPrice: x.taxPrice || values.taxPrice,
      })),
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.taxId]);

  useEffect(() => {
    values.prodVarAttrs &&
      values.prodVarAttrs.forEach((att, index) => {
        switch (index) {
          case 0:
            setValues((x) => ({
              ...x,
              prodVariants: [
                ...att.value.map((attrVal, ind) => {
                  var prevProd = x.prodVariants[ind];
                  var prod = prevProd ? prevProd : VariantProdMdl();

                  return {
                    ...prod,
                    key: ind + 1,
                    attrId1: att.attrId,
                    attrVal1: attrVal,
                    attrId2: "",
                    attrVal2: "",
                    attrId3: "",
                    attrVal3: "",
                    taxId: prod.taxId || values.taxId,
                    taxPrice: prod.taxPrice || values.taxPrice,

                    skuCode: prod.skuCode ? prod.skuCode : RandomCode(),
                    splrCode: prod.splrCode || values.splrCode,
                    splrPrice: prod.splrPrice || values.splrPrice,
                    rtlPrice: prod.rtlPrice || values.rtlPrice,
                    inventories:
                      prod.inventories.length > 0
                        ? prod.inventories
                        : [
                            ...outlets.map((y) => ({
                              outletName: y.outletName,
                              outletId: y.id,
                              inv: 0,
                              reOrder: "",
                              reOrderQty: "",
                            })),
                          ],
                  };
                }),
              ],
            }));

            break;
          case 1:
            if (att.value && att.value.length > 0) {
              let prods = [];
              var attr1 = values.prodVarAttrs[0];
              var attr2 = att;
              attr2.value.forEach((attrVal2) => {
                attr1.value.forEach((attrVal1) => {
                  var prevprod = values.prodVariants[prods.length];
                  var prod = prevprod ? prevprod : VariantProdMdl();
                  prods.push({
                    ...prod,
                    key: prods.length + 1,
                    attrId1: attr1.attrId,
                    attrId2: attr2.attrId,
                    attrVal1: attrVal1,
                    attrVal2: attrVal2,
                    attrId3: "",
                    attrVal3: "",
                    taxId: prod.taxId || values.taxId,
                    taxPrice: prod.taxPrice || values.taxPrice,

                    skuCode: prod.skuCode ? prod.skuCode : RandomCode(),
                    splrCode: prod.splrCode || values.splrCode,
                    splrPrice: prod.splrPrice || values.splrPrice,
                    rtlPrice: prod.rtlPrice || values.rtlPrice,
                    inventories:
                      prod.inventories.length > 0
                        ? prod.inventories
                        : [
                            ...outlets.map((y) => ({
                              outletName: y.outletName,
                              outletId: y.id,
                              inv: 0,
                              reOrder: "",
                              reOrderQty: "",
                            })),
                          ],
                  });
                });
              });
              setValues((j) => ({ ...j, prodVariants: [...prods] }));
            }

            break;
          case 2:
            if (att.value && att.value.length > 0) {
              let prods = [];
              var _attr1 = values.prodVarAttrs[1];
              var _attr2 = values.prodVarAttrs[0];
              var _attr3 = att;
              _attr3.value.forEach((attrVal3) => {
                _attr1.value.forEach((attrVal2) => {
                  _attr2.value.forEach((attrVal1) => {
                    var prevprod = values.prodVariants[prods.length];
                    var prod = prevprod ? prevprod : VariantProdMdl();
                    prods.push({
                      ...prod,
                      key: prods.length + 1,
                      attrId1: _attr2.attrId,
                      attrId2: _attr1.attrId,
                      attrId3: _attr3.attrId,
                      attrVal1: attrVal1,
                      attrVal2: attrVal2,
                      attrVal3: attrVal3,
                      taxId: prod.taxId || values.taxId,
                      taxPrice: prod.taxPrice || values.taxPrice,

                      skuCode: prod.skuCode ? prod.skuCode : RandomCode(),
                      splrCode: prod.splrCode || values.splrCode,
                      splrPrice: prod.splrPrice || values.splrPrice,
                      rtlPrice: prod.rtlPrice || values.rtlPrice,
                      inventories:
                        prod.inventories.length > 0
                          ? prod.inventories
                          : [
                              ...outlets.map((y) => ({
                                outletName: y.outletName,
                                outletId: y.id,
                                inv: 0,
                                reOrder: "",
                                reOrderQty: "",
                              })),
                            ],
                    });
                  });
                });
              });
              setValues((j) => ({ ...j, prodVariants: [...prods] }));
            }
            break;
          default:
            break;
        }
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.prodVarAttrs]);

  useEffect(() => {
    // Get Variant Prod Attribute
    API(controller.ProdAttr + action.GetAllByOrg).get((res) => {
      res.status === 200 &&
        setAttrLabels([...res.result.map((j) => ({ ...j, label: j.name }))]);
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOutlet]);

  return (
    <>
      <Grid item xs={12}>
        <div className="flex items-center pl-[5px] mt-[20px]">
          <span
            className="text-gray-500 uppercase font-medium"
            style={{ minWidth: "90px" }}
          >
            Variants
          </span>
          <div className="mr-[10px]">
            <Tooltip title="Add Variant Attribute">
              <IconButton
                onClick={() => setShowAttrForm((x) => !x)}
                size="small"
              >
                <AddRounded />
              </IconButton>
            </Tooltip>
          </div>

          <div className="bs-secondary w-[100%]" />
        </div>
      </Grid>
      {values.prodVarAttrs &&
        values.prodVarAttrs.map((attr, i) => (
          <Fragment key={i}>
            <Grid item xs={12} md={4}>
              <InputAuto
                fullWidth
                label={i === 0 ? "Attribute (e.g. Color)" : ""}
                name={`attr${attr.id}`}
                value={attr.attrId}
                onChange={(e) => handleChgAttr(e, attr.id)}
                options={attrLabels}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <div className="flex gap-[10px] items-center">
                <InputTag
                  label={i === 0 ? "Value (e.g. Green)" : ""}
                  name={`attrVal${attr.id}`}
                  onChange={(e) => handleTag(e, attr)}
                  dltConfirm={true}
                  setAlert={setAlert}
                  value={attr.value}
                />
                {i !== 0 && (
                  <div>
                    <Tooltip title="Delete this variant">
                      <IconButton
                        onClick={() => handleDltAttr(attr)}
                        color="error"
                      >
                        <DeleteForeverRounded className="text-[28px]" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          </Fragment>
        ))}

      {values.prodVarAttrs.length <= 2 ? (
        <Grid item xs={12} md={4}>
          <Button
            onClick={handleAddAttr}
            size="small"
            startIcon={<AddRounded />}
            variant="text"
            sx={{ minWidth: "220px" }}
          >
            Add another attribute
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12} md={4}></Grid>
      )}

      <Grid item xs={12} md={8}>
        <Button
          onClick={() => setShowAttrValForm(!showAttrValForm)}
          size="small"
          startIcon={<EditRounded />}
          variant="text"
          sx={{ minWidth: "175px !important" }}
        >
          Edit Value Name
        </Button>
      </Grid>

      <Grid item xs={12} className="mt-[20px]">
        <div>
          <span className="text-[20px] font-medium text-gray-500 border-left">
            This product has {values.prodVariants && values.prodVariants.length}{" "}
            variants
          </span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <TableContainer className="bs-secondary rounded-[15px] bg-inherit">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Variant Name</TableCell>
                <TableCell>SKU Code</TableCell>
                <TableCell>Supplier Code</TableCell>
                <TableCell>Supplier Price</TableCell>
                <TableCell>Retail Price</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.prodVariants &&
                values.prodVariants.map((prod, ind) => (
                  <ProdRow
                    key={ind}
                    {...{ prod, handleDltProd, setValues, values, selOutlet }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {/* Edit Attribute Value Name */}
      {showAttrValForm && (
        <Dialog show={showAttrValForm} onClose={false} maxWidth="sm">
          <EditAttrValueName
            {...{
              setValues,
              values,
              setShowForm: setShowAttrValForm,
            }}
          />
        </Dialog>
      )}

      {/* Attribute Form */}
      {showAttrForm && (
        <Dialog show={showAttrForm} onClose={false} maxWidth="sm">
          <AddEditAttr
            {...{ setAttrLabels, setShowForm: setShowAttrForm, setAlert }}
          />
        </Dialog>
      )}
    </>
  );
};

const ProdRow = (props) => {
  const { prod, handleDltProd, setValues, values } = props;
  const { authUser } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const { VariantProdMdl } = Model();
  const [prodVal, setProdVal] = useState(VariantProdMdl());
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    setProdVal({ ...prod });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod]);

  const handleChgInp = (e) => {
    const { name, value } = e.target;
    setProdVal((j) => ({
      ...j,
      [name]: value,
    }));
  };

  const handleSetInpVal = (e) => {
    const { name, value } = e.target;
    // Calculate tax price
    if (name === "taxId") {
      var taxAmt = 0;
      var taxDtl = taxes.find((j) => j.id === value);
      if (taxDtl) taxAmt = values.splrPrice * (taxDtl.rate / 100).toFixed(2);
      setValues((j) => ({
        ...j,
        prodVariants: j.prodVariants.map((x) =>
          x.key === prod.key
            ? {
                ...x,
                [name]: value,
                taxPrice: taxAmt,
                rtlPrice:
                  parseFloat(x.splrPrice) * (1 + values.markup / 100) + taxAmt,
              }
            : x
        ),
      }));
    } else
      setValues((j) => ({
        ...j,
        prodVariants: j.prodVariants.map((x) =>
          x.key === prod.key ? { ...x, [name]: value } : x
        ),
      }));
  };

  const handleInpCheck = (e) => {
    const { name, checked } = e.target;

    setValues((j) => ({
      ...j,
      prodVariants: j.prodVariants.map((x) =>
        x.key === prod.key ? { ...x, [name]: checked } : x
      ),
    }));
  };

  const handleImg = (e) => {
    const { value } = e;
    setValues((j) => ({
      ...j,
      prodVariants: j.prodVariants.map((x) =>
        x.key === prod.key ? { ...x, imgFiles: value } : x
      ),
    }));
  };

  useEffect(() => {
    // Get Taxes
    if (authUser.id)
      API(controller.Tax + action.GetAllByOrg).get((res) => {
        res &&
          res.status === 200 &&
          setTaxes([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });

    return () => {};
  }, [authUser]);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <div className="flex items-center gap-[10px]">
            <div>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <KeyboardArrowUpRounded />
                ) : (
                  <KeyboardArrowDownRounded />
                )}
              </IconButton>
            </div>

            <img
              src={prodVal.imgs.length > 0 ? prodVal.imgs[0].imgUrl : no_img}
              alt="product_img"
              className="img-fluid w-[70px] rounded-[10px] shadow-xs"
            />
            <span
              className="text-[16px] mr-[20px]"
              style={{ minWidth: "250px" }}
            >
              {`${prodVal.attrVal1}` +
                (prodVal.attrVal2 && ` / ${prodVal.attrVal2}`) +
                (prodVal.attrVal3 && ` / ${prodVal.attrVal3}`)}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <Input
            name="skuCode"
            value={prodVal.skuCode}
            onChange={handleChgInp}
            onBlur={handleSetInpVal}
            className="shadow-xs"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 200 }}
            sx={{ minWidth: "150px" }}
          />
        </TableCell>
        <TableCell>
          <Input
            name="splrCode"
            value={prodVal.splrCode}
            onChange={handleChgInp}
            onBlur={handleSetInpVal}
            className="shadow-xs"
            variant="outlined"
            size="small"
            placeholder="Enter Code"
            inputProps={{ maxLength: 200 }}
            sx={{ minWidth: "140px" }}
          />
        </TableCell>
        <TableCell>
          <Input
            name="splrPrice"
            value={prodVal.splrPrice}
            onChange={handleChgInp}
            onBlur={handleSetInpVal}
            className="shadow-xs"
            variant="outlined"
            size="small"
            placeholder="$0.00"
            inputProps={{ maxLength: 200 }}
            sx={{ minWidth: "140px" }}
          />
        </TableCell>
        <TableCell>
          <Input
            name="rtlPrice"
            value={prodVal.rtlPrice}
            onChange={handleChgInp}
            onBlur={handleSetInpVal}
            className="shadow-xs"
            variant="outlined"
            size="small"
            placeholder="$0.00"
            inputProps={{ maxLength: 200 }}
            sx={{ minWidth: "140px" }}
          />
        </TableCell>
        <TableCell>
          <InputSwitch
            checked={prod.enabled}
            name="enabled"
            onChange={handleInpCheck}
            className="shadow-xs"
          />
        </TableCell>

        {/* Delete Product Action */}
        <TableCell>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDltProd(prod)} color="error">
              <ClearRounded className="text-[26px]" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      {/* Details */}
      <TableRow>
        {open && (
          <TableCell colSpan={12}>
            <div className="mb-[10px] ml-[10px]">
              <span className="border-left text-[18px] uppercase text-gray-500">
                Product Details
              </span>
            </div>

            <ProdDtls
              {...{
                handleChgInp,
                handleSetInpVal,
                prod: prodVal,
                setValues,
                values,
                handleImg,
                taxes,
              }}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

const ProdDtls = (props) => {
  const {
    setValues,
    values,
    prod,
    handleChgInp,
    handleSetInpVal,
    handleImg,
    taxes,
  } = props;
  const { SkuTypes } = DropLi();
  const [imgSrc, setImgSrc] = useState([]);

  const handleInpInvChg = (e, invt) => {
    const { name, value } = e.target;
    setValues((j) => ({
      ...j,
      prodVariants: [
        ...j.prodVariants.map((x) =>
          x.key === prod.key
            ? {
                ...x,
                inventories: [
                  ...x.inventories.map((y) =>
                    y.outletId === invt.outletId ? { ...y, [name]: value } : y
                  ),
                ],
              }
            : x
        ),
      ],
    }));
  };

  useEffect(() => {
    if (values.id) {
      setImgSrc([...prod.imgs.map((j) => ({ src: j.imgUrl, ...j }))]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  return (
    <div className="mb-[30px] border !border-gray-200 p-[20px] rounded-[20px]">
      {/* -------- Inventory -------- */}
      <div className="grid gap-[20px] mt-[10px]">
        <Grid container spacing={3}>
          <Grid item xs={4} md={3}>
            <Select
              label="SKU Code Type"
              name="skuType"
              value={prod.skuType}
              onChange={handleChgInp}
              onBlur={handleSetInpVal}
              options={SkuTypes}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <Input
              label="SKU Code"
              name="skuCode"
              inputProps={{ maxLength: 100 }}
              value={prod.skuCode}
              onChange={handleChgInp}
              onBlur={handleSetInpVal}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label="Tax"
              name="taxId"
              value={prod.taxId}
              onChange={handleChgInp}
              onBlur={handleSetInpVal}
              options={taxes}
            />
          </Grid>
          <Grid item xs={12}></Grid>

          {values.trackInv && (
            <Grid item xs={12}>
              <div className="mt-[10px]">
                <span className="text-[16px] font-medium border-left uppercase">
                  INVENTORY LEVELS
                </span>
              </div>
              <TableContainer className="">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Outlet</TableCell>
                      <TableCell>Current inventory </TableCell>
                      <TableCell>Re-order point </TableCell>
                      <TableCell>Re-order quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prod.inventories.map((invt, ind) => (
                      <TableRow key={ind}>
                        <TableCell>
                          <span className="text-[16px] font-medium text-gray-500">
                            {invt.outletName ||
                              (invt.outlet && invt.outlet.outletName)}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Input
                            onBlur={(e) => handleInpInvChg(e, invt)}
                            defaultValue={invt.inInv}
                            name="inInv"
                            disabled={values.id ? true : false}
                            className="shadow-xs"
                            variant="outlined"
                            size="small"
                            type="number"
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 10))
                            }
                            sx={{ width: "140px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            onBlur={(e) => handleInpInvChg(e, invt)}
                            defaultValue={invt.reOrder}
                            name="reOrder"
                            className="shadow-xs"
                            variant="outlined"
                            size="small"
                            type="number"
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 10))
                            }
                            sx={{ width: "140px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            onBlur={(e) => handleInpInvChg(e, invt)}
                            defaultValue={invt.reOrderQty}
                            name="reOrderQty"
                            className="shadow-xs"
                            variant="outlined"
                            size="small"
                            type="number"
                            onInput={(e) =>
                              (e.target.value = MaxLength(e.target.value, 10))
                            }
                            sx={{ width: "140px" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            {/* -------- Img -------- */}
            <div className="flex mt-[30px]">
              <InputMultImg
                label="Product Images"
                name={`imgFiles${prod.key}`}
                maxUpl={8}
                maxImgWidth="200px"
                src={imgSrc}
                values={prod.imgFiles}
                onChange={handleImg}
                rmv={(file) => {
                  setValues((x) => ({
                    ...x,
                    prodVariants: [
                      ...x.prodVariants.map((j) =>
                        j.key === prod.key
                          ? {
                              ...j,
                              imgFiles: j.imgFiles.filter(
                                (y) => y.lastModified !== file.lastModified
                              ),
                              imgs: j.imgs.filter((y) => y.id !== file.id),
                            }
                          : j
                      ),
                    ],
                  }));
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProdVariants;
