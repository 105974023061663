import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Button } from "../../../../controls";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Sell";

const Accounts = () => {
  const { cartData, setCartData, sellReg } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCartData((j) => {
      // Calculate the total cart items amount.
      var _totalCartAmt = j.sellItems
        .map(
          (item) =>
            parseFloat(item.qty) *
            (parseFloat(item.price) - parseFloat(item.disAmt || 0))
        )
        .reduce((x, y) => x + y, 0);

      // Calculate total quantity.
      var _totalQty = j.sellItems
        .map((item) => parseFloat(item.qty))
        .reduce((x, y) => x + y, 0);

      return {
        ...j,
        subtotal: _totalCartAmt,
        totalQty: _totalQty,
      };
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.sellItems]);

  // Calculate the discount amount.
  useEffect(() => {
    var _disAmt = 0;
    if (cartData.discountType === 1)
      _disAmt = cartData.subtotal * (parseFloat(cartData.discount || 0) / 100);
    else _disAmt = cartData.discount;
    setCartData((j) => ({
      ...j,
      disAmt: _disAmt,
    }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.discount, cartData.discountType]);

  // Get Tax Rate
  useEffect(() => {
    if (sellReg && sellReg.outlet && sellReg.outlet.tax) {
      var taxDtls = sellReg.outlet.tax;
      setCartData((j) => ({ ...j, taxRate: taxDtls.rate, taxId: taxDtls.id }));
    } else setCartData((j) => ({ ...j, taxRate: 0, taxId: "" }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellReg]);

  // Calculate Tax
  useEffect(() => {
    var taxAmt = cartData.subtotal * (parseFloat(cartData.taxRate) / 100);
    setCartData((j) => ({ ...j, taxAmt: taxAmt }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.subtotal, cartData.taxRate]);

  // Calculate Amounts
  useEffect(() => {
    setCartData((j) => ({ ...j, totalPay: j.subtotal + j.taxAmt - j.disAmt }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.subtotal, cartData.disAmt, cartData.taxAmt]);

  return (
    <>
      <Box className="text-gray-500 mt-[10px] grid gap-2">
        <Box className="flex justify-between pl-[20px] pr-[20px] mt-[8px]">
          <span className="text-[16px]">Tax ({cartData.taxRate || 0}%):</span>
          <span className="text-[18px] font-medium">
            ${" "}
            {(cartData.taxAmt || 0).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </span>
        </Box>
        <div className="p-[10px]">
          <Button
            onClick={() => navigate("checkout")}
            className="flex !justify-between"
            sx={{ p: "12px" }}
            size="large"
            fullWidth
            disabled={cartData.sellItems.some((x) => x.outOfStock)}
          >
            <div>
              <span className="text-[20px]">Pay</span>
              <span className="text-[12px] ml-[10px] font-medium">
                {cartData.totalQty} item
              </span>
            </div>

            <span className="text-[20px]">
              ${" "}
              {cartData.totalPay.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </Button>
        </div>
      </Box>
    </>
  );
};

export default Accounts;
