import React from "react";
import { Grid } from "@mui/material";
import { LabelComp } from "../../../../controls";
import moment from "moment";

const ProdDtls = ({ product }) => {
  return (
    <div className="pt-[5px] bg-white rounded-[12px] p-[10px]">
      {product && product.id ? (
        <Grid
          container
          spacing={3}
          className="p-[15px] text-gray-800 mb-[10px] min-w-[800px] sm:min-w-auto"
        >
          <Grid item xs={12} md={6}>
            <div className="grid gap-4">
              <LabelComp label="Product Id" value={product.id} />
              <LabelComp label="Produt Name" value={product.name} />
              <LabelComp label="SKU Code" value={product.skuCode} />
              {product.brand && (
                <LabelComp label="Brand" value={product.brand.name} />
              )}
              {product.cat && (
                <LabelComp label="Category" value={product.cat.name} />
              )}
              {product.subCat && (
                <LabelComp label="Subcategory" value={product.subCat.name} />
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="grid gap-4">
              <LabelComp
                label="Product Type"
                value={
                  product.prodType === 1
                    ? "Standard Product"
                    : product.prodType === 2
                    ? "Variant Product"
                    : "Composit Product"
                }
              />

              <LabelComp
                label="Create Date"
                value={moment(product.createDate).format(
                  "ddd, DD MMM YYYY hh:mm A"
                )}
              />
              {product.user && (
                <LabelComp
                  label="Create User"
                  value={product.user.firstName + " " + product.user.lastName}
                />
              )}
              {product.updateDate && (
                <LabelComp
                  label="Update Date"
                  value={moment(product.updateDate).format(
                    "ddd, DD MMM YYYY hh:mm A"
                  )}
                />
              )}
              <LabelComp label="Description" value={product.desc} />
            </div>
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default ProdDtls;
