import React, { createContext, useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Actions from "./CartActions/Actions";
import Cart from "./Cart/Cart";
import { Route, Routes, useNavigate } from "react-router-dom";
import RefundForm from "./RefundForm";
import Home from "./Home";
import { Model } from "../../../hooks";
import { AppContext } from "../../../App";
import { action, API, controller } from "../../../api/api";
import { ToFormData } from "../../../hooks/Method";
import Checkout from "./Checkout/Checkout";

export const CartContext = createContext({});

const Sell = () => {
  const { SellMdl, SellRegisterMdl } = Model();
  const { authUser } = useContext(AppContext);
  const [cartData, setCartData] = useState(SellMdl);
  const [sellReg, setSellReg] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser && authUser.id) {
      API(controller.SellReg + action.GetCashierOpenReg).get((res) => {
        if (res && res.status === 200) {
          setSellReg({ ...res.result });
        } else if (res && res.status === 404) {
          setSellReg(SellRegisterMdl);
        }
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    var newCartItem = cartData.sellItems[0];
    if (newCartItem) {
      API(controller.Inventory + action.CheckInvExist).post(
        ToFormData({
          id:
            newCartItem.prodType === 2
              ? newCartItem.variantId
              : newCartItem.prodId,
          type: newCartItem.prodType,
        }),
        (res) => {
          if (res && res.status === 404) {
            setCartData((x) => ({
              ...x,
              sellItems: x.sellItems.map((y) =>
                y.key === newCartItem.key
                  ? { ...y, invMsg: res.msg, outOfStock: true }
                  : y
              ),
            }));
          }
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.sellItems.length]);

  const handleClearCart = () => {
    navigate("/dashboard/sell");
  };

  return (
    <div>
      <CartContext.Provider
        value={{ cartData, setCartData, sellReg, setSellReg }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box className="h-[calc(100vh-185px)] overflow-auto rounded-[8px]">
              <Routes>
                <Route
                  path="/refund"
                  element={<RefundForm {...{ handleClearCart }} />}
                />

                <Route
                  path="checkout"
                  element={
                    <Checkout
                      {...{
                        handleClearCart,
                      }}
                    />
                  }
                />

                <Route index element={<Home />} />
              </Routes>
            </Box>

            <div>
              <Actions />
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <Cart />
          </Grid>
        </Grid>
      </CartContext.Provider>
    </div>
  );
};

export default Sell;
