import { Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, InputSwitch } from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { Form } from "../../../../layout";
import { AppContext } from "../../../../App";
import { API, action, controller } from "../../../../api/api";
import { RmvEmptyProp, ToFormData } from "../../../../hooks/Method";

const AddEditRegister = (props) => {
  const { setAlert, setShowForm, updData, setRegs, outletId } = props;
  const { RegisterMdl } = Model();
  const { selOutlet, authUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    resetFormControls,
  } = UseForm(RegisterMdl);

  useEffect(() => {
    updData && setValues((x) => ({ ...x, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : "Name is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && (res.status === 201 || res.status === 200)) {
          if (setRegs && res.result.id) {
            updData
              ? setRegs((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setRegs((x) => [res.result, ...x]);
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      };
      if (values.id === 0) {
        values.orgId = selOutlet.orgId;
        values.userId = authUser.id;
        values.outletId = outletId;
        API(controller.Register + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else
        API(controller.Register + action.Put).put(
          ToFormData(
            RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
          ),
          onComplete
        );
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <Grid container spacing={2} className="p-[15px]">
            <Grid
              item
              xs={12}
              className="text-center p-[20px] !pt-[30px] w-[100%]"
            >
              <span className="text-[22px] font-medium uppercase text-gray-500">
                {updData ? "Update" : "Add"} Register
              </span>
              <div className="flex justify-center">
                <div className="border-bottom w-[60px]" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Register Name"
                name="name"
                value={values.name}
                error={errors.name}
                required={true}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12}>
              <InputSwitch
                label="Print Receipt"
                name="printReceipt"
                checked={values.printReceipt}
                onChange={handleInpCheck}
              />
            </Grid>
            <Grid item xs={12}>
              <InputSwitch
                label="Email Receipt"
                name="emailReceipt"
                checked={values.emailReceipt}
                onChange={handleInpCheck}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="flex gap-[10px]">
                <Button
                  fullWidth
                  size="large"
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button fullWidth size="large" loading={loading} type="submit">
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

export default AddEditRegister;
