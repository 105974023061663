import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API, action, controller } from "../../../../api/api";
import { Grid } from "@mui/material";
import { Alert, Dialog } from "../../../../layout";
import CancelOrderForm from "../CancelOrder/CancelOrderForm";
import Details from "./Details";
import Products from "./Products";

const ViewOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    id &&
      API(controller.Stock + action.GetDetailsById).getById(id, (res) => {
        if (res && res.status === 200) setOrder(res.result);
        else setOrder({});
      });

    return () => {};
  }, [id]);

  const handleMarkAsSent = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: `You’re about to change the order "${order.orderNum}" to sent`,
      subTitle:
        "You’ll be able to see which orders have already been sent to suppliers. If you need to make changes after hearing back from your supplier, you can edit your sent order. This action cannot be undone.",
      confirmBtnTitle: "Change status to sent",
      onConfirm: () => {
        API(controller.Stock + action.MarkStatusAsSent).getById(
          order.id,
          (res) => {
            if (res && res.result && res.status === 200) {
              setOrder((j) => ({ ...j, ...res.result }));
              setAlert({
                isOpen: true,
                type: "success",
                title: "Success",
                subTitle: res.msg,
              });
            } else
              setAlert({
                isOpen: true,
                type: "error",
                title: "Error",
                subTitle: res.msg,
              });
          }
        );
      },
    });
  };

  return (
    <>
      {order ? (
        <Grid container spacing={3} className="p-[10px]">
          <Details {...{ order, setShowCancelForm }} />
          <Products
            {...{
              order,
              setShowCancelForm,
              handleMarkAsSent,
            }}
          />
        </Grid>
      ) : (
        "Loading..."
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />

      {showCancelForm && (
        <Dialog show={showCancelForm} onClose={false} maxWidth="sm">
          <CancelOrderForm
            {...{ setOrder, order, setShowForm: setShowCancelForm, setAlert }}
          />
        </Dialog>
      )}
    </>
  );
};

export default ViewOrder;
