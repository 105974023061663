import { Chip } from "@mui/material";
import React from "react";

const StatusWidget = ({
  status,
  isOverdue = false,
  fontSize = "14px",
  className,
}) => {
  const renderStatus = () => {
    switch (status) {
      case 1:
        return (
          <div className="flex gap-2">
            <Chip
              label="Open"
              color="primaryXS"
              className={`!text-[${fontSize}] shadow-xl font-medium tracking-wider uppercase ${className}`}
            />
            {isOverdue && (
              <Chip
                label="Overdue"
                className={`!text-[${fontSize}] shadow-xl font-medium tracking-wider !bg-red-100 !text-red-500 uppercase ${className}`}
              />
            )}
          </div>
        );
      case 2:
        return (
          <Chip
            label="Received"
            color="success"
            className={`!text-[${fontSize}] shadow-xl tracking-wider uppercase ${className}`}
          />
        );
      case 3:
        return (
          <Chip
            label="Cancelled"
            color="gray"
            className={`!text-[${fontSize}] shadow-xl tracking-wider uppercase ${className}`}
          />
        );
      case 4:
        return (
          <div className="flex gap-2">
            <Chip
              label="Sent"
              color="primary"
              className={`!text-[${fontSize}] shadow-xl tracking-wider uppercase ${className}`}
            />
            {isOverdue && (
              <Chip
                label="Overdue"
                className={`!text-[${fontSize}] shadow-xl font-medium tracking-wider !bg-red-100 !text-red-500 uppercase ${className}`}
              />
            )}
          </div>
        );
      case 5:
        return (
          <Chip
            label="Dispatched"
            color="warning"
            className={`!text-[${fontSize}] shadow-xl tracking-wider uppercase ${className}`}
          />
        );
      default:
        break;
    }
  };

  return renderStatus();
};

export default StatusWidget;
