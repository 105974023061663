import React from "react";
// Icons
import CheckCircleRounded from "@mui/icons-material/CheckCircle";
import CancelRounded from "@mui/icons-material/Cancel";

export default function PassValidation(props) {
  const { passValid, password } = props;
  return (
    password !== "" &&
    !passValid.passValid && (
      <>
        <div className="mt-[10px] flex text-gray-500">
          {passValid.charNumberValid ? (
            <CheckCircleRounded className="success-color text-[20px]" />
          ) : (
            <CancelRounded className="error-color text-[20px]" />
          )}
          <span className="fs-15"> &nbsp; 6-20 characters</span>
        </div>
        <div className="mt-[10px] flex text-gray-500">
          {passValid.specialCharValid ? (
            <CheckCircleRounded className="success-color text-[20px]" />
          ) : (
            <CancelRounded className="error-color text-[20px]" />
          )}

          <span className="fs-15">&nbsp; 1 special character</span>
        </div>
        <div className="mt-[10px] flex text-gray-500">
          {passValid.uppercaseValid ? (
            <CheckCircleRounded className="success-color text-[20px]" />
          ) : (
            <CancelRounded className="error-color text-[20px]" />
          )}
          <span className="fs-15"> &nbsp; 1 uppercase letter</span>
        </div>
        <div className="mt-[10px] flex text-gray-500">
          {passValid.numberValid ? (
            <CheckCircleRounded className="success-color text-[20px]" />
          ) : (
            <CancelRounded className="error-color text-[20px]" />
          )}

          <span className="fs-15"> &nbsp; 1 number</span>
        </div>
      </>
    )
  );
}
