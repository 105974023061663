import { IconButton, Paper } from "@mui/material";

const Widget = (props) => {
  const { icon, number = "0.00", title } = props;
  return (
    <Paper className="p-[20px] pb-[30px] pt-[30px] bg-blue primary-color bg-primaryXS flex items-center gap-[20px] shadow-sm bs-primaryMD cursor-pointer">
      <div>
        <IconButton color="primary">{icon}</IconButton>
      </div>
      <div className="grid items-center">
        <span className="text-[28px] font-medium mb-[5px]">{number}</span>
        <span className="primary-color text-[14px]">{title}</span>
      </div>
    </Paper>
  );
};

export default Widget;
