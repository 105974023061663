import React from "react";
import { Avatar, Divider, IconButton, Tooltip } from "@mui/material";
import { EditRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const CustomerInfo = (props) => {
  const { cstmr, gender, accBalType } = props;
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/customer_form/${cstmr.id}`);
  };

  return (
    <>
      {cstmr ? (
        <div className="grid bg-white p-[10px] rounded-[25px] text-gray-500">
          <div className="text-right">
            <Tooltip title="Edit Member">
              <IconButton
                onClick={handleEdit}
                size="small"
                color="primary"
                sx={{ border: "1px solid var(--primarySM-color)" }}
              >
                <EditRounded className="text-[20px] p-[2px]" />
              </IconButton>
            </Tooltip>
          </div>
          <div className="flex justify-center mb-[10px]">
            <Avatar
              src={cstmr.photo}
              alt="profile_pic"
              className="object-cover"
              sx={{ width: "110px", height: "110px" }}
            />
          </div>
          <div className="grid gap-[10px] justify-center">
            <div className="tx-center">
              <p className="text-[28px] m-[0px] mb-[5px] font-medium">
                {cstmr.firstName + " " + cstmr.lastName}
              </p>
              <p className="text-[12px] text-gray-400 m-[0px]">
                Customer scince:{" "}
                {new Date(cstmr.createDate).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div className="p-[10px]">
            <div className="flex items-center mt-[40px] mb-[10px]">
              <span
                className="text-[12px] italic uppercase"
                style={{ width: "180px" }}
              >
                Personal Details
              </span>
              <div className="border border-gray-500 w-[100%]" />
            </div>
            {cstmr.dob && (
              <div className="flex flax-wrap justify-between items-center mb-[5px]">
                <span className="text-[15px] mr-[30px]">Birthday :</span>
                <div className="grid text-center">
                  <span className="text-[15px]">
                    {moment().diff(moment(cstmr.dob, "MM/DD/YYYY"), "years")}{" "}
                    Years old
                  </span>
                  <span className="text-[12px] text-gray-400">
                    {new Date(cstmr.dob).toDateString()}
                  </span>
                </div>
              </div>
            )}
            <Divider />
            <div className="flex flex-wrap j-between items-center mt-[10px] mb-[10px]">
              <span className="text-[15px] mr-[30px]">Gender :</span>
              <span className="text-[15px]">{gender}</span>
            </div>
            <Divider />
            <div className="flex flex-wrap j-between items-center mt-[10px] mb-[10px]">
              <span className="text-[15px] mr-[30px]">Group :</span>
              <span className="text-[15px]">
                {cstmr.cstmrGroup && cstmr.cstmrGroup.name}
              </span>
            </div>
            <Divider />
            <div className="flex flex-wrap j-between items-center mt-[10px] mb-[10px]">
              <span className="text-[15px] mr-[30px]">Customer Code :</span>
              <span className="text-[15px]">{cstmr.cstmrCode}</span>
            </div>
            <Divider />
            <div className="flex flex-wrap j-between items-center mt-[10px] mb-[10px]">
              <span className="text-[15px] mr-[30px]">
                Account Balance Type :
              </span>
              <span className="text-[15px]">{accBalType}</span>
            </div>
            <Divider />
            <div className="flex flex-wrap j-between items-center mt-[10px] mb-[10px]">
              <span className="text-[15px] mr-[30px]">Phone :</span>
              <a className="text-[15px] link" href={`tel:${cstmr.phone}`}>
                {cstmr.phone}
              </a>
            </div>
            <Divider />
            <div className="flex flex-wrap j-between items-center mt-[10px] mb-[10px]">
              <span className="text-[15px] mr-[30px]">Email :</span>
              <a className="link text-[15px]" href={`mailto:${cstmr.email}`}>
                {cstmr.email}
              </a>
            </div>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default CustomerInfo;
