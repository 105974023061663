import React, { useContext, useEffect, useState } from "react";
import Barcode from "react-barcode";
import { CartContext } from "../Sell";
import moment from "moment";

const PrintRecept = ({ data }) => {
  const { sellReg } = useContext(CartContext);
  const [outlet, setOutlet] = useState();

  useEffect(() => {
    if (sellReg && sellReg.outlet) setOutlet(sellReg.outlet);
    return () => {};
  }, [sellReg]);

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          maxWidth: "400px",
          margin: "0 auto",
          padding: "20px",
          border: "1px solid #ddd",
          display: "grid",
        }}
      >
        {/* Header  */}
        {outlet && (
          <div
            style={{
              display: "grid",
              gap: "5px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Sell Receipt
            </span>
            <span style={{ fontSize: "20px", fontWeight: "400" }}>
              {outlet.outletName}
            </span>
            <span>{outlet.address1}</span>
            {outlet.address2 && <span>{outlet.address2}</span>}
            <span>
              {outlet.city}, {outlet.state} {outlet.zip}
            </span>
            <span>Tell: {outlet.phone}</span>
          </div>
        )}

        {/* Cashier Details */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            borderBottom: "2px solid #ddd",
          }}
        >
          <div>
            <p style={{ fontWeight: "400", textTransform: "uppercase" }}>
              Cashier:{" "}
              {sellReg &&
                sellReg.cashier &&
                sellReg.cashier.firstName + " " + sellReg.cashier.lastName}
            </p>
          </div>
          <div style={{ display: "grid", gap: "5px" }}>
            <span style={{}}>
              Date: {moment(data.sellDate).format("ddd, DD MMM YYYY")}
            </span>
            <span style={{}}>
              Time: {moment(data.sellDate).format("hh:mm A")}
            </span>
          </div>
        </div>

        <table
          style={{
            width: "100%",
            marginTop: "20px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  paddingBottom: "15px",
                  textTransform: "uppercase",
                }}
              >
                Item
              </th>
              <th
                style={{
                  textAlign: "right",
                  paddingBottom: "15px",
                  textTransform: "uppercase",
                }}
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {data.sellItems.map((item, ind) => (
              <tr key={ind}>
                <td
                  style={{
                    maxWidth: "180px",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gap: "3px",
                      marginBottom: "10px",
                    }}
                  >
                    {/* Product Name */}
                    <span style={{ fontSize: "14px", fontWeight: "400" }}>
                      {item.prod.name}
                    </span>
                    {/* Product Variant Name */}
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      Qty: {item.qty}
                      {item.variant &&
                        ` | ${item.variant.attrVal1} ${
                          item.variant.attrVal2 && " / " + item.variant.attrVal2
                        } ${
                          item.variant.attrVal3 && " / " + item.variant.attrVal3
                        }`}
                    </span>
                  </div>
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  <span style={{ marginBottom: "10px" }}>
                    ${" "}
                    {item.totalPrice.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ borderBottom: "3px solid #8f8f8f", marginTop: "15px" }} />
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "space-between",
            grid: "10px",
            textTransform: "uppercase",
          }}
        >
          {/* Label */}
          <div style={{ display: "grid", gap: "10px", color: "#6f6f6f" }}>
            <span>Tax ({data.taxRate}%)</span>
            <span>Discount</span>
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              Total Pay
            </span>
            <span>Received</span>
            <span>Change</span>
          </div>

          {/* Value */}
          <div style={{ display: "grid", gap: "5px", color: "#6f6f6f" }}>
            <span>
              {" "}
              ${" "}
              {(data.taxAmt || 0).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
            <span>
              (-) ${" "}
              {(data.disAmt || 0).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
              ${" "}
              {(data.totalPay || 0).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
            <span>
              ${" "}
              {(data.received || 0).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
            <span>
              ${" "}
              {(data.change || 0).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>

        <div style={{ borderBottom: "3px solid #bfbfbf", marginTop: "10px" }} />
        <div
          style={{
            paddingTop: "15px",
            paddingBottom: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Barcode value={data.id.toString()} />
        </div>

        <div style={{ borderBottom: "3px solid #bfbfbf", marginTop: "10px" }} />
        <p
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "22px",
            fontWeight: "bold",
            color: "#6f6f6f",
            textTransform: "uppercase",
            letterSpacing: "5px",
          }}
        >
          * Thank you *
        </p>
      </div>
    </div>
  );
};

export default PrintRecept;
