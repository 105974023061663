import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Box, List as MuiList, IconButton, Drawer } from "@mui/material";
import { WinDims } from "../../hooks/Method";
import { CloseRounded, MenuRounded } from "@mui/icons-material";
import "./Dashboard.scss";
import { useLocation } from "react-router-dom";

const List = styled(MuiList)({
  "& .MuiListItemButton-root": {
    marginBottom: "0px",
    "&, & .MuiListItemIcon-root": {
      color: "var(--black-color)",
      minWidth: "40px",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "var(--white-color)",
    "&, & .MuiListItemIcon-root": {
      color: "var(--primaryDark-color)",
    },
    "& .MuiListItemText-root .MuiTypography-root": {
      fontWeight: "500",
      color: "var(--primaryDark-color)",
    },
  },
});

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  top: "inherit",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Dashboard(props) {
  const { menu, header, children, showNav = true, ...other } = props;
  const minWidth = 1024;
  const location = useLocation();
  const windowDims = WinDims().width;
  const [inPOS, setInPOS] = useState(false);
  const [open, setOpen] = useState(
    showNav && (windowDims > minWidth ? true : false)
  );
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (showNav && location.pathname && location.pathname.includes("/sell")) {
      setInPOS(true);
      setOpen(false);
    } else {
      setInPOS(false);
      setOpen(windowDims > minWidth ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, windowDims]);

  return (
    <div style={{ display: "flex" }} {...other}>
      {/* --- Navbar --- */}
      <AppBar className="appbar">
        <div className="appbar-container">
          <div className="appbar-inner-div">
            <Box
              sx={{
                display: { md: `${inPOS ? "block" : "none"}`, xs: "block" },
              }}
              className="ml-[10px]"
            >
              <IconButton onClick={toggleDrawer} size="small" color="white">
                {open ? <CloseRounded /> : <MenuRounded />}
              </IconButton>
            </Box>
            {(showNav || open) && header}
          </div>
        </div>
      </AppBar>

      {/* --- Menu --- */}
      <Drawer
        open={open}
        onClose={() => setOpen(!open)}
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            display: open ? "block" : "none",
            width: `${drawerWidth}px`,
            position: { md: "relative", xs: "" },
            backgroundColor: "#e7e5e8",
            border: "none",
            boxShadow: "none",
            borderRadius: "0px",
          },
        }}
      >
        <List
          sx={{
            height: "calc(100vh - 80px)",
            overflow: "auto",
            pt: "70px !important",
          }}
        >
          {menu}
        </List>
      </Drawer>

      {/* --- Body --- */}
      <Box
        component="main"
        className="bg-inherit p-[20px] w-[100%] mt-[50px]"
        sx={{
          overflow: "auto",
          height: "calc(100vh - 50px)",
        }}
      >
        {children}
      </Box>
    </div>
  );
}
