import { Collapse, Paper, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { GetHours } from "../../hooks/Method";
import { Button } from "../../controls";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { useState } from "react";
import StoreMetrics from "./StoreMetrics";

const OutletProg = () => {
  const [showMetrics, setShowMetrics] = useState(false);
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: GetHours(),
    },
    yaxis: {
      labels: {
        formatter: (val) => "$" + val,
        style: {
          fontSize: "14px",
        },
      },
    },
  };
  const series = [
    {
      name: "Main  Outlet",
      data: outlet1,
    },
    {
      name: "ABC Outlet",
      data: outlet2,
    },
  ];

  const handleShowMetrics = () => {
    setShowMetrics(!showMetrics);
  };

  return (
    <>
      <Paper className="shadow-xs p-[15px] bg-white">
        {/* Chart */}
        <Typography
          varint="h6"
          className="text-[18px] !font-medium text-slate-500 mb-[10px]"
        >
          All Outlet Sales
        </Typography>

        <ReactApexChart
          options={options}
          series={series}
          type="area"
          width="100%"
          height="300"
        />
        <div className="text-right">
          <Button
            variant="text"
            onClick={handleShowMetrics}
            endIcon={<KeyboardArrowDownRounded />}
          >
            Show more retail metrics
          </Button>
        </div>
      </Paper>

      <Collapse in={showMetrics}>
        <StoreMetrics />
      </Collapse>
    </>
  );
};

export default OutletProg;

const outlet1 = [
  1000, 800, 600, 1100, 600, 800, 1000, 1500, 1300, 900, 600, 100, 100, 600,
  150,
];

const outlet2 = [
  300, 400, 500, 900, 500, 800, 600, 100, 400, 800, 600, 600, 700, 350, 800,
];
