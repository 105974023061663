import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, IconButton, Paper } from "@mui/material";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  LabelComp,
  MultiSelect,
} from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";
import { AppContext } from "../../../../App";
import { API, action, controller } from "../../../../api/api";
import {
  BGNestedObjToFromData,
  RmvEmptyProp,
  ToFormData,
} from "../../../../hooks/Method";
import { Alert } from "../../../../layout";
import OrderItems from "./OrderItems";
import Accounting from "./Accounting";

const OrderAndReceiveForm = (props) => {
  const { isReceiveForm = false } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { StockMdl } = Model();
  const { outlets, selOutlet, authUser } = useContext(AppContext);
  const [splrs, setSplrs] = useState([]);
  const [stores, setStores] = useState([]);
  const { values, setValues, errors, setErrors, handleInpChg, handleInpDate } =
    UseForm(StockMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setStores([
      { id: "all", label: `All Outlets (${outlets.length})` },
      ...outlets,
    ]);

    return () => {};
  }, [outlets]);

  useEffect(() => {
    if (authUser.id) {
      API(controller.Supplier + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setSplrs([
            ...res.result.map((j) => ({
              ...j,
              label: j.firstName + " " + j.lastName,
            })),
          ]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  // Purchase Update Operation
  useEffect(() => {
    id &&
      API(controller.Stock + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((j) => ({
            ...j,
            ...val,
            user: "",
            outlet: "",
            org: "",
            orderingForDtls: val.orderingFor,
            orderingFor: val.orderingFor.map((x) => x.outletId),
            stockProds: [
              ...val.stockProds.map((x) => ({
                ...x,
                user: "",
                org: "",
                outlet: "",
                img: x.isVariant
                  ? x.varProd && x.varProd.imgs[0] && x.varProd.imgs[0].imgUrl
                  : x.prod.imgs[0] && x.prod.imgs[0].imgUrl,
                name: x.isVariant
                  ? (x.varProd && x.varProd.product.name) +
                    " / " +
                    x.varProd.attrVal1 +
                    (x.varProd.attrVal2 && ` / ${x.varProd.attrVal2}`) +
                    (x.varProd.attrVal3 && ` / ${x.varProd.attrVal3}`)
                  : x.prod && x.prod.name,
                subtitle: x.isVariant
                  ? x.varProd && x.varProd.product.skuCode
                  : x.prod && x.prod.skuCode,
                totalPrice: x.qty * x.price,
              })),
            ],
          }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.splrId = values.splrId !== "" ? "" : "Supplier is required.";
    temp.orderingFor =
      values.orderingFor.length > 0 ? "" : "Ordering for outlet is required.";
    temp.recipientOutletId =
      values.recipientOutletId !== "" ? "" : "Recipient outlet is required.";
    temp.orderNum = values.orderNum !== "" ? "" : "Order number is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (values.stockProds.length === 0) {
        setAlert({
          isOpen: true,
          type: "warning",
          title: "You cannot submit without product.",
          subTitle: "You must have to select products that purchased.",
        });
      } else {
        if (isReceiveForm) {
          setAlert({
            isOpen: true,
            type: "confirmation",
            title:
              "You’re about to receive this delivery which will update your inventory",
            subTitle: "This action is permanent and can’t be undone.",
            confirmBtnTitle: "Receive Delivery",
            cancelBtnTitle: "Don't Receive",
            hideIcon: true,
            onConfirm: () => {
              _submitForm();
            },
          });
        } else {
          _submitForm();
        }
      }
    }
  };

  console.log("values: ", values);

  const _submitForm = () => {
    setLoading(true);
    const onComplete = (res) => {
      setLoading(false);
      if (res) {
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
            onClick: () => navigate("/stock_control"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      }
    };

    if (values.id === 0) {
      API(controller.Stock + action.Post).post(
        BGNestedObjToFromData({
          ...values,
          stockProds: values.stockProds.map((j) => ({
            ...j,
            prod: null,
            varProd: null,
          })),
          outletId: selOutlet.id,
          orgId: selOutlet.orgId,
          userId: authUser.id,
          status: isReceiveForm ? 2 : 1,
          orderingFor: values.orderingFor.map((j) => ({ outletId: j })),
        }),
        onComplete
      );
    } else {
      API(controller.Stock + action.Put).put(
        BGNestedObjToFromData({
          ...values,
          recipientOutlet: "",
          stockProds: values.stockProds.map((j) => ({
            ...j,
            prod: null,
            varProd: null,
          })),
          orderingFor: values.orderingFor.map((j) => ({ outletId: j })),
          splr: null,
          updateDate: new Date().toISOString(),
        }),
        onComplete
      );
    }
  };

  useEffect(() => {
    // Newly Added Product
    var newProd = values.stockProds[0];
    if (newProd && values.stockProds.some((j) => j.prevStock === "")) {
      // map existing stock.
      newProd.orderInvsFor.forEach((orderInv) => {
        API(controller.Inventory + action.GetInventory).post(
          ToFormData({
            id: newProd.isVariant ? newProd.varProdId : newProd.prodId,
            outletId: orderInv.outletId,
            boolVal: newProd.isVariant,
          }),
          (res) => {
            if (res && res.result) {
              setValues((j) => ({
                ...j,
                stockProds: [
                  ...j.stockProds.map((x) =>
                    (
                      newProd.isVariant
                        ? x.varProdId === newProd.varProdId
                        : x.prodId === newProd.prodId
                    )
                      ? {
                          ...x,
                          orderInvsFor: x.orderInvsFor.map((y) =>
                            y.outletId === orderInv.outletId
                              ? { ...y, previousStock: res.result }
                              : { ...y }
                          ),
                        }
                      : { ...x }
                  ),
                ],
              }));
            }
          }
        );
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stockProds.length]);

  const handleInpOrderingFor = (e) => {
    if (e.target.value.includes("all")) {
      e.target.value = stores.filter((j) => j.id !== "all").map((j) => j.id);
      handleInpChg(e);
    } else {
      handleInpChg(e);
    }
  };

  return (
    <>
      <Paper>
        <div className="flex justify-between items-center p-[10px]">
          <div className="flex items-center gap-[10px]">
            <div>
              <IconButton size="small" onClick={() => navigate(-1)}>
                <ArrowBackRounded />
              </IconButton>
            </div>

            <div>
              <h1 className="form-title !font-normal text-gray-500 uppercase">
                {isReceiveForm
                  ? `Receive ${id ? "Delivery" : "Stock"}`
                  : `${id ? "Edit" : "New"} Purchase Order`}
              </h1>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              loading={loading}
              onClick={() => handleSubmit()}
            >
              {id ? "Save Changes" : "Submit"}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="p-[15px] mt-[15px]">
          <Grid container spacing={3}>
            {isReceiveForm && id && values.id ? (
              <>
                <Grid item xs={12} md={4}>
                  <LabelComp
                    label="Supplier"
                    value={
                      values.splr
                        ? values.splr.firstName + " " + values.splr.lastName
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LabelComp
                    label="Ordering for"
                    value={values.orderingForDtls.map(
                      (val, ind) =>
                        `${val.outlet && val.outlet.outletName}${
                          values.orderingForDtls.length > ind + 1 ? " | " : ""
                        }`
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LabelComp
                    label="Delivery to"
                    value={
                      values.recipientOutlet &&
                      values.recipientOutlet.outletName
                    }
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={4}>
                  <InputAuto
                    label="Supplier"
                    name="splrId"
                    required={true}
                    value={values.splrId}
                    error={errors.splrId}
                    onChange={handleInpChg}
                    options={splrs}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MultiSelect
                    label="Ordering for"
                    name="orderingFor"
                    required={true}
                    value={values.orderingFor}
                    error={errors.orderingFor}
                    onChange={(e) => handleInpOrderingFor(e)}
                    options={stores}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputAuto
                    label="Delivery Recipient"
                    name="recipientOutletId"
                    required={true}
                    value={values.recipientOutletId}
                    error={errors.recipientOutletId}
                    onChange={handleInpChg}
                    options={outlets}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={4}>
              <Input
                label="Order Number"
                name="orderNum"
                required
                value={values.orderNum}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Supplier Invoice Number"
                name="splrInv"
                value={values.splrInv}
                onChange={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label="Delivery Date"
                name="deliveryDate"
                value={values.deliveryDate}
                onChange={(date) =>
                  handleInpDate(
                    "deliveryDate",
                    new Date(date).toLocaleDateString()
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Note"
                name="note"
                multiline
                rows={3}
                value={values.note}
                onChange={handleInpChg}
              />
            </Grid>

            <Grid item xs={12}>
              <OrderItems {...{ values, setValues, stores, isReceiveForm }} />
            </Grid>
          </Grid>

          <Accounting {...{ values, setValues, handleInpChg }} />
        </div>
      </Paper>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default OrderAndReceiveForm;
