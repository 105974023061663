import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import { Button, InputAuto, InputSwitch, Select } from "../../../controls";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import {
  ClearRounded,
  EditRounded,
  RemoveRedEyeRounded,
  // RemoveRedEyeRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import { no_img } from "../../../assets";
import ViewProd from "./ViewProduct/ViewProd";

const Products = () => {
  const navigate = useNavigate();
  const { authUser, setLoader } = useContext(AppContext);
  const [splrFltrVal, setSplrFltrVal] = useState("");
  const [brandFltrVal, setBrandFltrVal] = useState("");
  const [catFltrVal, setCatFltrVal] = useState("");
  const [channelFltrVal, setChannelFltrVal] = useState(true);
  const [products, setProducts] = useState([]);
  const [filteredProds, setFilteredProds] = useState([]);
  const [cats, setCats] = useState([]);
  const [splrs, setSplrs] = useState([]);
  const [brands, setBrands] = useState([]);
  const [showViewProd, setShowViewProd] = useState(false);
  const [viewProdId, setViewProdId] = useState(0);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser.id) {
      setLoader(true);

      // Get Products
      API(controller.Product + action.GetAllByOrg).get((res) => {
        res && res.status === 200 && setProducts([...res.result]);
        setLoader(false);
      });

      // Get Categories
      API(controller.Category + action.GetAllMainCatByOrg).get((res) => {
        res.status === 200 &&
          setCats([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });

      // Get Brand
      API(controller.Brand + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setBrands([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });

      // Get Suppliers
      API(controller.Supplier + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setSplrs([...res.result.map((j) => ({ ...j, label: j.splrName }))]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (id) => {
    navigate(`/product_form/${id || ""}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this product?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res) {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setProducts((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
          }
        };
        API(controller.Product).Delete(id, onComplete);
      },
    });
  };

  const handleSplrFltr = (e) => {
    setSplrFltrVal(e.target.value);
  };
  const handleBrandFltr = (e) => {
    setBrandFltrVal(e.target.value);
  };
  const handleCatFltr = (e) => {
    setCatFltrVal(e.target.value);
  };
  const handleChannelFltr = (e) => {
    setChannelFltrVal(e.target.value);
  };

  useEffect(() => {
    var fltrProds = products.filter((j) => j.isActive === channelFltrVal);
    if (splrFltrVal)
      fltrProds = fltrProds.filter((j) => j.splrId === splrFltrVal);
    if (catFltrVal) fltrProds = fltrProds.filter((j) => j.catId === catFltrVal);
    if (brandFltrVal)
      fltrProds = fltrProds.filter((j) => j.brandId === brandFltrVal);

    setFilteredProds(fltrProds);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandFltrVal, catFltrVal, channelFltrVal, splrFltrVal, products]);

  const handleView = (id) => {
    setViewProdId(id);
    setShowViewProd(true);
  };

  const handleActive = (id) => {
    setLoader(true);
    API(controller.Product + action.ActiveAndInactive).getById(id, (res) => {
      setLoader(false);
      if (res.status === 200)
        setProducts([
          ...products.map((j) =>
            j.id === id ? { ...j, isActive: res.result.isActive } : j
          ),
        ]);
      else console.error("error: ", res.msg);
    });
  };

  const ActionComp = (val) => (
    <div className="border !border-gray-100 flex gap-2 p-[3px] shadow-sm rounded-[12px] w-fit">
      <Tooltip title="View Product">
        <IconButton
          onClick={() => handleView(val.id)}
          size="small"
          color="primary"
        >
          <RemoveRedEyeRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val.id)}
          size="small"
          color="primary"
        >
          <EditRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div className="flex flex-wrap gap-[10px]">
      <Button onClick={() => handleAddEdit()}>Add Product</Button>
      <Button variant="text">Import Product</Button>
    </div>
  );

  console.log("table: ", products);

  return (
    <>
      <Paper className="!p-[10px] mb-[15px] bg-white">
        <Box
          className="gap-[15px]"
          sx={{ display: { lg: "flex", xs: "grid" } }}
        >
          <Select
            label="Channel Status"
            size="small"
            value={channelFltrVal}
            fullWidth
            onChange={handleChannelFltr}
            options={[
              { id: true, label: "Active" },
              { id: false, label: "Inactive" },
            ]}
          />
          <InputAuto
            label="Supplier"
            size="small"
            value={splrFltrVal}
            fullWidth
            onChange={handleSplrFltr}
            options={splrs}
          />
          <InputAuto
            label="Category"
            size="small"
            value={catFltrVal}
            fullWidth
            onChange={handleCatFltr}
            options={cats}
          />
          <InputAuto
            label="Brand"
            size="small"
            value={brandFltrVal}
            fullWidth
            onChange={handleBrandFltr}
            options={brands}
          />
        </Box>
      </Paper>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "active", label: "Active" },
          { id: "prod", label: "Product" },
          { id: "brandName", label: "Brand" },
          { id: "splrName", label: "Supplier" },
          { id: "inv", label: "Inventory" },
          { id: "rtlPrice", label: "Retail Price" },
          { id: "created", label: "Created" },
        ]}
        tblData={filteredProds.map((j) => ({
          ...j,
          active: (
            <InputSwitch
              checked={j.isActive}
              onChange={() => handleActive(j.id)}
            />
          ),
          brandName: j.brand && j.brand.name,
          splrName: j.splr && j.splr.splrName,
          created: new Date(j.createDate).toDateString(),
          rtlPrice: `$ ${j.rtlPrice}`,
          inv:
            j.prodType === 2
              ? j.prodVariants &&
                j.prodVariants
                  .map((x) =>
                    x.inventories.map((y) => y.inInv).reduce((x, y) => x + y, 0)
                  )
                  .reduce((x, y) => x + y, 0)
              : j.prodType === 1
              ? j.inventories.map((x) => x.inInv).reduce((x, y) => x + y, 0)
              : "",
          prod: (
            <div className="flex gap-[15px] items-center mb-[5px] mt-[5px] mr-[30px]">
              <img
                src={j.imgs[0] ? j.imgs[0].imgUrl : no_img}
                alt="product_img"
                className="img-fluid bg-white rounded-[10px] shadow-md  w-[60px] h-[40px] object-contain"
              />
              <div className="grid">
                <span className="">{j.name}</span>
                <span className="text-[12px]">
                  {j.prodType === 1
                    ? j.skuCode
                    : j.prodType === 2
                    ? `${j.prodVariants.length} variants`
                    : `${j.compositProds.length} composite items`}
                </span>
              </div>
            </div>
          ),
        }))}
      />

      {/* View Product */}
      {showViewProd && (
        <Dialog show={showViewProd} onClose={false} maxWidth="lg">
          <ViewProd {...{ setShowForm: setShowViewProd, id: viewProdId }} />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Products;
