import React, { useState } from "react";
import RegisterDtls from "./RegisterDtls";
import Payments from "./Payments";
import { Input } from "../../../controls";

const CloseReg = () => {
  const [note, setNote] = useState("");

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  return (
    <div className="p-15 d-grid">
      <div className="mb-30">
        <RegisterDtls />
      </div>
      <Payments />
      <div className="mt-20 shadow-xs">
        <Input
          placeholder="Write your note here..."
          name="note"
          value={note}
          variant="outlined"
          multiline
          rows={4}
          onChange={handleNote}
        />
      </div>
    </div>
  );
};

export default CloseReg;
