import React, { useContext } from "react";
import { ButtonGroup, Input } from "../../../../controls";
import { CartContext } from "../Sell";
import { DropLi } from "../../../../hooks";

const CartAdditionalFields = () => {
  const { DiscountTypes } = DropLi();
  const { setCartData, cartData } = useContext(CartContext);

  const handleInpChg = (e) => {
    setCartData((j) => ({ ...j, [e.target.name]: e.target.value }));
  };

  return (
    <div className="grid gap-[15px] ml-[25px]">
      <div className="flex gap-[2px]">
        <Input
          placeholder="Discount"
          name="discount"
          type="number"
          variant="outlined"
          size="small"
          value={cartData.discount}
          onChange={handleInpChg}
        />
        <ButtonGroup
          value={cartData.discountType || 0}
          onChange={handleInpChg}
          name="discountType"
          options={DiscountTypes}
        />
      </div>
      <Input
        placeholder="Note"
        name="note"
        value={cartData.note}
        variant="outlined"
        size="small"
        multiline
        rows={2}
        onChange={handleInpChg}
      />
    </div>
  );
};

export default CartAdditionalFields;
