import { Divider, Grid, IconButton, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Select } from "../../../controls";
import { Model, UseForm } from "../../../hooks";
import { Alert, Form } from "../../../layout";
import { ArrowBackRounded } from "@mui/icons-material";
import { API, action, controller } from "../../../api/api";
import { RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { AppContext } from "../../../App";

const AddEditOutlet = (props) => {
  const { id } = useParams();
  const { OutletMdl } = Model();
  const navigate = useNavigate();
  const { authUser, setLoader } = useContext(AppContext);
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(OutletMdl);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    id &&
      API(controller.Outlet + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((x) => ({ ...x, ...val }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (authUser.id) {
      API(controller.Tax + action.GetAllByOrg).get((res) => {
        res &&
          res.status === 200 &&
          setTaxes([...res.result.map((j) => ({ ...j, label: j.name }))]);
        setLoader(false);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const validateForm = () => {
    let temp = {};
    temp.outletName =
      values.outletName !== "" ? "" : "Outlet name is required.";
    if (!values.id)
      temp.regName = values.regName !== "" ? "" : "Register name is required.";
    temp.taxId = values.taxId !== "" ? "" : "Tax is required.";
    temp.phone = values.phone !== "" ? "" : "Phone is required.";
    temp.email = values.email !== "" ? "" : "Email is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res) {
          if (res.status === 201 || res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: `Outlet ${id ? "Updated" : "Added"} Successfully.`,
              onClick: () => navigate("/outlets"),
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        }
      };
      if (values.id === 0) {
        API(controller.Outlet + action.Post).post(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else {
        API(controller.Outlet + action.Put).put(
          ToFormData(RmvEmptyProp(values)),
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-[10px] flex justify-between items-center pt-[15px] pl-[15px] pr-[15px]">
            <div className="flex items-center gap-[10px]">
              <div>
                <IconButton size="small" onClick={() => navigate(-1)}>
                  <ArrowBackRounded />
                </IconButton>
              </div>
              <div>
                <h1 className="text-[22px] font-medium text-gray-500 uppercase">
                  {id ? "Update" : "Add"} Outlet
                </h1>
              </div>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                {id ? "Save Changes" : "Submit"}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="p-[15px] mt-[10px]">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="grid lg:flex gap-4">
                  <Input
                    label="Outlet Name"
                    name="outletName"
                    required={true}
                    value={values.outletName}
                    error={errors.outletName}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />

                  {!values.id && (
                    <Input
                      label="Register Name"
                      name="regName"
                      required={true}
                      value={values.regName}
                      error={errors.regName}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleInpChg}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  label="Tax"
                  name="taxId"
                  required={true}
                  value={values.taxId}
                  error={errors.taxId}
                  onChange={handleInpChg}
                  options={taxes}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Phone"
                  name="phone"
                  required={true}
                  value={values.phone}
                  error={errors.phone}
                  inputProps={{ maxLength: 50 }}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Email"
                  name="email"
                  required={true}
                  value={values.email}
                  error={errors.email}
                  inputProps={{ maxLength: 200 }}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="flex items-center pl-[5px] mt-[20px]">
                  <span
                    className="text-gray-500 uppercase fw-5 italic"
                    style={{ minWidth: "160px" }}
                  >
                    Physical Address
                  </span>
                  <div className="border border-gray-500 w-[100%]" />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Address Line 1"
                  name="address1"
                  value={values.address1}
                  error={errors.address1}
                  inputProps={{ maxLength: 400 }}
                  multiline
                  rows={2}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Address Line 2"
                  name="address2"
                  value={values.address2}
                  error={errors.address2}
                  inputProps={{ maxLength: 400 }}
                  multiline
                  rows={2}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="City"
                  name="city"
                  value={values.city}
                  error={errors.city}
                  inputProps={{ maxLength: 300 }}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="State"
                  name="state"
                  value={values.state}
                  error={errors.state}
                  inputProps={{ maxLength: 300 }}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="ZIP"
                  name="zip"
                  value={values.zip}
                  error={errors.zip}
                  inputProps={{ maxLength: 50 }}
                  onChange={handleInpChg}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditOutlet;
