import React, { useContext, useState } from "react";
import { InputAuto } from "../../../../controls";
import { AppContext } from "../../../../App";
import { action, API, controller } from "../../../../api/api";

const InputSelectItem = ({ values, setValues, setAlert }) => {
  const { selOutlet } = useContext(AppContext);
  const [searchVal, setSearchVal] = useState("");
  const [searchLi, setSearchLi] = useState([]);
  const [findErr, setFindErr] = useState("");

  const handleScan = (e, searchVal) => {
    const onComplete = (res) => {
      if (res && res.result.length) {
        const _prod = res.result.filter((j) => j.prodType !== 3)[0];
        var isVariantProd = _prod.prodType === 2 ? true : false;

        const prod = isVariantProd
          ? _prod.prodVariants.find((j) => j.skuCode === searchVal)
          : _prod;

        prod
          ? handleSelect(null, {
              ...prod,
              splrId: _prod.splrId,
              isVariant: isVariantProd,
              label: isVariantProd
                ? `${
                    _prod.name +
                    " / " +
                    prod.attrVal1 +
                    (prod.attrVal2 && ` / ${prod.attrVal2}`) +
                    (prod.attrVal3 && ` / ${prod.attrVal3}`)
                  }`
                : prod.name,
            })
          : setFindErr("Product Not Found");
      }
    };
    e.target.value && GetDataFromServer(e.target.value, onComplete);
    setSearchVal("");
  };

  const GetDataFromServer = (val, onComplete) => {
    selOutlet &&
      API(controller.Product + action.FindProducts).post(
        {
          orgId: selOutlet.orgId,
          value: val,
        },
        onComplete
      );
  };

  const handleSelect = (e, prod) => {
    if (values.splrId && prod.splrId !== values.splrId)
      setAlert({
        isOpen: true,
        type: "confirmation",
        title: "This product is not for the selected suppliers.",
        subTitle: "Do you still want to add this product?",
        cancelBtnTitle: "No",
        onConfirm: () => {
          _handleProdSel(prod);
          setAlert({
            isOpen: false,
            type: "",
            title: "",
            subTitle: "",
          });
        },
      });
    else _handleProdSel(prod);

    if (prod) {
    }
  };

  const _handleProdSel = (prod) => {
    if (
      !values.stockProds.some(
        (j) => j.prodId === prod.id && j.varProdId === prod.varProdId
      )
    ) {
      console.warn("prod: ", prod);

      setValues((x) => ({
        ...x,
        stockProds: [
          {
            name: prod.label,
            subtitle: prod.subtitle,
            prodId: prod.id,
            varProdId: prod.varProdId,
            splrPrice: prod.splrPrice,
            isVariant: prod.isVariant,
            img: prod.img,
            prod: prod,
            unitPrice: prod.splrPrice || 0,
            totalPrice: 0,
            prevStock: "",
            // Inventories
            orderInvsFor: x.orderingFor.map((outletId) => ({
              outletId: outletId,
              qty: "",
              receivedQty: "",
              previousStock: 0,
            })),
            totalQty: 0,
          },
          ...x.stockProds,
        ],
      }));
    } else if (
      values.stockProds.some(
        (j) => j.prodId === prod.id && j.varProdId === prod.varProdId
      )
    ) {
      setValues((x) => ({
        ...x,
        stockProds: x.stockProds.map((j) =>
          j.prodId === prod.id && j.varProdId === prod.varProdId
            ? { ...j, qty: parseFloat(j.qty) + 1 }
            : j
        ),
      }));
    }
    setFindErr("");
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      if (res.status === 200) {
        var _stockProds = [];
        res.result
          .filter((j) => j.prodType !== 3)
          .forEach((prod) => {
            if (prod.prodType === 1) {
              _stockProds.push({
                ...prod,
                isVariant: false,
                id: prod.id,
                varProdId: null,
                img: prod.imgs && prod.imgs[0] && prod.imgs[0].imgUrl,
                label: prod.name,
                subtitle:
                  (prod.skuCode && `${prod.skuCode} | `) +
                  `$${prod.splrPrice || 0}`,
              });
            } else {
              prod.prodVariants.forEach((varProd) => {
                _stockProds.push({
                  ...varProd,
                  isVariant: true,
                  id: null,
                  varProdId: varProd.id,
                  img:
                    varProd.imgs && varProd.imgs[0] && varProd.imgs[0].imgUrl,
                  label: `${
                    prod.name +
                    " / " +
                    varProd.attrVal1 +
                    (varProd.attrVal2 && ` / ${varProd.attrVal2}`) +
                    (varProd.attrVal3 && ` / ${varProd.attrVal3}`)
                  }`,
                  subtitle:
                    (varProd.skuCode && `${varProd.skuCode} | `) +
                    `$${varProd.splrPrice}`,
                });
              });
            }
          });
        setSearchLi([..._stockProds]);
      }
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  return (
    <>
      <InputAuto
        label="Search or Scan Product"
        value={searchVal}
        onChange={handleSelect}
        onInputChange={handleSearch}
        inputValue={searchVal}
        onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
        classes={{ paper: "shadow-sm" }}
        options={searchLi}
        error={findErr}
      />
    </>
  );
};

export default InputSelectItem;
