import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Inventory2Rounded } from "@mui/icons-material";

const ProdShortDtls = ({ product, handleShowStock }) => {
  return product && product.id ? (
    <div className="p-[15px] flex flex-wrap gap-3 justify-between">
      {/* Product */}
      <div className="flex gap-4">
        <img
          src={product.imgUrl}
          alt="product_img"
          className="img-fluid bg-white rounded-[10px] shadow-md  w-[60px] h-[60px] object-contain"
        />
        <div className="grid items-center">
          <div className="grid h-fit">
            <span className="text-[18px] text-gray-500">{product.name}</span>
            <div>
              <span className="text-[14px] pr-[10px]">
                {product.prodType === 1
                  ? product.skuCode
                  : product.prodType === 2
                  ? `${product.prodVariants.length} variants`
                  : `${product.compositProds.length} composite items`}
              </span>
            </div>
          </div>
        </div>
      </div>
      {product.prodType === 1 && (
        <div>
          <Tooltip title="View Stock Details">
            <IconButton
              onClick={() => handleShowStock(product, 1)}
              color="primary"
              className="shadow-lg !m-[5px] !bg-slate-100"
            >
              <Inventory2Rounded />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  ) : (
    <span className="p-[20px] text-[18px]">Loading...</span>
  );
};

export default ProdShortDtls;
