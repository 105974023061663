import { ArrowBackRounded } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import StatusWidget from "./StatusWidget";

const Details = ({ order }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Grid item xs={12}>
        <div className="flex flex-wrap items-center gap-2 justify-between">
          <div>
            <IconButton onClick={handleBack} className="border">
              <ArrowBackRounded />
            </IconButton>
          </div>
          <div className="flex gap-[10px] items-center">
            <span>
              Order Id: <span className="text-[26px]">{order.orderNum}</span>
            </span>
            <StatusWidget status={order.status} isOverdue={order.isOverdue} />
          </div>
        </div>

        <Divider className="pt-[10px]" />
      </Grid>

      <Grid item xs={12} md={6}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className="!font-medium">Deliver to</TableCell>
                <TableCell>
                  : {order.recipientOutlet && order.recipientOutlet.outletName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Ordered For</TableCell>
                <TableCell>
                  :{" "}
                  {order.orderingFor &&
                    order.orderingFor.map((val, ind) => (
                      <span key={ind}>
                        {val.outlet && val.outlet.outletName}{" "}
                        {order.orderingFor.length > ind + 1 ? " | " : ""}
                      </span>
                    ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Supplier</TableCell>
                <TableCell>
                  : {order.splr.company} (
                  {order.splr.firstName + " " + order.splr.lastName})
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Supplier invoice</TableCell>
                <TableCell>: {order.splrInv}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Note</TableCell>
                <TableCell>: {order.note}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Created</TableCell>
                <TableCell>
                  : {new Date(order.createDate).toDateString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Created by</TableCell>
                <TableCell>
                  :{" "}
                  {order.user &&
                    order.user.firstName + " " + order.user.lastName}
                </TableCell>
              </TableRow>
              {order.updateDate && (
                <TableRow>
                  <TableCell className="!font-medium">Updated</TableCell>
                  <TableCell>
                    : {new Date(order.updateDate).toDateString()}
                  </TableCell>
                </TableRow>
              )}
              {order.status === 1 && (
                <TableRow>
                  <TableCell className="!font-medium">Delivery due</TableCell>
                  <TableCell>
                    : {new Date(order.deliveryDate).toDateString()}
                  </TableCell>
                </TableRow>
              )}
              {order.receiveDate && (
                <TableRow>
                  <TableCell className="!font-medium"> Received</TableCell>
                  <TableCell>
                    : {new Date(order.receiveDate).toDateString()}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {order.status === 3 && (
                <>
                  <TableRow>
                    <TableCell className="!font-medium">Cancel Date</TableCell>
                    <TableCell>
                      : {new Date(order.cancelDate).toDateString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="!font-medium">Cancel User</TableCell>
                    <TableCell>
                      :{" "}
                      {order.cancelUser &&
                        order.cancelUser.firstName +
                          " " +
                          order.cancelUser.lastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="!font-medium">
                      Cancel Reason
                    </TableCell>
                    <TableCell>
                      <span
                        className="font-normal text-gray-500 whitespace-pre-wrap"
                        style={{ lineHeight: "1.4rem" }}
                      >
                        : {order.cancelReason}
                      </span>
                    </TableCell>
                  </TableRow>
                </>
              )}

              <TableRow>
                <TableCell className="!font-medium">
                  <span className="flex !w-max">Subtotal of Products</span>
                </TableCell>
                <TableCell>
                  : ${" "}
                  {order.subtotal.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">
                  Discount {order.discountType === 1 && ` (${order.discount}%)`}
                </TableCell>
                <TableCell>
                  : (-) ${" "}
                  {order.disAmt.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Shipping</TableCell>
                <TableCell>
                  : ${" "}
                  {order.shippingFee.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium">Import duty</TableCell>
                <TableCell>
                  : ${" "}
                  {order.importDuty.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!font-medium !text-[18px]">
                  Order Total
                </TableCell>
                <TableCell className="!text-gray-500 !font-medium !text-[18px]">
                  : ${" "}
                  {order.totalOrderAmt.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}></Grid>
    </>
  );
};

export default Details;
