import { AdminPanelSettingsRounded, Logout } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuPopup } from "../../layout";
import { Box } from "@mui/system";
import { Select } from "../../controls";
import { API, action, controller } from "../../api/api";
import { AppContext } from "../../App";
import { no_img } from "../../assets";

const Header = () => {
  const [profileEl, setProfileEl] = useState(null);
  const { setAuthUser, authUser, outlets, setOutlets, setSelOutlet } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [outletId, setOutletId] = useState(1);

  const handleProfile = (e) => {
    setProfileEl(e.currentTarget);
  };

  const handleLogout = () => {
    API(controller.Auth + action.Logout).get(() => {
      setAuthUser();
      navigate("/auth");
    });
    navigate("/auth");
  };

  const handleChgBranch = (e) => {
    var defOutlet = outlets.find((j) => j.id === e.target.value);
    if (defOutlet) {
      setSelOutlet(defOutlet);
      setOutletId(defOutlet.id);
    }
    setOutletId(e.target.value);
  };

  useEffect(() => {
    API(controller.Outlet + action.GetAuthUserOutlets).get((res) => {
      if (res.status === 200) {
        setOutlets([...res.result.map((j) => ({ ...j, label: j.outletName }))]);
        var _defOutlet = res.result.find((j) => j.defOutlet);
        if (_defOutlet) {
          setSelOutlet(_defOutlet);
          setOutletId(_defOutlet.id);
        }
      }
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <Box
      className="flex items-center w-[100%]"
      sx={{ justifyContent: { md: "space-between", xs: "end" } }}
    >
      <Box
        className="ml-[10px] text-gray"
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <img
          className="h-[40px]"
          src={
            authUser && authUser.org && authUser.org.logo
              ? authUser.org.logo
              : no_img
          }
          alt="Logo"
        />
      </Box>
      <div className="flex items-center">
        <div className="mr-[10px]">
          <Select
            sx={{
              minWidth: "150px",
              backgroundColor: "var(--whiteXS-color)",
              color: "var(--white-color)",
              "& .MuiSvgIcon-root": {
                color: "var(--white-color)",
              },
            }}
            variant="outlined"
            size="small"
            fullWidth
            value={outletId}
            onChange={handleChgBranch}
            options={outlets}
          />
        </div>
        <div>
          <IconButton
            onClick={handleProfile}
            size="small"
            aria-controls="account-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <Avatar
              alt="profile-pic"
              src={authUser.photo}
              className="bg-primary"
            />
          </IconButton>
          <MenuPopup
            anchorEl={profileEl}
            setAnchorEl={setProfileEl}
            id="account-menu"
          >
            <MenuItem
              onClick={() => navigate(`/edit_my_account/${authUser.id}`)}
            >
              <ListItemIcon>
                <AdminPanelSettingsRounded fontSize="small" />
              </ListItemIcon>
              My Account
            </MenuItem>

            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </MenuPopup>
        </div>
      </div>
    </Box>
  );
};

export default Header;
