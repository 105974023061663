import { Divider, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Model, UseForm } from "../../hooks";
import { AppContext } from "../../App";
import { API, action, controller } from "../../api/api";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import {
  Button,
  Input,
  InputAuto,
  InputImg,
  InputSwitch,
} from "../../controls";
import { Alert, Form, Tabs } from "../../layout";

const SettingsForm = (props) => {
  const { OrgMdl } = Model();
  const { authUser } = useContext(AppContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    handleInpCheck,
    resetFormControls,
  } = UseForm(OrgMdl);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser.id) {
      API(controller.Tax + action.GetAllByOrg).get((res) => {
        res.status === 200 &&
          setTaxes([...res.result.map((j) => ({ ...j, label: j.name }))]);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    authUser &&
      API(controller.Org + action.GetAuthUserOrg).get(
        (res) =>
          res.status === 200 &&
          setValues((x) => ({
            ...x,
            ...RmvEmptyProp(res.result),
          }))
      );
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const validateForm = () => {
    let temp = {};
    temp.orgName = values.orgName !== "" ? "" : "";
    temp.defTax = values.defTax !== "" ? "" : "";
    temp.email = values.email !== "" ? "" : "";
    temp.phone = values.phone !== "" ? "" : "";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const onComplete = (res) => {
        if (res && res.status === 200) {
          res.result && setValues({ ...res.result });
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: "Organization data updated successfully.",
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
        setLoading(false);
      };
      API(controller.Org + action.Put).put(
        ToFormData(
          RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
        ),
        onComplete
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-15 pl-15 pr-15">
            <div>
              <h1
                variant="h5"
                className="form-title mb-5 fw-4 gray-color tx-upp
                    border-left"
              >
                General Settings
              </h1>
            </div>

            <div>
              <Button type="submit" loading={loading}>
                Save Changes
              </Button>
            </div>
          </div>
          <Divider />

          <div className="pl-15 pr-15 pb-15 mt-30">
            <Tabs
              className="mt-30"
              header={[
                { label: "Org. Info", icon: "" },
                { label: "Contact Info.", icon: "" },
                { label: "Address Book", icon: "" },
              ]}
            >
              {/* Organization Information */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Organization Name"
                    name="orgName"
                    required={true}
                    value={values.orgName}
                    error={errors.orgName}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputAuto
                    label="Default sales tax"
                    name="defTax"
                    value={values.defTax}
                    error={errors.defTax}
                    onChange={handleInpChg}
                    options={taxes}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label="Org. Logo"
                    name="logoFile"
                    src={values.logo}
                    value={values.logoFile}
                    rmv={() => {
                      setValues({ ...values, logoFile: "", logo: "" });
                    }}
                    onChange={handleFile}
                    error={errors.logoFile}
                  />
                </Grid>
              </Grid>

              {/* Contact Information */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    label="First Name"
                    name="firstName"
                    required={true}
                    value={values.firstName}
                    error={errors.firstName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Last Name"
                    name="lastName"
                    required={true}
                    value={values.lastName}
                    error={errors.lastName}
                    inputProps={{ maxLength: 255 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Email"
                    name="email"
                    required={true}
                    value={values.email}
                    error={errors.email}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Phone"
                    name="phone"
                    required={true}
                    value={values.phone}
                    inputProps={{ maxLength: 50 }}
                    error={errors.phone}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Website"
                    name="website"
                    value={values.website}
                    inputProps={{ maxLength: 600 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Twitter"
                    name="twitter"
                    value={values.twitter}
                    inputProps={{ maxLength: 600 }}
                    onChange={handleInpChg}
                  />
                </Grid>
              </Grid>

              {/* Addresses */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="d-flex a-center pl-5 mt-20">
                    <span
                      className="gray-color tx-upp fw-5 fs-italic"
                      style={{ minWidth: "150px" }}
                    >
                      PHYSICAL ADDRESS
                    </span>
                    <div className="bs-secondary w-100pr" />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 1"
                    name="address1"
                    value={values.address1}
                    error={errors.address1}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    rows={2}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Address Line 2"
                    name="address2"
                    value={values.address2}
                    error={errors.address2}
                    inputProps={{ maxLength: 300 }}
                    multiline
                    rows={2}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="City"
                    name="city"
                    value={values.city}
                    error={errors.city}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="State"
                    name="state"
                    value={values.state}
                    error={errors.state}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label="ZIP"
                    name="zip"
                    value={values.zip}
                    error={errors.zip}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleInpChg}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputSwitch
                    label="Use different address for postal address"
                    fontSize="16px"
                    name="isPostalAddress"
                    checked={values.isPostalAddress}
                    onChange={handleInpCheck}
                  />
                </Grid>

                {values.isPostalAddress && (
                  <>
                    <Grid item xs={12}>
                      <div className="d-flex a-center pl-5 mt-20">
                        <span
                          className="gray-color tx-upp fw-5 fs-italic"
                          style={{ minWidth: "150px" }}
                        >
                          POSTAL ADDRESS
                        </span>
                        <div className="bs-secondary w-100pr" />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        label="Address Line 1"
                        name="pAddress1"
                        value={values.pAddress1}
                        error={errors.pAddress1}
                        inputProps={{ maxLength: 300 }}
                        multiline
                        rows={2}
                        onChange={handleInpChg}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        label="Address Line 2"
                        name="pAddress2"
                        value={values.pAddress2}
                        error={errors.pAddress2}
                        inputProps={{ maxLength: 300 }}
                        multiline
                        rows={2}
                        onChange={handleInpChg}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        label="City"
                        name="pCity"
                        value={values.pCity}
                        error={errors.pCity}
                        inputProps={{ maxLength: 150 }}
                        onChange={handleInpChg}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        label="State"
                        name="pState"
                        value={values.pState}
                        error={errors.pState}
                        inputProps={{ maxLength: 150 }}
                        onChange={handleInpChg}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Input
                        label="ZIP"
                        name="pZip"
                        value={values.pZip}
                        error={errors.pZip}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleInpChg}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default SettingsForm;
