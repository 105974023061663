import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, IconButton, Paper } from "@mui/material";
import { Button, Input, InputDate, LabelComp } from "../../../../controls";
import { Model, UseForm } from "../../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";
import { AppContext } from "../../../../App";
import { API, action, controller } from "../../../../api/api";
import { BGNestedObjToFromData, RmvEmptyProp } from "../../../../hooks/Method";
import { Alert } from "../../../../layout";
import OrderItems from "./OrderItems";
import Accounting from "./Accounting";

const ReceivedDeliveryForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { ReceivedDeliveryMdl } = Model();
  const { outlets } = useContext(AppContext);

  const [stores, setStores] = useState([]);
  const { values, setValues, errors, setErrors, handleInpChg, handleInpDate } =
    UseForm(ReceivedDeliveryMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setStores([
      { id: "all", label: `All Outlets (${outlets.length})` },
      ...outlets,
    ]);

    return () => {};
  }, [outlets]);

  // Purchase Update Operation
  useEffect(() => {
    id &&
      API(controller.Stock + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((j) => ({
            ...j,
            ...val,
            user: "",
            outlet: "",
            org: "",
            stockProds: [
              ...val.stockProds.map((x) => ({
                ...x,
                user: "",
                org: "",
                outlet: "",
                orderedQty: x.orderInvsFor
                  .map((y) => y.qty)
                  .reduce((a, b) => a + b, 0),
                img: x.isVariant
                  ? x.varProd && x.varProd.imgs[0] && x.varProd.imgs[0].imgUrl
                  : x.prod.imgs[0] && x.prod.imgs[0].imgUrl,
                name: x.isVariant
                  ? (x.varProd && x.varProd.product.name) +
                    " / " +
                    x.varProd.attrVal1 +
                    (x.varProd.attrVal2 && ` / ${x.varProd.attrVal2}`) +
                    (x.varProd.attrVal3 && ` / ${x.varProd.attrVal3}`)
                  : x.prod && x.prod.name,
                subtitle: x.isVariant
                  ? x.varProd && x.varProd.product.skuCode
                  : x.prod && x.prod.skuCode,
                totalReceiveCost: 0,
              })),
            ],
          }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.splrId = values.splrId !== "" ? "" : "Supplier is required.";
    temp.orderingFor =
      values.orderingFor.length > 0 ? "" : "Ordering for outlet is required.";
    temp.recipientOutletId =
      values.recipientOutletId !== "" ? "" : "Recipient outlet is required.";
    temp.orderNum = values.orderNum !== "" ? "" : "Order number is required.";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setAlert({
        isOpen: true,
        type: "confirmation",
        title:
          "You’re about to receive this delivery which will update your inventory",
        subTitle: "This action is permanent and can’t be undone.",
        confirmBtnTitle: "Receive Delivery",
        cancelBtnTitle: "Don't Receive",
        onConfirm: () => {
          _submitForm();
        },
      });
    }
  };

  const _submitForm = () => {
    setLoading(true);
    const onComplete = (res) => {
      setLoading(false);
      if (res) {
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: "Success",
            subTitle: res.msg,
            onClick: () => navigate("/stock_control"),
          });
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: "Error",
            subTitle: res.msg,
          });
      }
    };

    if (values.id) {
      API(controller.Stock + action.ReceiveDelivery).post(
        BGNestedObjToFromData({
          ...values,
          recipientOutlet: "",
          stockProds: values.stockProds.map((j) => ({
            ...j,
            prod: null,
            varProd: null,
          })),
          orderingFor: values.orderingFor.map((j) => ({ outletId: j })),
          splr: null,
        }),
        onComplete
      );
    }
  };

  return (
    <>
      {values.id ? (
        <Paper>
          <div className="flex justify-between items-center p-[10px]">
            <div className="flex items-center gap-[10px]">
              <div>
                <IconButton size="small" onClick={() => navigate(-1)}>
                  <ArrowBackRounded />
                </IconButton>
              </div>

              <div>
                <h1 className="form-title !font-medium text-gray-500 uppercase">
                  Receive Delivery
                </h1>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                loading={loading}
                onClick={() => handleSubmit()}
              >
                Receive
              </Button>
            </div>
          </div>
          <Divider />
          <div className="p-[15px] mt-[15px]">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <LabelComp
                  label="Supplier"
                  value={
                    values.splr
                      ? values.splr.firstName + " " + values.splr.lastName
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LabelComp
                  label="Ordering for"
                  value={values.orderingFor.map(
                    (val, ind) =>
                      `${val.outlet && val.outlet.outletName}${
                        values.orderingFor.length > ind + 1 ? " | " : ""
                      }`
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LabelComp
                  label="Delivery to"
                  value={
                    values.recipientOutlet && values.recipientOutlet.outletName
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Input
                  label="Order Number"
                  name="orderNum"
                  required
                  value={values.orderNum}
                  error={errors.orderNum}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Input
                  label="Supplier Invoice Number"
                  name="splrInv"
                  value={values.splrInv}
                  onChange={handleInpChg}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputDate
                  label="Delivery Date"
                  name="deliveryDate"
                  value={values.deliveryDate}
                  onChange={(date) =>
                    handleInpDate(
                      "deliveryDate",
                      new Date(date).toLocaleDateString()
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Note"
                  name="note"
                  multiline
                  rows={3}
                  value={values.note}
                  onChange={handleInpChg}
                />
              </Grid>

              <Grid item xs={12}>
                <OrderItems {...{ values, setValues, stores }} />
              </Grid>
            </Grid>

            <Accounting {...{ values, setValues, handleInpChg }} />
          </div>
        </Paper>
      ) : (
        "Loading..."
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default ReceivedDeliveryForm;
