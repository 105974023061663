import React, { useContext, useEffect, useState } from "react";
import { Alert, Dialog, Table } from "../../../layout";
import { Button } from "../../../controls";
import { IconButton, Tooltip } from "@mui/material";
import { ClearRounded, EditRounded } from "@mui/icons-material";
import AddEditCat from "./AddEditCat";
import { AppContext } from "../../../App";
import { API, action, controller } from "../../../api/api";
import moment from "moment";

const Categories = () => {
  const [updData, setUpdData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [cats, setCats] = useState([]);
  const { authUser, setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser.id) {
      setLoader(true);
      API(controller.Category + action.GetAllByOrg).get((res) => {
        res.status === 200 && setCats([...res.result]);
        setLoader(false);
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (data) => {
    setUpdData(data);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this category?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res) {
            if (res.status === 200) {
              setAlert({
                isOpen: true,
                type: "success",
                title: "Deleted Successfully",
                subTitle: res.msg,
              });
              setCats((x) => [...x.filter((j) => j.id !== res.result.id)]);
            } else
              setAlert({
                isOpen: true,
                type: "warning",
                title: "Warning",
                subTitle: res.msg,
              });
          }
        };
        API(controller.Category).Delete(id, onComplete);
      },
    });
  };

  const ActionComp = (val) => (
    <div className="border !border-gray-100 flex gap-2 p-[3px] shadow-sm rounded-[12px] w-fit">
      <Tooltip title="Edit">
        <IconButton
          onClick={() => handleAddEdit(val)}
          size="small"
          color="primary"
        >
          <EditRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => handleDlt(val.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="!text-[18px]" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const HeaderComp = () => (
    <div>
      <Button onClick={() => handleAddEdit()}>Add Category</Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "catName", label: "Name" },
          { id: "prod", label: "Number of Products	" },
          { id: "createDate", label: "Create Date" },
          { id: "actions", label: "Actions" },
        ]}
        tblData={cats.map((cat) => {
          return {
            ...cat,
            catName: cat.parentCat ? (
              <div>
                {cat.parentCat.name}
                <span className="fw-5 fs-18">
                  &nbsp;&nbsp; {">"} &nbsp;&nbsp;
                </span>
                {cat.name}
              </div>
            ) : (
              cat.name
            ),
            prod: 0,
            createDate: moment(cat.createDate).format(
              "ddd, DD MMM YYYY hh:mm A"
            ),
          };
        })}
      />

      {/* Add Follow Up */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditCat
            {...{ setShowForm: setShowForm, setCats, updData, setAlert }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Categories;
