import React, { useState } from "react";
import { Table } from "../../../layout";
import { Box } from "@mui/material";
import { InputDate } from "../../../controls";
import { subDays } from "date-fns";
import moment from "moment";

const SaleHistory = () => {
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/1/${new Date().getFullYear()}`,
  });
  const handleDate = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  const HeaderComp = () => (
    <Box
      className="flex items-center w-[100%]"
      sx={{ mb: { md: "10px", xl: "0px" } }}
    >
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="fromDate"
        value={sortDate.fromDate}
        views={["day", "month", "year"]}
        openTo="day"
        minDate={new Date("01/01/2000")}
        maxDate={subDays(new Date(sortDate.toDate), 1)}
        onChange={(date) =>
          handleDate("fromDate", new Date(date).toLocaleDateString())
        }
      />
      <span className="ml-[10px] mr-[10px] text-[16px] text-gray-500">TO</span>
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="toDate"
        value={sortDate.toDate}
        minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
        views={["day", "month", "year"]}
        openTo="day"
        onChange={(date) =>
          handleDate("toDate", new Date(date).toLocaleDateString())
        }
      />
    </Box>
  );
  return (
    <>
      <Table
        ActionComp={() => <></>}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "trans", label: "Transaction" },
          { id: "receipt", label: "Receipt" },
          { id: "amount", label: "Amount" },
          { id: "bal", label: "Balance" },
        ]}
        tblData={[]}
      />
    </>
  );
};

export default SaleHistory;
