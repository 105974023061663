import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { AppContext } from "../../../../App";
import { no_img } from "../../../../assets";
import { Inventory2Rounded } from "@mui/icons-material";

const VarProdInv = ({ product, handleShowStock }) => {
  const { outlets } = useContext(AppContext);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    if (product && product.prodVariants.length) {
      setVariants([
        ...product.prodVariants.map((j) => ({
          ...j,
          imgUrl: j.imgs[0] ? j.imgs[0].imgUrl : no_img,
          splrPrice:
            j.inventories[0] && (j.inventories.lastSplrPrice || j.splrPrice),
          label:
            product.name +
            " / " +
            j.attrVal1 +
            (j.attrVal2 && ` / ${j.attrVal2}`) +
            (j.attrVal3 && ` / ${j.attrVal3}`),
        })),
      ]);
    } else {
      setVariants([]);
    }

    return () => {};
  }, [product]);

  return (
    <div>
      {variants ? (
        <TableContainer className="max-h-[500px] overflow-auto">
          <Table className="min-w-[650px]" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="!text-gray-500">Stock</TableCell>
                <TableCell className="!text-gray-500">Product</TableCell>
                <TableCell className="!text-gray-500">Supplier Price</TableCell>
                <TableCell className="!text-gray-500">Retail Price</TableCell>
                {outlets.map((outlet, ind) => (
                  <TableCell key={ind}>
                    <div className="grid leading-snug text-gray-500">
                      <span className="text-[12px] font-normal">
                        Inventory on{" "}
                      </span>
                      <span>{outlet.outletName}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {variants.map((variant, ind) => (
                <TableRow key={ind}>
                  <TableCell>
                    <Tooltip title="View Stock Details">
                      <IconButton
                        onClick={() => handleShowStock(variant, 2)}
                        color="primary"
                        size="small"
                        className="shadow-lg !m-[5px] !bg-slate-100"
                      >
                        <Inventory2Rounded className="!text-[22px] !m-[2px]" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-3 items-center text-gray-500">
                      <img
                        className="w-[50px] h-[50px] object-cover rounded-lg shadow-md"
                        src={variant.imgUrl}
                        alt={variant.label}
                      />

                      <div className="grid gap-[1px]">
                        <span className="text-[16px]">{variant.label}</span>
                        <span className="text-[12px]">{variant.skuCode}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <span className="text-[18px] text-gray-500">
                      $ {variant.splrPrice}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-[18px] text-gray-500">
                      $ {variant.rtlPrice}
                    </span>
                  </TableCell>
                  {outlets.map((outlet, ind) => {
                    var inv = variant.inventories.find(
                      (j) => j.outletId === outlet.id
                    );
                    return (
                      <TableCell key={ind}>
                        <span className="text-[18px] text-gray-500">
                          {inv ? inv.inInv : 0}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default VarProdInv;
