import { Box, Paper } from "@mui/material";
import React, { useContext, useState } from "react";
import { InputAuto, Select } from "../../../controls";
import { AppContext } from "../../../App";

const StockFilter = ({ onChange }) => {
  const { outlets } = useContext(AppContext);
  const [status, setStatus] = useState("all");
  const [outletId, setOutletId] = useState();

  const handleStatus = (e) => {
    var _status = e.target.value;
    setStatus(_status);
    onChange(_status ? _status : null, outletId || 0);
  };

  const handleOutlet = (e) => {
    var _outletId = e.target.value;
    setOutletId(_outletId);
    onChange(status ? status : null, _outletId || 0);
  };

  return (
    <Paper className="p-[10px] mb-[15px]">
      <Box className="gap-[15px]" sx={{ display: { lg: "flex", xs: "grid" } }}>
        <Select
          label="Status"
          size="small"
          value={status}
          fullWidth
          onChange={handleStatus}
          options={[
            { id: "all", label: "All orders" },
            { id: 1, label: "Open orders" },
            { id: 4, label: "Sent orders" },
            { id: 2, label: "Received orders" },
            { id: 5, label: "Overdue orders" },
            { id: 3, label: "Cancelled orders" },
          ]}
        />
        <InputAuto
          label="Delivery Outlet"
          size="small"
          value={outletId}
          fullWidth
          onChange={handleOutlet}
          options={outlets}
        />
      </Box>
    </Paper>
  );
};

export default StockFilter;
