import React from "react";

const StoreCredit = () => {
  return (
    <div className="text-[18px] text-gray-500 italic">
      This section is wait for the backend.
    </div>
  );
};

export default StoreCredit;
